import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

/** Material UI  */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'

/** Páginas */
import CronogramaFinanceiroCard from './CronoFinanceiroCard'

function CronogramaFinanceiro() {

    const { id } = useParams()

    //#region Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region Carregar informações
    let listaCronogramaFinanceiro = []
    const InfoCronograma = () => {
        firebase.firestore().collection('Obras').doc(id).collection('CronogramaFinanceiro').orderBy('Etapa').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaCronogramaFinanceiro.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setQuadroCronogramaFinanceiro(listaCronogramaFinanceiro)
        })
    }

    /** Obter Valores Cronograma Financeiro */    
    const [QuadroCronogramaFinanceiro, setQuadroCronogramaFinanceiro] = useState([])
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).collection('CronogramaFinanceiro').orderBy('Etapa').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaCronogramaFinanceiro.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setQuadroCronogramaFinanceiro(listaCronogramaFinanceiro)
        })
    }, [])


    /** Função Inserir CronogramaFinanceiro */
    const [Etapa, setEtapa] = useState('')
    const [Inicio, setInicio] = useState('')
    const [Término, setTérmino] = useState('')
    const [Duração, setDuração] = useState('')
    const [PercentualEtapa, setPercentualEtapa] = useState('')
    const [ValorEtapa, setValorEtapa] = useState('')
    const [PercentualAcumulado, setPercentualAcumulado] = useState('')
    const [ValorAcumulado, setValorAcumulado] = useState('')

    function InserirCronogramaFinanceiro() {
        firebase.firestore().collection('Obras').doc(id).collection('CronogramaFinanceiro').add({
            Etapa: Number(Etapa),
            Início: Inicio,
            Término: Término,
            Duração: Number(Duração),
            PercentualEtapa: Number(PercentualEtapa),
            ValorEtapa: Number(ValorEtapa),
            PercentualAcumulado: Number(PercentualAcumulado),
            ValorAcumulado: Number(ValorAcumulado)
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).collection('CronogramaFinanceiro').orderBy('Etapa').get().then(async (resultado) => {
                await resultado.docs.forEach(doc => {
                    listaCronogramaFinanceiro.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setQuadroCronogramaFinanceiro(listaCronogramaFinanceiro)
                setEtapa('')
                setInicio('')
                setTérmino('')
                setDuração('')
                setPercentualEtapa('')
                setValorEtapa('')
                setPercentualAcumulado('')
                setValorAcumulado('')
            })
        }).catch(erro => {

        })
    }
    //#endregion

    return (
        <div>
            {/** Tabela do cronograma Financeiro */}
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Cronograma Financeiro</h3>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <table className="table table-hover text-nowrap">
                        {/** Cabeçalho Tabela */}
                        <thead>
                            <tr>
                                <th>Etapa</th>
                                <th>Início</th>
                                <th>Término</th>
                                <th>Duração</th>
                                <th>Percentual Etapa</th>
                                <th>Valor Etapa</th>
                                <th>Percentual Acumulado</th>
                                <th>Valor Acumulado</th>
                                <th>Edição</th>
                            </tr>
                        </thead>

                        {/** Composição Tabela */}
                        {QuadroCronogramaFinanceiro.map(item =>
                            <tbody>
                                <tr>
                                    {/** Etapa */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            Etapa={item.Etapa}
                                            Editar="Não"
                                        />
                                    </td>

                                    {/** Data Início */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            Início={moment(item.Início).format('L')}
                                            Editar="Não"
                                        />
                                    </td>

                                    {/** Data Término */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            Término={moment(item.Término).format('L')}
                                            Editar="Não"
                                        />
                                    </td>

                                    {/** Duração */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            Duração={item.Duração}
                                            Editar="Não"
                                        />
                                    </td>

                                    {/** Percentual da Etapa */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            PercentualEtapa={numberDecimalFormat(item.PercentualEtapa)}
                                            Editar="Não"
                                        />
                                    </td>

                                    {/** Valor da Etapa */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            ValorEtapa={numberFormat(item.ValorEtapa)}
                                            Editar="Não"
                                        />
                                    </td>

                                    {/** Percentual Acumulado */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            PercentualAcumulado={numberDecimalFormat(item.PercentualAcumulado)}
                                            Editar="Não"
                                        />
                                    </td>

                                    {/** Valor Acumulado */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            ValorAcumulado={numberFormat(item.ValorAcumulado)}
                                            Editar="Não"
                                        />
                                    </td>

                                    {/** Edição */}
                                    <td>
                                        <CronogramaFinanceiroCard
                                            key={item.id}
                                            IdObra={id}
                                            IdCronograma={item.id}
                                            Etapa={item.Etapa}
                                            Início={item.Início}
                                            Término={item.Término}
                                            Duração={item.Duração}
                                            PercentualEtapa={item.PercentualEtapa}
                                            ValorEtapa={item.ValorEtapa}
                                            PercentualAcumulado={item.PercentualAcumulado}
                                            ValorAcumulado={item.ValorAcumulado}
                                            InfoCronograma={InfoCronograma}
                                            Editar="Sim"
                                        />
                                    </td>
                                </tr>
                            </tbody>)}
                    </table>
                </div>
                <div className="uk-card-footer">
                    <button className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#Cronograma">Adicionar</button>
                </div>
                {/** Caixa de Diálogo*/}
                <div className="modal fade" id="Cronograma" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações do Cronograma</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setEtapa(e.target.value)}
                                            type="number"
                                            className="form-control"
                                            label="Etapa"
                                            value={Etapa && Etapa} />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setInicio(e.target.value)}
                                            className="form-control"
                                            label="Data de Início"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={Inicio && Inicio} />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setTérmino(e.target.value)}
                                            className="form-control"
                                            label="Data de Término"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={Término && Término} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setDuração(e.target.value)}
                                            type="number"
                                            className="form-control"
                                            label="Duração da Etapa"
                                            value={Duração && Duração} />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setPercentualEtapa(e.target.value)}
                                            type="number"
                                            className="form-control"
                                            label="Percentual da Etapa"
                                            value={PercentualEtapa && PercentualEtapa} />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setValorEtapa(e.target.value)}
                                            type="number"
                                            className="form-control"
                                            label="Valor da Etapa"
                                            value={ValorEtapa && ValorEtapa} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-6">
                                        <TextField
                                            onChange={(e) => setPercentualAcumulado(e.target.value)}
                                            type="number"
                                            className="form-control"
                                            label="Percentual Acumulado"
                                            value={PercentualAcumulado && PercentualAcumulado} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            onChange={(e) => setValorAcumulado(e.target.value)}
                                            type="number"
                                            className="form-control"
                                            label="Valor Acumulado"
                                            value={ValorAcumulado && ValorAcumulado} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={InserirCronogramaFinanceiro} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Inserir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Análises do Cronograma Financeiro */}
            <div className='row'>
                <div className="col-md-3 my-2">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Total das Etapas</h3>
                        <h5 className=' float-right'>{numberIntegerFormat(QuadroCronogramaFinanceiro.reduce((acc, p) => acc + parseFloat(p.Duração), 0))} Dias</h5>
                    </div>
                </div>
                <div className="col-md-3 my-2">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Valor Total Etapas</h3>
                        <h5 className=' float-right'>{numberFormat(QuadroCronogramaFinanceiro.reduce((acc, p) => acc + parseFloat(p.ValorEtapa), 0))}</h5>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default CronogramaFinanceiro