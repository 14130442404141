import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import firebase from '../../config/firebase'
import { getStorage, ref, getDownloadURL, uploadBytes, listAll } from "firebase/storage"

function Detalhes(props, id) {

    const UsuarioEmail = useSelector(state => state.usuarioEmail)

    const storage = getStorage()

    const [InfoPessoais, setInfoPessoais] = useState('')
    useEffect(() => {
        firebase.firestore().collection('Usuários').doc(UsuarioEmail).get().then(resultado => {
            setInfoPessoais(resultado.data().InfoPessoais)
        })
    }, [])

    //#region Visualizar Foto perfil
    const [urlImagens, setUrlImagens] = useState()
    getDownloadURL(ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/${'FotoPerfil'}`))
    .then(url => setUrlImagens(url))
    .catch(error => {
        // Tratar o erro aqui
        console.error("Erro ao obter URL da imagem:", error.message)
        // Definir uma URL padrão ou fazer outra coisa para lidar com a falta do objeto
    })

    const [urlImagemNova, setUrlImagemNova] = useState()
    getDownloadURL(ref(storage, `Usuários/${'user defalt.png'}`)).then(url => setUrlImagemNova(url))
    //#endregion

    //#region Imagens Storage
    const [fotoNova, setFotoNova] = useState(null)
    const imageListRef = ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/`)

    const cadastrarfoto = () => {
        if (fotoNova == null) return;
        const imageRef = ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/${'FotoPerfil'}`);
        uploadBytes(imageRef, fotoNova).then((snaphsot) => {
            getDownloadURL(snaphsot.ref).then((url) => {
                setUrlImagens(url)
            })
            alert("Image Alterada")
        })
    }

    useEffect(() => {
        listAll(imageListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setUrlImagens(url)
                })
            })
        })
    }, [])

    //#endregion

    return (
        <div className="">
            <div className="card-widget widget-user shadow">
                <div className="widget-user-header bg-olive">
                    <h3 className="widget-user-username">{InfoPessoais.Nome ? InfoPessoais.Nome : 'Novo Usuário'}</h3>
                    <h5 className="widget-user-desc">{UsuarioEmail}</h5>
                </div>
                <div className="widget-user-image">
                    <img className="img-circle elevation-2" src={urlImagens ? urlImagens : urlImagemNova} alt="User Avatar" />
                </div>
            </div>
        </div >

    )
}

export default Detalhes