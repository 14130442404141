import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'
import InputAdornment from '@mui/material/InputAdornment'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

/** Material UI  */
import { TextField } from '@mui/material'

/** Editor de Texto */
import JoditEditor from 'jodit-react'

/** Páginas */
import ListadeTarefasCard from './ListadeTarefasCard'

import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateToHTML } from 'draft-js-export-html'


function ListadeTarefas() {

    const { id } = useParams()
    const editor = useRef(null);

    const [content, setContent] = useState('');

    const [value, setValue] = useState('');

    const UsuarioEmail = useSelector(state => state.usuarioEmail)

    //#region wysiwyg Descrição
    const [descrição, setDescrição] = useState('')
    const [descricaoState, setDescricaoState] = useState(EditorState.createEmpty());
    const handleDescricaoChange = (descricaoState) => {
        setDescricaoState(descricaoState)

        const contentState = descricaoState.getCurrentContent()
        const htmlContent = stateToHTML(contentState)

        setDescrição(htmlContent)
    }

    const [observações, setObservações] = useState('')
    const [observacoesState, setObservacoesEditorState] = useState(EditorState.createEmpty());
    const handleObservacoesChange = (observacoesState) => {
        setObservacoesEditorState(observacoesState)

        const contentState = observacoesState.getCurrentContent()
        const htmlContent = stateToHTML(contentState)
        
        setObservações(htmlContent)
    }


    //#region Views Dropdown
    const [viewEdição, SetViewEdição] = useState('False')
    function viewModeMedEdição() {
        viewEdição == 'True' && SetViewEdição('False')
        viewEdição == 'False' && SetViewEdição('True')
    }
    //#endregion

    //#region Adicionar Tarefas
    const [dataInício, setDataInício] = useState('')
    const [assunto, setAssunto] = useState('')
    const [responsável, setResponsável] = useState('')
    const [dataConclusão, setDataConclusão] = useState('')
    const [situacao, setSituacao] = useState('')
    const [importancia, setImportancia] = useState('')

    const [tarefas, setTarefas] = useState([]);
    //#endregion

    //#region Obter Lista de Tarefas
    let listatarefas = []
    const [RelTarefas, setRelTarefas] = useState([])

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('Obras').doc(id).collection('Tarefas')
            .onSnapshot((snapshot) => {
                // Limpa a lista antes de atualizar com os novos dados
                listatarefas = [];

                snapshot.forEach((doc) => {
                    listatarefas.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                // Atualiza o estado com a lista atualizada
                setRelTarefas([...listatarefas]);
            });

        // O retorno da função useEffect é chamado quando o componente é desmontado ou quando as dependências mudam
        return () => {
            // Isso cancela a inscrição quando o componente é desmontado
            unsubscribe();
        };
    }, []);

    //#endregion

    //#region Criar Lista de Tarefas    
    const CriarTarefa = async () => {

        // Adicione a nova pasta à coleção "Pastas"
        const tarefasRef = firebase.firestore().collection('Obras').doc(id).collection('Tarefas').doc();

        tarefasRef.set({
            DataInicio: dataInício,
            Assunto: assunto,
            Responsável: responsável,
            Descrição: descrição,
            DataConclusão: dataConclusão,
            Situação: situacao == '' && 'Pendente',
            Importância: importancia == '' && 'Baixa',
            Observações: observações,
            Usuario: UsuarioEmail,
        });

        // Limpe os estados após criar a pasta
        const carregarPastas = async () => {
            try {
                const snapshot = await firebase.firestore().collection('Obras').doc(id).collection('Tarefas').orderBy('Nome').get();
                const listaTarefas = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTarefas(listaTarefas);
            } catch (error) {
                console.error('Erro ao obter pastas:', error);
            }
        };
        carregarPastas();
        setDataInício('')
        setAssunto('')
        setResponsável('')
        setDescrição('')
        setDataConclusão('')
        setSituacao('')
        setImportancia('')
        setObservações('')

        alert("Tarefa criada com sucesso.")
    }

    //#endregion

    return (
        <div className="uk-card uk-card-default col-md-12">
            <div className="uk-card-header">
                <div className="uk-grid-small uk-flex-middle" uk-grid>
                    <div className="uk-width-expand">
                        <div className="d-flex align-items-center">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Lista de Tarefas</h3>
                            <div class="uk-inline">
                                <i className="fa-solid fa-caret-down ml-3" style={{ fontSize: '22px' }} />
                                <div uk-dropdown="mode: click; animation: slide-top; animate-out: true; duration: 700 ">
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li class="uk-nav-header mb-2  fw-bolder">Tarefas</li>
                                        <li className='mb-2'><input className="uk-checkbox" type="checkbox" onClick={viewModeMedEdição} /> Editar Tarefas</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-card-body">
                <div className='col-md-12'>
                    <div className="row">
                        {/** Botões de Filtros */}
                        <div className='col-md-12'>
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button className="btn btn-sm btn-outline-primary active ml-3" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Pendente</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button className="btn btn-sm btn-outline-primary ml-3" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Em andamento</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button className="btn btn-sm btn-outline-primary ml-3" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Concluído</button>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active row" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                <div className='col-md-12 row'>
                                    <h3 className='text-red mb-3 text-center'>Lista de Tarefas - Pendentes</h3>
                                    {RelTarefas.map(item => (
                                        <>
                                            {item.Situação == 'Pendente' &&
                                                <ListadeTarefasCard
                                                    key={item.id}
                                                    IdObra={id}
                                                    IdTarefas={item.id}
                                                    DataInicio={item.DataInicio}
                                                    Assunto={item.Assunto}
                                                    Responsável={item.Responsável}
                                                    Descrição={item.Descrição}
                                                    DataConclusão={item.DataConclusão}
                                                    Situação={item.Situação}
                                                    Importância={item.Importância}
                                                    Observações={item.Observações}
                                                    Usuario={item.Usuario}
                                                    ViewEdição={viewEdição}
                                                />
                                            }
                                        </>
                                    ))
                                    }
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                <div className='col-md-12 row'>
                                    <h3 className='text-red mb-3 text-center'>Lista de Tarefas - Em Andamento</h3>
                                    {RelTarefas.map(item => (
                                        <>
                                            {item.Situação == 'Em Andamento' &&
                                                <>
                                                    <ListadeTarefasCard
                                                        key={item.id}
                                                        IdObra={id}
                                                        IdTarefas={item.id}
                                                        DataInicio={item.DataInicio}
                                                        Assunto={item.Assunto}
                                                        Responsável={item.Responsável}
                                                        Descrição={item.Descrição}
                                                        DataConclusão={item.DataConclusão}
                                                        Situação={item.Situação}
                                                        Importância={item.Importância}
                                                        Observações={item.Observações}
                                                        Usuario={item.Usuario}
                                                    />
                                                </>
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                <div className='col-md-12 row'>
                                    <h3 className='text-red mb-3 text-center'>Lista de Tarefas - Concluídas</h3>
                                    {RelTarefas.map(item => (
                                        <>
                                            {item.Situação == 'Concluído' &&
                                                <>

                                                    <ListadeTarefasCard
                                                        key={item.id}
                                                        IdObra={id}
                                                        IdTarefas={item.id}
                                                        DataInicio={item.DataInicio}
                                                        Assunto={item.Assunto}
                                                        Responsável={item.Responsável}
                                                        Descrição={item.Descrição}
                                                        DataConclusão={item.DataConclusão}
                                                        Situação={item.Situação}
                                                        Importância={item.Importância}
                                                        Observações={item.Observações}
                                                        Usuario={item.Usuario}
                                                    />
                                                </>
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            <div className="uk-card-footer">
                <button className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#AdicionarTarefas">Adicionar</button>
            </div>

            {/** Caixa de Diálogo Adicionar Tarefas*/}
            <div className="modal fade" id="AdicionarTarefas" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações da Medição</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-3">
                                        <TextField
                                            onChange={(e) => setDataInício(e.target.value)}
                                            type="date"
                                            className="form-control"
                                            label="Data de Início"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={dataInício && dataInício} />
                                    </div>
                                    <div className="col-md-9">
                                        <TextField
                                            onChange={(e) => setAssunto(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            label="Assunto"
                                            value={assunto && assunto} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-6">
                                        <TextField
                                            onChange={(e) => setResponsável(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            label="Responsável Pela Execução"
                                            value={responsável && responsável} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setDataConclusão(e.target.value)}
                                            className="form-control"
                                            label="Data de Conclusão"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={dataConclusão && dataConclusão} />
                                    </div>
                                    <div className='col-md-6 row'>
                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text" for="inputGroupSelect01">Situação</label>
                                                <select
                                                    className="form-select"
                                                    id="inputGroupSelect01"
                                                    onChange={(e) => setSituacao(e.target.value)}
                                                    value={situacao && situacao}>
                                                    <option value="Pendente">Pendente</option>
                                                    <option value="Em Andamento">Em andamento</option>
                                                    <option value="Concluído">Concluído</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text" for="inputGroupSelect01">Importância</label>
                                                <select
                                                    className="form-select"
                                                    id="inputGroupSelect01"
                                                    onChange={(e) => setImportancia(e.target.value)}
                                                    value={importancia && importancia}>
                                                    <option value="Baixa">Baixa</option>
                                                    <option value="Média">Média</option>
                                                    <option value="Alta">Alta</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className='col-md-12'>
                                        <label>Descrição</label>
                                        <div className='border'>
                                            <Editor
                                                className="border"
                                                editorState={descricaoState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={handleDescricaoChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className='col-md-12'>
                                        <label>Observação / Solução</label>
                                        <div className='border'>
                                            <Editor
                                                className="border"
                                                editorState={observacoesState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={handleObservacoesChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={CriarTarefa} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Inserir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >



    )
}

export default ListadeTarefas