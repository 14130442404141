import React, { useState, useEffect } from 'react';
import firebase from '../../config/firebase'
import { useParams } from 'react-router-dom'


function CronogramaFisico() {

    const { id } = useParams()

    //#region Variáveis
    let listaTarefas = []
    const [tarefas, setTarefas] = useState([]);
    const [item, setItem] = useState('')
    const [tarefa, setTarefa] = useState('')
    const [dataInicio, setDataInicio] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [dependencias, setDependencias] = useState('')
    const [custo, setCusto] = useState('')
    //#endregion

    //#region Inserir Cronogra Físico
    function InserirCronogramaFisico() {
        firebase.firestore().collection('Obras').doc(id).collection('CronogramaFisico').add({
            Item: item,
            Tarefa: tarefa,
            DataInicio: dataInicio,
            DataFim: dataFim,
            Dependências: dependencias,
            Custo: custo
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).collection('CronogramaFisico').orderBy('Item').get().then(async (resultado) => {
                await resultado.docs.forEach(doc => {
                    listaTarefas.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setTarefas(listaTarefas)
                setItem('')
                setTarefa('')
                setDataInicio('')
                setDataFim('')
                setDependencias('')
                setCusto('')
            })
        }).catch(erro => {

        })
    }
    //#endregion

    //#region Ler Banco de dados
    const InfoTarefas = () => {
        firebase.firestore().collection('Obras').doc(id).collection('CronogramaFisico').orderBy('Item').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaTarefas.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setTarefas(listaTarefas)
        })
    }

    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).collection('CronogramaFisico').orderBy('Item').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaTarefas.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setTarefas(listaTarefas)
        })
    }, [])
    //#endregion

    return (
        <div className="uk-card uk-card-default col-md-12">
            <div className="uk-card-header">
                <h3 className="uk-card-title uk-margin-remove-bottom">Cronograma Físico</h3>
            </div>
            <div className="uk-card-body">
                <table className="uk-table uk-table-divider">
                    <thead>
                        <tr>
                            <th>Tarefa</th>
                            <th>Subtarefa</th>
                            <th>Data de Início</th>
                            <th>Data de Fim</th>
                            <th>Dependências</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tarefas.map((tarefa, index) => (
                            <tr key={index}>
                                <td>{tarefa.tarefa}</td>
                                <td>{tarefa.subtarefa}</td>
                                <td>{tarefa.inicio.toLocaleDateString()}</td>
                                <td>{tarefa.fim.toLocaleDateString()}</td>
                                <td>{tarefa.dependencias}</td>
                                <td>{tarefa.valor}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="uk-card-footer">
                <button className="btn btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#AdicionarTarefaModal">Adicionar Tarefa</button>
            </div>

            {/* Modal */}
            <div className="modal fade" id="AdicionarTarefaModal" tabIndex="-1" aria-labelledby="AdicionarTarefaModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="AdicionarTarefaModalLabel">Adicionar Nova Tarefa</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* Campos do formulário */}
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-primary" onClick={InserirCronogramaFisico} data-bs-dismiss="modal">Adicionar Tarefa</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CronogramaFisico;