import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import firebase from '../../../config/firebase'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'



/** Páginas */

function Projetos() {

    const [item, setItem] = useState('')
    const [código, setCódigo] = useState('')
    const [descrição, setDescrição] = useState('')
    const [unidade, setUnidade] = useState('')
    const [custo, setCusto] = useState('')

    const [prazo, setPrazo] = useState('')

    const [profissionais, setProfissionais] = useState('')
    const [horasxmês, setHorasxmês] = useState('')
    const [mês, setMês] = useState('')

    const [EscolaA, setEscolaA] = useState('True')
    const [EscolaB, setEscolaB] = useState('True')
    const [EscolaC, setEscolaC] = useState('True')

    //#region Formatar Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    function Engenheiro() {
        setItem('02')
        setCódigo('AD 39.05.0122')
        setDescrição('Engenheiro, arquiteto ou geólogo jr (inclusive encargos sociais).(desonerado)')
        setUnidade('h')
        setCusto('78.56')
    }

    function Vigia() {
        setItem('03')
        setCódigo('AD 39.05.0218')
        setDescrição('Vigia (inclusive encargos sociais).(desonerado)')
        setUnidade('h')
        setCusto('13.25')
    }

    return (
        <>
            <div className="container">
                <div className='row'>
                    <h4 className=" mt-3 mb-3 text-center font-weight-bold">Criar Orçamentos</h4>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <h5 className='mt-4 text-bold'>Tabela SCO-RIO Janeiro/2022:</h5>
                    </div>
                    <div className='col-md-12'>
                        <table className="table table-hover text-nowrap mt-4">
                            {/** Cabeçalho Tabela */}
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Código</th>
                                    <th>Descrição</th>
                                    <th>Unidade</th>
                                    <th>Custo Unitário</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        01
                                    </td>
                                    <td>
                                        AD 19.20.0100
                                    </td>
                                    <td>
                                        Instalação e ligação provisória de obra de água e esgoto a rede pública.(desonerado)
                                    </td>
                                    <td>
                                        un
                                    </td>
                                    <td>
                                        {numberFormat(1124.47)}
                                    </td>
                                </tr>
                                <tr>

                                    <td>
                                        02
                                    </td>
                                    <td>
                                        <a onClick={Engenheiro}>AD 39.05.0122</a>
                                    </td>
                                    <td>
                                        Engenheiro, arquiteto ou geólogo jr (inclusive encargos sociais).(desonerado)
                                    </td>
                                    <td>
                                        h
                                    </td>
                                    <td>
                                        {numberFormat(78.56)}
                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                        03
                                    </td>
                                    <td>
                                        <a onClick={Vigia}>AD 39.05.0218</a>
                                    </td>
                                    <td>
                                        Vigia (inclusive encargos sociais).(desonerado)
                                    </td>
                                    <td>
                                        h
                                    </td>
                                    <td>
                                        {numberFormat(13.25)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='row'>
                    <h5 className=" mt-3 mb-3 text-center text-bold">Dados da Obra</h5>
                    <div className="col-md-3 mb-3">
                        <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setPrazo(e.target.value)} type="number" className="form-control" id="Item" label="Prazo Obra (Meses)" value={prazo && prazo} />
                    </div>
                </div>
                <div className='row mt-4'>
                    <h5 className=" mt-3 mb-3 text-center text-bold">Informações do Item</h5>
                    <div className="col-md-1 mb-3">
                        <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setItem(e.target.value)} type="text" className="form-control" id="Item" label="Item" value={item && item} />
                    </div>
                    <div className="col-md-3 mb-3">
                        <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setCódigo(e.target.value)} type="text" className="form-control" id="Código" label="Código" value={código && código} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setDescrição(e.target.value)} type="text" className="form-control" id="Descrição" label="Descrição" value={descrição && descrição} />
                    </div>
                    <div className="col-md-1 mb-3">
                        <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setUnidade(e.target.value)} type="text" className="form-control" id="Unidade" label="Unidade" value={unidade && unidade} />
                    </div>
                    <div className="col-md-1 mb-3">
                        <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setCusto(e.target.value)} type="text" className="form-control" id="Custo" label="Custo" value={custo && custo} />
                    </div>


                    {código == 'AD 39.05.0122' ?
                        <div className='row mt-4'>
                            <h5 className='mt-4 text-bold'>Entrada de Dados:</h5>
                            <div className="col-md-2 mb-3">
                                <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setProfissionais(e.target.value)} type="text" className="form-control" id="Item" label="Profissional" value={profissionais && profissionais} />
                            </div>
                            <div className="col-md-2 mb-3">
                                <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setHorasxmês(e.target.value)} type="text" className="form-control" id="Item" label="horas/mês" value={horasxmês && horasxmês} />
                            </div>
                            <div className="col-md-2 mb-3">
                                <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setMês(e.target.value)} type="number" className="form-control" id="Item" label="Meses" value={mês && mês} />
                            </div>
                            <h5 className='mt-4 text-bold'>Memória de Cálculo:</h5>
                            <h6>Quantidade de Horas = {profissionais} Profissionais x {horasxmês} horas/mês x {mês} meses = {profissionais * horasxmês * mês}</h6>
                            <br></br>
                            <br></br>
                            <h5 className='mt-4 text-bold'>Dica: </h5>
                            <br></br>
                            <h6>O engenheiro civil é o profissional responsável por projetar, gerenciar, executar e fiscalizar uma obra, que pode envolver casas, edifícios, pontes, viadutos, estradas, barragens, túneis, aeroportos e portos.</h6>
                            <h5 className='mt-4 text-bold'>Resoluções do TCM/RJ sobre este item:</h5>
                            <br></br>
                            <h6>Resolução nº 12.345/2022: Utilizar no máximo a carga horária mês de 185 h/mês, com o objetivo de respeitar os horários de descanso dos profissionais.</h6>
                            <h6>Resolução nº 67.890/2022: Não Utilizar juntamente com o item AD 39.05.0134 - Engenheiro Sênior.</h6>
                            <br></br>
                        </div>
                        :
                        ''}

                    {código == 'AD 39.05.0218' ?
                        <div className='row'>
                        <br></br>
                        <h5 className='text-bold text-danger mt-4'>Atenção:</h5>
                        <br></br>
                        <h6 className='text-bold text-danger'>Este item não deve ser utilizado segundo recomedação do TCM/RJ!</h6>
                    </div>

                        :
                        ''}

                    {custo == '' ? '' :
                        <div className='row'>
                            <h5 className='mt-4 text-bold'>Custo: </h5>
                            <h6>{numberFormat(custo)} x {profissionais * horasxmês * mês} = {numberFormat(custo * profissionais * horasxmês * mês)}</h6>
                        </div>
                    }

                    {horasxmês > 185 ?
                        <div className='row'>
                            <br></br>
                            <h5 className='text-bold text-danger mt-4'>Cuidado:</h5>
                            <br></br>
                            <h6 className='text-bold text-danger'>Seguindo a resolução do TCM/RJ nº 12.345/2022, não devemos usar valores maiores que 185 h/mês!</h6>
                            <br></br>
                            <br></br>
                            <h5 className='text-bold text-success'>Dica:</h5>
                            <h6 className='text-bold text-success'>Se precisar aumentar a quantidade de horas de Engenheiro, aumente a quantidade de profissionais.</h6>
                        </div>
                        : ''}

                    {profissionais > 4 ?
                        <div className='row'>
                            <h5 className='text-bold text-warning mt-4'>Cuidado:</h5>
                            <br></br>
                            <h6 className='text-bold text-warning'>Quantidade incomum de Profissionais de Engenharia, não deseja utilizar o Engenheiro Sénior - AD 39.05.0134 ?</h6>
                        </div>
                        : ''}

                    {Number(mês) > Number(prazo) ?
                        <div className='row'>
                            <h5 className='text-bold text-danger mt-4'>Cuidado:</h5>
                            <br></br>
                            <h6 className='text-bold text-danger'>O Engenheiro está planejado para {numberDecimalFormat(mês)} meses mas o prazo da obra é de {numberDecimalFormat(prazo)} meses.</h6>
                        </div>
                        : ''}

                </div>

            </div >
        </>

    )
}

export default Projetos