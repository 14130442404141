import React, { useState, useEffect } from 'react'
import firebase from '../../../config/firebase'

/** Material UI */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'


function FotosCard({ id, IdObra, IdPasta, Nome, Descricao, CardPastaSelecionada, carregarPastas, viewPasta, tamanhoPasta }) {

    const [nome, setNome] = useState(Nome)
    const [idObra, setIdObra] = useState(IdObra)
    const [idPasta, setIdPasta] = useState(IdPasta)
    const [descricao, setDescricao] = useState(Descricao)

    //#region Obter Pastas
    let listafotos = []
    const [RelFotos1, setRelFotos1] = useState([])

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('Obras').doc(idObra).collection('Fotos')
            .where(idPasta, '==', CardPastaSelecionada)
            .onSnapshot((snapshot) => {
                // Limpa a lista antes de atualizar com os novos dados
                listafotos = [];

                snapshot.forEach((doc) => {
                    listafotos.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                // Atualiza o estado com a lista atualizada
                setRelFotos1([...listafotos]);
            });

        // O retorno da função useEffect é chamado quando o componente é desmontado ou quando as dependências mudam
        return () => {
            // Isso cancela a inscrição quando o componente é desmontado
            unsubscribe();
        };
    }, [IdPasta]);
    //#endregion

    //#region Formatação do Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region Atualizar Pastas
    function AtualizarPastas() {
        firebase.firestore().collection('Obras').doc(idObra).collection('PastasDoc').doc(idPasta).update({
            Nome: nome,
            Descricao: descricao
        }).then(() => {

        }, [])
    }
    //#endregion

    //#region Excluir Pastas
    function ExcluirPasta() {
        // Exibe uma mensagem de confirmação e só continua se o usuário clicar em "OK"
        const confirmacao = window.confirm('Tem certeza que deseja apagar esta Pasta ?');

        if (confirmacao) {
            firebase.firestore().collection('Obras').doc(idObra).collection('PastasDoc').doc(idPasta).delete()
                .then(() => {
                    // Lógica após a exclusão
                    carregarPastas()
                    alert('Pasta apagada com sucesso!')
                })
                .catch(erro => {
                    console.error('Erro ao apagar pasta:', erro);
                    alert('Erro ao apagar a pasta. Verifique o console para mais detalhes.');
                });
        }
    }
    //#endregion

    useEffect(() => {
        setNome(Nome)
        setDescricao(Descricao)
        setIdObra(IdObra)
        setIdPasta(IdPasta)
    }, [Nome, Descricao.IdObra, IdPasta])

    return (
        <>
            {id}
            <h3>{nome}</h3>
            <h5>{descricao} - {tamanhoPasta} Mb</h5>
            {viewPasta == 'True' ?
                <>
                    <button className="btn btn-sm btn-outline-warning my-3" data-bs-toggle="modal" data-bs-target={`#A${idPasta}-modalcard-1`}>Editar</button>
                    {tamanhoPasta == '0,00' ?
                        <button className="btn btn-sm btn-outline-danger ml-2 my-3" data-bs-toggle="modal" data-bs-target={`#A${idPasta}-modalcard-2`}>Excluir</button>
                        : <p><span className='text-red fw-bolder'>Atenção:</span> Para excluir a pasta é necessário excluir todas as fotos da pasta.</p>}
                </>
                : ''}

            {/** Modal para editar o texto da Pasta */}
            <div className="modal fade" id={`A${idPasta}-modalcard-1`} tabIndex="-1" aria-labelledby={`A${idPasta}-exampleModalLabel-1`} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações da Pasta</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="col-md-12 mb-5">
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setNome(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    label="Nome da Pasta"
                                    value={nome && nome} />
                            </div>
                            <div className="col-md-12 mb-5">
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    label="Descrição da Foto"
                                    value={descricao && descricao} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button onClick={AtualizarPastas} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/** Modal para excluir a Pasta */}
            <div className="modal fade" id={`A${idPasta}-modalcard-2`} tabIndex="-1" aria-labelledby={`A${idPasta}-exampleModalLabel-2`} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Excluir Informações da Pasta</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="col-md-12 mb-5">
                                <TextField
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setNome(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    label="Nome da Pasta"
                                    value={nome && nome} />
                            </div>
                            <div className="col-md-12 mb-5">
                                <TextField
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    label="Descrição da Foto"
                                    value={descricao && descricao} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button onClick={ExcluirPasta} className="btn btn-sm btn-outline-danger" data-bs-dismiss="modal">Excluir</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FotosCard