import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

/** Material UI  */
import { TextField } from '@mui/material'

function InfoFinanceiras() {

    const { id } = useParams()

    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);

    const [InformaçõesFinanceiras, setInformaçõesFinanceiras] = useState([])
    const [Investimento, setInvestimento] = useState('')
    const [I0, setI0] = useState('')
    const [Font, setFont] = useState('')
    const [EmpenhoFinanceiro, setEmpenhoFinanceiro] = useState('')

    /** Buscar Informações Financeiras */
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setInformaçõesFinanceiras(resultado.data().InformaçõesFinanceiras)
            setInvestimento(resultado.data().InformaçõesFinanceiras.Investimento)
            setI0(resultado.data().InformaçõesFinanceiras.I0)
            setFont(resultado.data().InformaçõesFinanceiras.Font)
            setEmpenhoFinanceiro(resultado.data().InformaçõesFinanceiras.EmpenhoFinanceiro)
        })
    }, [])

    /** Editar Informações Financeiras */
    function EditarInformaçõesFinanceiras() {
        firebase.firestore().collection('Obras').doc(id).update({
            InformaçõesFinanceiras: {
                Investimento: Investimento,
                I0: I0,
                Font: Font,
                EmpenhoFinanceiro: EmpenhoFinanceiro
            }
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setInformaçõesFinanceiras(resultado.data().InformaçõesFinanceiras)
                setInvestimento(resultado.data().InformaçõesFinanceiras.Investimento)
                setI0(resultado.data().InformaçõesFinanceiras.I0)
                setFont(resultado.data().InformaçõesFinanceiras.Font)
                setEmpenhoFinanceiro(resultado.data().InformaçõesFinanceiras.EmpenhoFinanceiro)
            })
        })
    }

    return (
        <>
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 className="uk-card-title uk-margin-remove-bottom">Informações Básicas</h3>
                        </div>
                    </div>
                </div>
                <div className="uk-card-body">
                    <div className="row">
                        {/** Investimento */}
                        <div className="row col-md-12">
                            <p>
                                <span className="font-weight-bold">Investimento:</span>
                                <br></br>
                                {numberFormat(InformaçõesFinanceiras.Investimento)}
                            </p>
                        </div>

                        {/** I0 */}
                        <div className="row col-md-12">
                            <p>
                                <span className="font-weight-bold">I0:</span>
                                <br></br>
                                {InformaçõesFinanceiras.I0}
                            </p>
                        </div>

                        {/** Font */}
                        <div className="row col-md-12">
                            <p>
                                <span className="font-weight-bold">Font:</span>
                                <br></br>
                                {InformaçõesFinanceiras.Font}
                            </p>
                        </div>

                        {/** Cronograma Financeiro */}
                        <div className="row col-md-12">
                            <p>
                                <span className="font-weight-bold">Empenho Financeiro:</span>
                                <br></br>
                                {numberFormat(InformaçõesFinanceiras.EmpenhoFinanceiro)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="uk-card-footer">
                    <button href="#" className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#EditarInformaçõesFinanceiras">Editar</button>
                </div>
                <div class="modal fade" id="EditarInformaçõesFinanceiras" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Editar Informações Financeiras</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body row">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <TextField 
                                        onChange={(e) => setInvestimento(e.target.value)} 
                                        className="form-control" 
                                        label="Investimento" 
                                        type="number" 
                                        value={Investimento && Investimento} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField 
                                        onChange={(e) => setI0(e.target.value)} 
                                        className="form-control" 
                                        label="I0" 
                                        type="text" 
                                        value={I0 && I0} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField 
                                        onChange={(e) => setFont(e.target.value)} 
                                        className="form-control" 
                                        label="Font" 
                                        type="text" 
                                        value={Font && Font} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField 
                                        onChange={(e) => setEmpenhoFinanceiro(e.target.value)} 
                                        className="form-control" 
                                        label="Empenho Financeiro" 
                                        type="number" 
                                        value={EmpenhoFinanceiro && EmpenhoFinanceiro} />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                <button onClick={EditarInformaçõesFinanceiras} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Atualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default InfoFinanceiras