import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyClrx7EYKDQe3_ctV0bzCtGwEtoZXix0bE",
    authDomain: "programaconstrucao-cd195.firebaseapp.com",
    databaseURL: "https://programaconstrucao-cd195.firebaseio.com",
    projectId: "programaconstrucao-cd195",
    storageBucket: "programaconstrucao-cd195.appspot.com",
    messagingSenderId: "140758035749",
    appId: "1:140758035749:web:33a5242dae1752bf5e3ecc",
    measurementId: "G-ES1D4YRFLL"
};
// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);