import React, { useState, useRef } from 'react'
import firebase from '../../../config/firebase'

/** Material UI */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'

/** Editor de Texto */
import JoditEditor from 'jodit-react'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'


function Obras({ id, IdObra, InicioSuspensão, TérminoSuspensão, PrazoSuspenso, MotivaçãoSuspensão, Editar, SuspensoaId, InfoSuspensão }) {

    const [suspensoaId, setSuspensãoId] = useState(SuspensoaId)
    const [idObra, setIdObra] = useState(IdObra)
    const [incioSuspensão, setInicioSuspensão] = useState(InicioSuspensão)
    const [términoSuspensão, setTérminoSuspensão] = useState(TérminoSuspensão)
    const [motivaçãoSuspensão, setMotivaçãoSuspensão] = useState(MotivaçãoSuspensão)
    const [prazoSuspenso, setPrazoSuspenso] = useState(PrazoSuspenso)

    const editor = useRef(null);

    //#region Atualizar Suspensão
    function AtualizarSuspensão() {
        firebase.firestore().collection('Obras').doc(idObra).collection('Suspensão').doc(suspensoaId).update({
            InicioSuspensão: incioSuspensão,
            MotivaçãoSuspensão: motivaçãoSuspensão,
            PrazoSuspenso: moment(términoSuspensão).diff(incioSuspensão, 'days'),
            TerminoSuspensão: términoSuspensão
        }).then(() => {
            InfoSuspensão()
        }, [])
    }
    //#endregion

    //#region Excluir Suspensão
    function ExcluirSuspensão() {
        // Exibe uma mensagem de confirmação e só continua se o usuário clicar em "OK"
        const confirmacao = window.confirm('Tem certeza que deseja apagar esta suspensão?');

        if (confirmacao) {
            firebase.firestore().collection('Obras').doc(idObra).collection('Suspensão').doc(suspensoaId).delete()
                .then(() => {
                    // Lógica após a exclusão
                    InfoSuspensão();
                    alert('Suspensão apagada com sucesso!');
                })
                .catch(erro => {
                    console.error('Erro ao apagar suspensão:', erro);
                    alert('Erro ao apagar suspensão. Verifique o console para mais detalhes.');
                });
        }
    }
    //#endregion

    return (
        <>
            {Editar == 'Não' &&
                <div className="row col-md-12">
                    {id}
                    {InicioSuspensão}
                    {TérminoSuspensão}
                    {PrazoSuspenso}
                    <p dangerouslySetInnerHTML={{ __html: MotivaçãoSuspensão }} />
                </div>
            }
            {Editar == 'Sim' &&
                <>
                    {/** Botão Editar */}
                    <button className='btn btn-sm btn-outline-warning' type="button" data-bs-toggle="modal" data-bs-target={`#A${SuspensoaId}-modal-1`}>Editar</button>
                    <div className="modal fade" id={`A${SuspensoaId}-modal-1`} tabIndex="-1" aria-labelledby={`A${SuspensoaId}-exampleModalLabel-1`} aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Ediar Informações Suspensões</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='row col-md-12 mb-5'>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setIdObra(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="ID Obra"
                                                value={idObra && idObra} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setSuspensãoId(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="ID Suspensão"
                                                value={suspensoaId && suspensoaId} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setInicioSuspensão(e.target.value)}
                                                className="form-control"
                                                label="Data de Início"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={incioSuspensão && incioSuspensão} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setTérminoSuspensão(e.target.value)}
                                                className="form-control"
                                                label="Data de Reinício"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={términoSuspensão && términoSuspensão} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <TextField
                                            disabled
                                            onChange={(e) => setPrazoSuspenso(e.target.value)}
                                            className="form-control"
                                            label="Período"
                                            type="text"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={moment(términoSuspensão).diff(incioSuspensão, 'days')} />
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <span >Motivo da Suspensão:</span>
                                        <JoditEditor
                                            className=''
                                            ref={editor}
                                            value={motivaçãoSuspensão}
                                            tabIndex={1}
                                            onChange={newContent => setMotivaçãoSuspensão(newContent)}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                    <button onClick={AtualizarSuspensão} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** Botão Excluir */}
                    <button className='btn btn-sm btn-outline-danger ml-2' type="button" data-bs-toggle="modal" data-bs-target={`#A${SuspensoaId}-modal-2`}>Excluir</button>
                    <div className="modal fade" id={`A${SuspensoaId}-modal-2`} tabIndex="-1" aria-labelledby={`A${SuspensoaId}-exampleModalLabel-1`} aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Excluir Informações Suspensões</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='row col-md-12 mb-5'>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setIdObra(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="ID Obra"
                                                value={idObra && idObra} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setSuspensãoId(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="ID Suspensão"
                                                value={suspensoaId && suspensoaId} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setInicioSuspensão(e.target.value)}
                                                className="form-control"
                                                label="Data de Início"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={incioSuspensão && incioSuspensão} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setTérminoSuspensão(e.target.value)}
                                                className="form-control"
                                                label="Data de Início"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={términoSuspensão && términoSuspensão} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <span className="font-weight-bold">Motivo da Suspensão:</span>
                                        <p dangerouslySetInnerHTML={{ __html: MotivaçãoSuspensão }} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                    <button onClick={ExcluirSuspensão} type="button" className="btn btn-sm btn-outline-danger" data-bs-dismiss="modal">Excluir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Obras