import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'
import './detalhe-obras.css'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

/** Material Ui */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'

import SuspensõesCard from './suspensõescard'

function Suspensões() {

    const { id } = useParams()
    var today = moment().startOf('day')

    //#region Buscar Informações Básicas
    const [InformaçõesBásicas, setInformaçõesBásicas] = useState([])
    const [criaçãoobra, setCriaçãoObra] = useState()
    const [TituloReduzido, setTituloReduzido] = useState('')
    const [TituloCompleto, setTituloCompleto] = useState('')
    const [Status, setStatus] = useState('')
    const [PrincipaisServiços, setPrincipaisServiços] = useState('')
    const [InformaçõesAdicionais, setInformaçõesAdicionais] = useState('')

    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setInformaçõesBásicas(resultado.data().InformaçõesBásicas)
            setCriaçãoObra(resultado.data().criacao)
            setTituloReduzido(resultado.data().InformaçõesBásicas.TituloReduzido)
            setTituloCompleto(resultado.data().InformaçõesBásicas.TituloCompleto)
            setInformaçõesAdicionais(resultado.data().InformaçõesBásicas.InformaçõesAdicionais)
            setPrincipaisServiços(resultado.data().InformaçõesBásicas.PrincipaisServiços)
            setStatus(resultado.data().InformaçõesBásicas.Status)
        })
    }, [])
    //#endregion

    //#region Formatar Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region Cronograma
    const [Cronograma, setCronograma] = useState([])
    const [DataInicio, setDataInicio] = useState('')
    const [DataTermino, setDataTermino] = useState('')
    const [Suspenso, setSuspenso] = useState('')
    const [PrazoDias, setPrazoDias] = useState('')
    const [CronogramaFísico, setCronogramaFísico] = useState('')

    /** Buscar Informações Cronograma */
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setCronograma(resultado.data().Cronograma)
            setDataInicio(resultado.data().Cronograma.DataInicio)
            setDataTermino(resultado.data().Cronograma.DataTermino)
            setPrazoDias(resultado.data().Cronograma.PrazoDias)
            setCronogramaFísico(resultado.data().Cronograma.CronogramaFísico)
            setSuspenso(resultado.data().Cronograma.Suspenso)
        })
    }, [])

    /** Editar Informações Cronograma */
    function EditarInformaçõesCronograma() {
        firebase.firestore().collection('Obras').doc(id).update({
            Cronograma: {
                DataInicio: DataInicio,
                DataTermino: DataTermino,
                Suspenso: Suspenso ? Suspenso : '',
                PrazoDias: PrazoDias
            }
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setCronograma(resultado.data().Cronograma)
                setDataInicio(resultado.data().Cronograma.DataInicio)
                setDataTermino(resultado.data().Cronograma.DataTermino)
                setSuspenso(resultado.data().Cronograma.Suspenso)
                setPrazoDias(resultado.data().Cronograma.PrazoDias)
            })
        })
    }
    //#endregion

    //#region Carregar informações
    const InfoSuspensão = () => {
        firebase.firestore().collection('Obras').doc(id).collection('Suspensão').orderBy('InicioSuspensão').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaSuspensões.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setSuspensão(listaSuspensões)
        })
    }


    //#region Suspensões

    /** Obter Valores Medição */
    let listaSuspensões = []
    const [Suspensão, setSuspensão] = useState([])
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).collection('Suspensão').orderBy('InicioSuspensão').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaSuspensões.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setSuspensão(listaSuspensões)
        })
    }, [])

    //#region Inserir Suspensão
    const [InicioSuspensão, setInicioSuspensão] = useState('')
    const [TérminoSuspensão, setTérminoSuspensão] = useState('')
    const [PrazoSuspenso, setPrazoSuspenso] = useState('')
    const [MotivaçãoSuspensão, setMotivaçãoSuspensão] = useState('')

    function InserirSuspensão() {
        firebase.firestore().collection('Obras').doc(id).collection('Suspensão').add({
            InicioSuspensão: InicioSuspensão,
            TérminoSuspensão: TérminoSuspensão,
            PrazoSuspenso: moment(TérminoSuspensão).diff(InicioSuspensão, 'days'),
            MotivaçãoSuspensão: MotivaçãoSuspensão
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).collection('Suspensão').orderBy('InicioSuspensão').get().then(async (resultado) => {
                await resultado.docs.forEach(doc => {
                    listaSuspensões.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setSuspensão(listaSuspensões)
                setInicioSuspensão('')
                setTérminoSuspensão('')
                setPrazoSuspenso('')
                setMotivaçãoSuspensão('')
            })
        }).catch(erro => {

        })
    }
    //#endregion

    //#endregion


    return (
        <div>
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Prazos</h3>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <div className="row">
                        {/** Data de Início */}
                        <div className="col-md-12">
                            <p>
                                <span className="font-weight-bold">Data de Início:</span>
                                <br></br>
                                {moment(Cronograma.DataInicio).format('L')}
                            </p>
                        </div>
                        {/** Data de Término */}
                        <div className="col-md-12">
                            <p>
                                <span className="font-weight-bold">Data de Término:</span>
                                <br></br>
                                {moment(Cronograma.DataTermino).format('L')}
                            </p>
                        </div>
                        {/** Prazo Obra */}
                        <div className="col-md-12">
                            <p>
                                <span className="font-weight-bold">Prazo Obra:</span>
                                <br></br>
                                {PrazoDias} Dias
                            </p>
                        </div>

                        <h6 className='col-md-12 font-weight-bold text-red my-2'>Análise das Informações</h6>

                        <div className="col-md-12">
                            <span className="font-weight-bold">Status da Obra:</span>
                            <p>O Status dessa obra está definido como: {InformaçõesBásicas.Status}</p>
                        </div>

                        {/** Conferência do Prazo */}
                        <div className="col-md-12">
                            <p>
                                <span className="font-weight-bold">Conferência Prazo da Obra:</span>
                                <br></br>
                                {InformaçõesBásicas.Status == 'Concluído' &&
                                    Number(moment(Cronograma.DataTermino).diff(Cronograma.DataInicio, 'days')
                                        + 1
                                        - Number(numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0))))
                                } Dias
                                {InformaçõesBásicas.Status == 'Paralisado / Suspenso' &&
                                    (Number(moment(Cronograma.DataTermino).diff(Cronograma.DataInicio, 'days'))
                                        - Number(numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0))) == PrazoDias ?
                                        PrazoDias
                                        :
                                        <p> <span className='font-weight-bold text-red'> Atenção: </span>Existe algum erro na "Data de Início", "Data de Término" ou no "Prazo Obra" informado !</p>)}

                            </p>
                        </div>

                        {/** Período Total suspenso */}
                        <div className="col-md-12">
                            <p>
                                <span className="font-weight-bold">Período Total Suspenso:</span>
                                <br></br>
                                {Number(numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0)))} Dias
                            </p>
                        </div>

                        {/** Prazo Decorrido Após Início*/}
                        <div className="col-md-12">
                            <p>
                                <span className="font-weight-bold">Dias Corridos Após o início:</span>
                                <br></br>
                                {moment(today).diff(Cronograma.DataInicio, 'days') + 1} Dias
                            </p>
                        </div>

                        <div className="col-md-12">
                            {InformaçõesBásicas.Status == 'Paralisado / Suspenso' &&
                                (
                                    <p>
                                        <span className="font-weight-bold">Prazo Efetivo de Obra:</span>
                                        <br></br>
                                        {InformaçõesBásicas.Status == 'Paralisado / Suspenso' ?
                                            <>
                                                {Number(moment(today).diff(Cronograma.DataInicio, 'days'))
                                                    - Number(numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0)))
                                                    - Number(moment(today).diff(Cronograma.Suspenso, 'days'))} Dias
                                            </>
                                            :
                                            <>
                                                {Number(moment(today).diff(Cronograma.DataInicio, 'days'))
                                                    - Number(numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0)))} Dias
                                            </>
                                        }
                                    </p>)}
                        </div>

                        <div className="col-md-12">
                            <p>
                                <span className="font-weight-bold">Prazo Consumido:</span>
                                <br></br>
                                {InformaçõesBásicas.Status == 'Paralisado / Suspenso' &&

                                    (numberDecimalFormat((Number(moment(today).diff(Cronograma.DataInicio, 'days'))
                                        - Number(numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0)))) / Number(PrazoDias) * 100) + '%'
                                    )}

                                {InformaçõesBásicas.Status == 'Concluído' && '100 %'}

                            </p>
                        </div>

                        {/** Informações de Obra Suspensa */}
                        {InformaçõesBásicas.Status == 'Paralisado / Suspenso' ?
                            <>
                                <h6 className='col-md-12 font-weight-bold text-red my-2'>Informações de Suspensão</h6>
                                <div className="col-md-3">
                                    <p>
                                        <span className="font-weight-bold">Obras Suspensa em:</span>
                                        <br></br>
                                        {moment(Cronograma.Suspenso).format('L')}
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p>
                                        <span className="font-weight-bold">Prazo Atual Suspensão:</span>
                                        <br></br>
                                        {InformaçõesBásicas.Status == 'Paralisado / Suspenso' ? moment(today).diff(Cronograma.Suspenso, 'days') : Number(0)} Dias
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p>
                                        <span className="font-weight-bold">Prazo Suspensões Anteriores:</span>
                                        <br></br>
                                        {numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0))} Dias
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <p>
                                        <span className="font-weight-bold">Prazo Total Suspenso:</span>
                                        <br></br>
                                        {Number(numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0))) + Number(moment(today).diff(Cronograma.Suspenso, 'days'))} Dias
                                    </p>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>
                <div className="uk-card-footer">
                    <button className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#EditarPrazos">Editar</button>
                </div>
                <div class="modal fade" id="EditarPrazos" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Editar Informações Cronograma</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body row">
                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <TextField
                                            onChange={(e) => setDataInicio(e.target.value)}
                                            className="form-control"
                                            label="Data de Início"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={DataInicio && DataInicio} />
                                    </div>

                                    <div className="col-md-12 my-4">
                                        <TextField
                                            onChange={(e) => setDataTermino(e.target.value)}
                                            className="form-control"
                                            label="Data de Término"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={DataTermino && DataTermino} />
                                    </div>

                                    <div className="col-md-12 my-4">
                                        <TextField
                                            onChange={(e) => setPrazoDias(e.target.value)}
                                            className="form-control"
                                            label="Prazo da Obra (Dias)"
                                            type="text"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={PrazoDias && PrazoDias} />
                                    </div>
                                    {InformaçõesBásicas.Status == 'Paralisado / Suspenso' ?
                                        <>
                                            <span className='text-red mt-2'>Informações da Paralisação / Suspensão</span>
                                            <div className="col-md-12 mt-2">
                                                <TextField
                                                    onChange={(e) => setSuspenso(e.target.value)}
                                                    className="form-control"
                                                    label="Suspenso em"
                                                    type="date"
                                                    InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                    value={Suspenso && Suspenso} />
                                            </div>
                                        </>
                                        : ''}
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={EditarInformaçõesCronograma} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** */}
            <div className="uk-card uk-card-default col-md-12 mt-3">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Histórico Suspensões</h3>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <table className="table table-hover text-nowrap">
                        {/** Cabeçalho Tabela */}
                        <thead>
                            <tr>
                                <th>Data Início</th>
                                <th>Data Término</th>
                                <th>Período (Dias)</th>
                                <th>Motivação</th>
                                <th>Edição</th>
                            </tr>
                        </thead>

                        {/** Composição Tabela */}
                        {Suspensão.map(item =>
                            <tbody>
                                <tr>
                                    {/** Início */}
                                    <td>
                                        <SuspensõesCard
                                            key={item.id}
                                            InicioSuspensão={moment(item.InicioSuspensão).format('L')}
                                            Editar={"Não"}
                                        />
                                    </td>

                                    {/** Término */}
                                    <td>
                                        <SuspensõesCard
                                            key={item.id}
                                            TérminoSuspensão={moment(item.TérminoSuspensão).format('L')}
                                            Editar={"Não"}
                                        />
                                    </td>

                                    {/** Período */}
                                    <td>
                                        <SuspensõesCard
                                            key={item.id}
                                            PrazoSuspenso={numberIntegerFormat(item.PrazoSuspenso)}
                                            Editar={"Não"}
                                        />
                                    </td>

                                    {/** Motivação */}
                                    <td className='motivo-cell'>
                                        <SuspensõesCard
                                            key={item.id}
                                            MotivaçãoSuspensão={item.MotivaçãoSuspensão}
                                            Editar={"Não"}
                                        />
                                    </td>
                                    {/** Motivação */}
                                    <td>
                                        <SuspensõesCard
                                            key={item.id}
                                            IdObra={id}
                                            SuspensoaId={item.id}
                                            InicioSuspensão={item.InicioSuspensão}
                                            TérminoSuspensão={item.TérminoSuspensão}
                                            MotivaçãoSuspensão={item.MotivaçãoSuspensão}
                                            InfoSuspensão={InfoSuspensão}
                                            Editar={"Sim"}
                                        />
                                    </td>

                                </tr>
                            </tbody>)}
                    </table>
                </div>
                <div className="uk-card-footer">
                    <a className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#EditarSuspensão">Adicionar</a>
                </div>
                {/** Caixa de diálogo Inserir Suspensões */}
                <div class="modal fade" id="EditarSuspensão" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Inserir Suspensões</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body row">
                                <div className="row">
                                    <div className='col-md-12 mb-4'>
                                        <span>Atenção: Preencha apenas as suspensões com data de início e data de término conhecidos.</span>
                                        <br></br>
                                        <span>Se você não tiver a data de término da suspensão, preencha apenas o campo "Obra suspensa em: "</span>
                                    </div>
                                    <div className="col-md-12 my-4">
                                        <TextField
                                            onChange={(e) => setInicioSuspensão(e.target.value)}
                                            className="form-control"
                                            label="Data de Início"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={InicioSuspensão && InicioSuspensão} />
                                    </div>
                                    <div className="col-md-12 my-4">
                                        <TextField
                                            onChange={(e) => setTérminoSuspensão(e.target.value)}
                                            className="form-control"
                                            label="Data de Término"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={TérminoSuspensão && TérminoSuspensão} />
                                    </div>
                                    <div className="col-md-12 my-4">
                                        <TextField
                                            disabled
                                            onChange={(e) => setPrazoSuspenso(e.target.value)}
                                            className="form-control"
                                            label="Período"
                                            type="text"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={moment(TérminoSuspensão).diff(InicioSuspensão, 'days')} />
                                    </div>
                                    <div className="col-md-12 my-4">
                                        <TextField
                                            onChange={(e) => setMotivaçãoSuspensão(e.target.value)}
                                            className="form-control"
                                            label="Motivação"
                                            type="text"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={MotivaçãoSuspensão && MotivaçãoSuspensão} />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                <button onClick={InserirSuspensão} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Inseir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Suspensões