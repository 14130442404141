import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import firebase from '../../../config/firebase'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'

/** Componentes */
import Menu from '../../components/menu/login'
import Cabeçalho from '../../components/cabeçalho'
import Footer from '../../components/footer'


/** Páginas */

function Projetos() {
    const [TituloReduzido, setTituloReduzido] = useState('')
    const [TituloCompleto, setTituloCompleto] = useState('')
    const [DataInicio, setDataInicio] = useState('')
    const [DataTermino, setDataTermino] = useState('')
    const [Status, setStatus] = useState('')
    const [Físico, setFísico] = useState('')
    const [Investimento, setInvestimento] = useState('')
    const [I0, setI0] = useState('')
    const [Font, setFont] = useState('')
    const [EmpenhoFinanceiro, setEmpenhoFinanceiro] = useState('')
    const [TituloMemorialDescritivo, setTituloMemorialDescritivo] = useState('')
    const [InfoAdicionais, setInfoAdicionais] = useState('')
    const [RelFinal, setRelFinal] = useState('')
    const [Endereço, setEndereço] = useState('')
    const [Estado, setEstado] = useState('')
    const [Cidade, setCidade] = useState('')
    const [Bairro, setBairro] = useState('')
    const [Cep, setCep] = useState('')
    const [AP, setAP] = useState('')
    const [RA, setRA] = useState('')
    const [NContrato, setNContrato] = useState('')
    const [NProcesso, setNProcesso] = useState('')
    const [NSiscob, setNSiscob] = useState('')
    const [NomeContratante, setNomeContratante] = useState('')
    const [CNPJContratante, setCNPJContratante] = useState('')
    const [ContratanteResponsável, setContratanteResponsável] = useState('')
    const [NomeContratado, setNomeContratado] = useState('')
    const [CNPJContratado, setCNPJContratado] = useState('')
    const [ContratadoResponsável, setContratadoResponsável] = useState('')
    const [PrincipaisServiços, setPrincipaisServiços] = useState('')
    const [infoPreliminar, setInfoPreliminar] = useState('')
    const [InformaçõesAdicionais, setInformaçõesAdicionais] = useState('')
    const [Documento, setDocumento] = useState('')
    const [msgTipo, setMsgTipo] = useState('')
    const [Fiscal1, setFiscal1] = useState('')
    const [Fiscal2, setFiscal2] = useState('')
    const [Fiscal3, setFiscal3] = useState('')

    const UsuarioEmail = useSelector(state => state.usuarioEmail)
    const db = firebase.firestore()

    //#region Formatar Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    function Cadastrar() {
        db.collection('Obras').add({
            Contagem: 1,
            criacao: new Date(),
            InformaçõesPreliminares: {
                infoPreliminar: infoPreliminar
            },
            InformaçõesBásicas: {
                TituloReduzido: TituloReduzido,
                TituloCompleto: TituloCompleto,
                Status: Status,
                PrincipaisServiços: PrincipaisServiços,
                InformaçõesAdicionais: InformaçõesAdicionais
            },
            MemorialDescritivo: {
                TituloMemorialDescritivo: TituloMemorialDescritivo
            },
            InformaçõesFísicas: {
                Físico: Físico
            },
            InformaçõesFinanceiras: {
                Investimento: Investimento,
                I0: I0,
                Font: Font,
                EmpenhoFinanceiro: EmpenhoFinanceiro
            },
            Documentação: {
                Documento: Documento
            },
            InformaçõesFinais: {
                RelFinal: RelFinal
            },
            Cronograma: {
                DataInicio: DataInicio,
                DataTermino: DataTermino
            },
            Contrato: {
                NContrato: NContrato,
                NProcesso: NProcesso,
                NSiscob: NSiscob
            },
            Contratado: {
                NomeContratado: NomeContratado,
                CNPJContratado: CNPJContratado,
                ContratadoResponsável: ContratadoResponsável
            },
            Contratante: {
                NomeContratante: NomeContratante,
                CNPJContratante: CNPJContratante,
                ContratanteResponsável: ContratanteResponsável
            },
            Fiscalização: {
                Fiscal1: Fiscal1,
                Fiscal2: Fiscal2,
                Fiscal3: Fiscal3
            },
            Localização: {
                Endereço: Endereço,
                Estado: Estado,
                Cidade: Cidade,
                Bairro: Bairro,
                Cep: Cep,
                AP: AP,
                RA: RA
            },
            Pastas: [],
            EmailEdição: [UsuarioEmail],
            EmailView: [UsuarioEmail]
        }).then(() => {
            setMsgTipo('Sucesso')
        }).catch(erro => {
            setMsgTipo('Erro')
        })
    }

    return (
        <>
            <Menu />
            <Cabeçalho />
            <div className="container-fluid">
                <div className='row mt-5'>
                    <h2 className=" mt-3 mb-3 text-center font-weight-bold">Adicionar Obras</h2>
                </div>
                <div className='row'>
                    <div className="col-md-8 mb-3">
                        <TextField onChange={(e) => setTituloReduzido(e.target.value)} type="text" className="form-control" id="TituloReduzido" label="Título Reduzido" value={TituloReduzido && TituloReduzido} />
                    </div>
                    <div className="col-md-12 mb-3 mt-3">
                        <label>Título Completo</label>
                        <textarea onChange={(e) => setTituloCompleto(e.target.value)} rows="3" className="form-control" id="TituloCompleto" placeholder="Título Completo" value={TituloCompleto && TituloCompleto} />
                    </div>
                    <div className="col-md-2 mb-3 mt-3">
                        <TextField onChange={(e) => setDataInicio(e.target.value)} type="date" className="form-control" id="DataInicio" label="Data Início" value={DataInicio && DataInicio} InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }} />
                    </div>
                    <div className="col-md-2 mb-3 mt-3">
                        <TextField 
                        onChange={(e) => setDataTermino(e.target.value)} 
                        type="date" 
                        className="form-control" 
                        id="DataTermino" 
                        label="Data Término" 
                        value={DataTermino && DataTermino} 
                        InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }} />
                    </div>
                    <div className="col-md-3 mb-3 mt-3">
                        <TextField onChange={(e) => setStatus(e.target.value)} select label="Status" className="form-control" id="Status" value={Status && Status} >
                            <MenuItem disabled selected value>-- Selecione um tipo --</MenuItem >
                            <MenuItem value={'Em Estudo / A Iniciar'} >Em Estudo / A Iniciar</MenuItem >
                            <MenuItem value={'Em Andamento'} >Em Andamento</MenuItem >
                            <MenuItem value={'Paralisado / Suspenso'}>Paralisado / Suspenso</MenuItem >
                            <MenuItem value={'Concluído'}>Concluído</MenuItem >
                        </TextField>
                    </div>
                    <div className="col-md-3 input-group mb-3 mt-3">
                        <div class="input-group">
                            <TextField onChange={(e) => numberDecimalFormat(setInvestimento(e.target.value))} type="number" className="form-control" id="InvestimentoFinanceiro" label="Investimento Financeiro" value={Investimento && Investimento} InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }} />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3 mt-3">
                        <label>Principais Serviços</label>
                        <textarea onChange={(e) => setPrincipaisServiços(e.target.value)} rows="3" className="form-control" id="PrincipaisServiços" placeholder="Principais Serviços" value={PrincipaisServiços && PrincipaisServiços} />
                    </div>
                    <div className="col-md-12 mb-3 mt-3">
                        <label>Informações Adicionais</label>
                        <textarea onChange={(e) => setInformaçõesAdicionais(e.target.value)} rows="3" className="form-control" id="InformaçõesAdicionais" placeholder="Informações Adicionais" value={InformaçõesAdicionais && InformaçõesAdicionais} />
                    </div>
                    <div className="col-md-12" align="center">
                        <button onClick={Cadastrar} type="button" className="btn btn-lg mt-3 btn-cadastro">Cadastrar Obra</button>
                    </div>
                    <div className="msg-login text-center mt-2">
                        {msgTipo === 'Sucesso' && <h2><strong>Obra Cadastrada !</strong></h2>}
                        {msgTipo === 'Erro' && <h2><strong>Ops, Não foi possível cadastrar a Obra !</strong></h2>}
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Projetos