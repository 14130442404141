import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import firebase from '../../config/firebase'

function Menu() {

    const usuarioEmail = useSelector(state => state.usuarioEmail)
    const dispatch = useDispatch()

    const [urlImagens, setUrlImagens] = useState()


    return (
        <>
            {/** Nav Superior */}
            <nav className="navbar border-bottom">
                {/* Left navbar links */}
                <div>
                    <Link className="" type="button" uk-toggle="target: #offcanvas-nav">
                        <span className='text-white' uk-icon="icon: menu" />
                        <span className="text-black uk-margin-small-left mr-2 text-white text-navbar text-bold"></span>
                    </Link>
                    <div id="offcanvas-nav" uk-offcanvas="overlay: true">
                        <div className="uk-offcanvas-bar">
                            <button className="uk-offcanvas-close uk-background-secondary " uk-icon="icon: close" type="button" uk-close></button>
                            <ul className="uk-nav uk-nav-default uk-nav-parent-icon" uk-nav="multiple: false">
                                <li className="uk-nav-header">
                                    <Link className="nav-link" to='/'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: home" />Home</h5></Link>
                                </li>
                                <li class="uk-nav-divider my-2"></li>
                                <li><Link className="d-block" to='/login'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: sign-in" />Faça Login</h5></Link></li>
                                <li><Link className="d-block" to='/novousuario'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: user" />Cadastrar</h5></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/** navbar center */}
                <div class="uk-navbar-center mt-1">
                    <Link to='/'>
                        <h6 className='text-white text-navbar text-bold'>CONSTRUCAOBR.COM</h6>
                    </Link>
                </div>
                {/* Right navbar links */}
                <div className="uk-navbar-right">

                </div>
            </nav>
        </>
    )
}

export default Menu