import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

/** Material UI  */
import { TextField } from '@mui/material'
import { MenuItem } from '@mui/material'

/** Editor de Texto */
import JoditEditor from 'jodit-react'

function Info() {

    const { id } = useParams()
    const editor = useRef(null);

    //#region Informações Básicas

    const [InformaçõesBásicas, setInformaçõesBásicas] = useState([])
    const [criaçãoobra, setCriaçãoObra] = useState()
    const [TituloReduzido, setTituloReduzido] = useState('')
    const [TituloCompleto, setTituloCompleto] = useState('')
    const [Status, setStatus] = useState('')
    const [PrincipaisServiços, setPrincipaisServiços] = useState('')
    const [InformaçõesAdicionais, setInformaçõesAdicionais] = useState('')

    /** Buscar Informações Básicas */
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setInformaçõesBásicas(resultado.data().InformaçõesBásicas)
            setCriaçãoObra(resultado.data().criacao)
            setTituloReduzido(resultado.data().InformaçõesBásicas.TituloReduzido)
            setTituloCompleto(resultado.data().InformaçõesBásicas.TituloCompleto)
            setInformaçõesAdicionais(resultado.data().InformaçõesBásicas.InformaçõesAdicionais)
            setPrincipaisServiços(resultado.data().InformaçõesBásicas.PrincipaisServiços)
            setStatus(resultado.data().InformaçõesBásicas.Status)
        })
    }, [])

    /** Editar Informações Básicas */
    function EditarInformaçõesBásicas() {
        firebase.firestore().collection('Obras').doc(id).update({
            InformaçõesBásicas: {
                TituloReduzido: TituloReduzido,
                TituloCompleto: TituloCompleto,
                Status: Status,
                PrincipaisServiços: PrincipaisServiços,
                InformaçõesAdicionais: InformaçõesAdicionais
            }
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setInformaçõesBásicas(resultado.data().InformaçõesBásicas)
                setTituloReduzido(resultado.data().InformaçõesBásicas.TituloReduzido)
                setTituloCompleto(resultado.data().InformaçõesBásicas.TituloCompleto)
                setStatus(resultado.data().InformaçõesBásicas.Status)
                setPrincipaisServiços(resultado.data().InformaçõesBásicas.PrincipaisServiços)
                setInformaçõesAdicionais(resultado.data().InformaçõesBásicas.InformaçõesAdicionais)
            })
        })
    }

    //#endregion
    return (
        <div className="uk-card uk-card-default col-md-12">
            <div className="uk-card-header">
                <div className="uk-grid-small uk-flex-middle" uk-grid>
                    <div className="uk-width-expand">
                        <h3 class="uk-card-title uk-margin-remove-bottom">Informações Básicas</h3>
                    </div>
                </div>
            </div>
            <div class="uk-card-body">
                <div className="col-md-12">
                    <p>
                        <span className="font-weight-bold">Título Reduzido: </span>
                        <br></br>
                        {InformaçõesBásicas.TituloReduzido}
                    </p>
                </div>
                <div className="col-md-12">
                    <p>
                        <span className="font-weight-bold">Data de criação: </span>
                        <br></br>
                        {moment(criaçãoobra).format('L')}
                    </p>
                </div>
                <div className="col-md-12">
                    <p>
                        <span className="font-weight-bold">Título Completo: </span>
                        <br></br>
                        {InformaçõesBásicas.TituloCompleto}
                    </p>
                </div>
                <div className="col-md-12">
                    <p>
                        <span className="font-weight-bold">Status:</span>
                        <br></br>
                        {InformaçõesBásicas.Status}
                    </p>
                </div>
                <div className="col-md-12">
                    <p>
                        <span className="font-weight-bold">Principais Serviços:</span>
                        <p dangerouslySetInnerHTML={{ __html: InformaçõesBásicas.PrincipaisServiços }} />
                    </p>
                </div>
                <div className="col-md-12">
                    <p>
                        <span className="font-weight-bold">Informações Adicionais:</span>
                        <p dangerouslySetInnerHTML={{ __html: InformaçõesBásicas.InformaçõesAdicionais }} />
                    </p>
                </div>
            </div>
            <div className="uk-card-footer">
                <button href="#" className="btn btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#EditarInformaçõesBásicas">Editar</button>

                {/** Caixa de Diálogo*/}
                <div className="modal fade" id="EditarInformaçõesBásicas" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Editar Informações Básicas</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body row">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setTituloReduzido(e.target.value)} className="form-control" label="Título Reduzido" type="text" value={TituloReduzido && TituloReduzido} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setTituloCompleto(e.target.value)} className="form-control" label="Título Completo" type="text" value={TituloCompleto && TituloCompleto} />
                                    </div>
                                    <div className="col-md-12 mb-2">                    
                                        <div className="input-group mb-3">                                            
                                            <select className="form-select" onChange={(e) => setStatus(e.target.value)} id="inputGroupSelect01" value={Status && Status}>
                                                <option disabled selected>-- Selecione um tipo --</option>
                                                <option value={'Em Estudo / A Iniciar'}>Em Estudo / A Iniciar</option>
                                                <option value={'Em Andamento'}>Em Andamento</option>
                                                <option value={'Paralisado / Suspenso'}>Paralisado / Suspenso</option>
                                                <option value={'Concluído'}>Concluído</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <label>Principais Serviços</label>
                                        <JoditEditor
                                            className=''
                                            ref={editor}
                                            value={PrincipaisServiços}
                                            tabIndex={1}
                                            onChange={newContent => setPrincipaisServiços(newContent)}
                                        />
                                    </div>

                                    <div className="col-md-12 mb-5">
                                        <label>Informações Adicionais</label>
                                        <JoditEditor
                                            className=''
                                            ref={editor}
                                            value={InformaçõesAdicionais}
                                            tabIndex={1}
                                            onChange={newContent => setInformaçõesAdicionais(newContent)}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                <button onClick={EditarInformaçõesBásicas} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Atualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default Info