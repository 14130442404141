import React from 'react'
import { useSelector } from 'react-redux'

/** Páginas */
import Footer from '../../../components/footer'
import MenuHome from './../../components/menu/home'
import MenuLogin from './../../components/menu/login'
import Configurações from './configurações'

function MeuPerfil() {
    return (
        <>
            {
                useSelector(state => state.usuarioLogado) > 0 ?
                    <>
                        <MenuLogin />
                        <Configurações />
                        <Footer />
                    </>
                    :
                    <>
                        <MenuHome />
                        <h1>É necessário Fazer Login !</h1>
                        <Footer />
                    </>
            }
        </>
    )
}

export default MeuPerfil