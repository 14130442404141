import React, { useState, useEffect } from 'react'
import firebase from '../../../config/firebase'

/** Material UI */
import { TextField } from '@mui/material'

/** Moment */
import moment from 'moment'
import 'moment/locale/pt-br'

function Obras({ id, Matricula, Fiscal, Situação, DataEficácia, DataPublicação, Obra, IdFiscal, IdObra, Editar, InfoFiscal }) {


    const [scroll, setScroll] = useState('paper');
    const descriptionElementRef = React.useRef(null);

    /** Função Inserir Quadro Fiscalização */
    let listaFiscais = []
    const [Fiscais, setFiscais] = useState([])
    const [idFiscal, setIdFiscal] = useState(IdFiscal)
    const [idObra, setIdObra] = useState(IdObra)
    const [FiscalMatricula, setFiscalMatricula] = useState(Matricula)
    const [FiscalNome, setFiscalNome] = useState(Fiscal)
    const [FiscalSituação, setFiscalSituação] = useState(Situação)
    const [FiscalDataEficácia, setFiscalDataEficácia] = useState(DataEficácia)
    const [FiscalDataPublicação, setFiscalDataPublicação] = useState(DataPublicação)

    function AtualizarFiscal() {
        firebase.firestore().collection('Obras').doc(idObra).collection('QuadroFiscalização').doc(idFiscal).update({
            Matricula: FiscalMatricula,
            Fiscal: FiscalNome,
            Situação: FiscalSituação,
            DataEficácia: FiscalDataEficácia,
            DataPublicação: FiscalDataPublicação
        }).then(() => {
            InfoFiscal()
        }, [])
    }

    //#region Excluir Fiscal
    function ExcluirFiscal() {
        // Exibe uma mensagem de confirmação e só continua se o usuário clicar em "OK"
        const confirmacao = window.confirm('Tem certeza que deseja apagar este Fiscal ?');

        if (confirmacao) {
            firebase.firestore().collection('Obras').doc(idObra).collection('QuadroFiscalização').doc(idFiscal).delete()
                .then(() => {
                    // Lógica após a exclusão
                    InfoFiscal();
                    alert('Fiscal excluído com sucesso!');
                })
                .catch(erro => {
                    console.error('Erro ao apagar Fiscal:', erro);
                    alert('Erro ao apagar a Fiscal. Verifique o console para mais detalhes.');
                });
        }
    }
    //#endregion

    return (
        <>
            <div className="row col-md-6">
                {Editar == 'Não' &&
                    <>
                        {id}
                        {Matricula}
                        {Fiscal}
                        {Situação}
                        {DataEficácia}
                        {DataPublicação}
                    </>
                }
                {Editar == 'Sim' &&
                    <div className="uk-inline">

                        {/** Botão Editar */}
                        <button className='btn btn-sm btn-outline-warning' type="button" data-bs-toggle="modal" data-bs-target={`#A${idFiscal}-modal-1`}>Editar</button>
                        <div className="modal fade" id={`A${idFiscal}-modal-1`} tabIndex="-1" aria-labelledby={`A${idFiscal}-exampleModalLabel-1`} aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações da Medição</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div className='row col-md-12 mb-5'>
                                            <div className="col-md-6">
                                                <TextField
                                                    disabled
                                                    onChange={(e) => setIdObra(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="ID Obra"
                                                    value={idObra && idObra} />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    disabled
                                                    onChange={(e) => setIdFiscal(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="ID Fiscal"
                                                    value={idFiscal && idFiscal} />
                                            </div>
                                        </div>
                                        <div className="row col-md-12 mb-5">
                                            <div className="col-md-3">
                                                <TextField onChange={(e) => setFiscalMatricula(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="Matrícula"
                                                    value={FiscalMatricula && FiscalMatricula} />
                                            </div>
                                            <div className="col-md-9">
                                                <TextField
                                                    onChange={(e) => setFiscalNome(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="Fiscal"
                                                    value={FiscalNome && FiscalNome} />
                                            </div>
                                        </div>
                                        <div className="row col-md-12 mb-5">
                                            <div className="col-md-5">
                                                <TextField
                                                    onChange={(e) => setFiscalSituação(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="Situação"
                                                    value={FiscalSituação && FiscalSituação} />
                                            </div>
                                        </div>
                                        <div className="row col-md-12 mb-5">
                                            <div className="col-md-6">
                                                <TextField
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setFiscalDataEficácia(e.target.value)}
                                                    type="date"
                                                    className="form-control"
                                                    label="Data Eficácia"
                                                    value={FiscalDataEficácia && FiscalDataEficácia} />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setFiscalDataPublicação(e.target.value)}
                                                    type="date"
                                                    className="form-control"
                                                    label="Data Publicação"
                                                    value={FiscalDataPublicação && FiscalDataPublicação} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                        <button onClick={AtualizarFiscal} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Salvar</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/** Botão Excluir */}
                        <button className='btn btn-sm btn-outline-danger ml-2' type="button" data-bs-toggle="modal" data-bs-target={`#A${idFiscal}-modal-2`}>Excluir</button>
                        <div className="modal fade" id={`A${idFiscal}-modal-2`} tabIndex="-1" aria-labelledby={`A${idFiscal}-exampleModalLabel-2`} aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações da Medição</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div className='row col-md-12 mb-5'>
                                            <div className="col-md-6">
                                                <TextField
                                                    disabled
                                                    onChange={(e) => setIdObra(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="ID Obra"
                                                    value={idObra && idObra} />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    disabled
                                                    onChange={(e) => setIdFiscal(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="ID Fiscal"
                                                    value={idFiscal && idFiscal} />
                                            </div>
                                        </div>
                                        <div className="row col-md-12 mb-5">
                                            <div className="col-md-3">
                                                <TextField
                                                    disabled
                                                    onChange={(e) => setFiscalMatricula(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="Matrícula"
                                                    value={FiscalMatricula && FiscalMatricula} />
                                            </div>
                                            <div className="col-md-9">
                                                <TextField
                                                    disabled
                                                    onChange={(e) => setFiscalNome(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="Fiscal"
                                                    value={FiscalNome && FiscalNome} />
                                            </div>
                                        </div>
                                        <div className="row col-md-12 mb-5">
                                            <div className="col-md-5">
                                                <TextField
                                                    disabled
                                                    onChange={(e) => setFiscalSituação(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="Situação"
                                                    value={FiscalSituação && FiscalSituação} />
                                            </div>
                                        </div>
                                        <div className="row col-md-12 mb-5">
                                            <div className="col-md-6">
                                                <TextField
                                                    disabled
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setFiscalDataEficácia(e.target.value)}
                                                    type="date"
                                                    className="form-control"
                                                    label="Data Eficácia"
                                                    value={FiscalDataEficácia && FiscalDataEficácia} />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    disabled
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setFiscalDataPublicação(e.target.value)}
                                                    type="date"
                                                    className="form-control"
                                                    label="Data Publicação"
                                                    value={FiscalDataPublicação && FiscalDataPublicação} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                        <button onClick={ExcluirFiscal} type="button" className="btn btn-sm btn-outline-danger" data-bs-dismiss="modal">Excluir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>


    )
}

export default Obras