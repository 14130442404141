import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

/** Material UI  */
import { TextField } from '@mui/material'

function Contratado() {

    const { id } = useParams()

    const [Contratado, setContratado] = useState([])
    const [NomeContratado, setNomeContratado] = useState('')
    const [CNPJContratado, setCNPJContratado] = useState('')
    const [ContratadoResponsável, setContratadoResponsável] = useState('')

    /** Buscar Informações Contratado */
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setContratado(resultado.data().Contratado)
            setNomeContratado(resultado.data().Contratado.NomeContratado)
            setCNPJContratado(resultado.data().Contratado.CNPJContratado)
            setContratadoResponsável(resultado.data().Contratado.ContratadoResponsável)
        })
    }, [])

    /** Editar Informações Contratado */
    function EditarContratado() {
        firebase.firestore().collection('Obras').doc(id).update({
            Contratado: {
                CNPJContratado: CNPJContratado,
                NomeContratado: NomeContratado,
                ContratadoResponsável: ContratadoResponsável
            }
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setContratado(resultado.data().Contratado)
                setNomeContratado(resultado.data().Contratado.NomeContratado)
                setCNPJContratado(resultado.data().Contratado.CNPJContratado)
                setContratadoResponsável(resultado.data().Contratado.ContratadoResponsável)
            })
        })
    }

    return (
        <div>
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 className="uk-card-title uk-margin-remove-bottom">Contratado</h3>
                        </div>
                    </div>
                </div>
                <div className="uk-card-body">
                    <div className="row">
                        {/** Contratado */}
                        <div className="row col-md-12">
                            <p>
                                <span className="font-weight-bold">Contratado:</span>
                                <br></br>
                                {Contratado.NomeContratado}
                            </p>
                        </div>
                        {/** CNPJ Contratado */}
                        <div className="row col-md-12">
                            <p>
                                <span className="font-weight-bold">CNPJ Contratado:</span>
                                <br></br>
                                {Contratado.CNPJContratado}
                            </p>
                        </div>
                        {/** Responsável */}
                        <div className="row col-md-12">
                            <p>
                                <span className="font-weight-bold">Responsável:</span>
                                <br></br>
                                {Contratado.ContratadoResponsável}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="uk-card-footer">                    
                    <button className="btn btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#EditarContratado">Editar</button>
                </div>
                
                {/** Modal */}
                <div class="modal fade" id="EditarContratado" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Editar Informações Contratado</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body row">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setNomeContratado(e.target.value)} className="form-control" label="Contratado" type="text" value={NomeContratado && NomeContratado} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setCNPJContratado(e.target.value)} className="form-control" label="CNPJ Contratado" type="text" value={CNPJContratado && CNPJContratado} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setContratadoResponsável(e.target.value)} className="form-control" label="Responsável" type="text" value={ContratadoResponsável && ContratadoResponsável} />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                <button onClick={EditarContratado} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Atualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default Contratado