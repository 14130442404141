import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase.js'

/** Material UI  */
import { TextField } from '@mui/material'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

import FiscalCard from './fiscalcard.js'

function Fiscalização() {

    const { id } = useParams()
    const [checked, setChecked] = React.useState(true);

    //#region Fiscalização   

    const [viewFisColMat, SetFisColMat] = useState('True')
    function viewModeFisColMat() {
        viewFisColMat == 'True' && SetFisColMat('False')
        viewFisColMat == 'False' && SetFisColMat('True')
    }

    const [viewFisColNome, SetFisColNome] = useState('True')
    function viewModeFisColNome() {
        viewFisColNome == 'True' && SetFisColNome('False')
        viewFisColNome == 'False' && SetFisColNome('True')
    }

    const [viewFisColSit, SetFisColSit] = useState('True')
    function viewModeFisColSit() {
        viewFisColSit == 'True' && SetFisColSit('False')
        viewFisColSit == 'False' && SetFisColSit('True')
    }

    const [viewFisColEfi, SetFisColEfi] = useState('True')
    function viewModeFisColEfi() {
        viewFisColEfi == 'True' && SetFisColEfi('False')
        viewFisColEfi == 'False' && SetFisColEfi('True')
    }

    const [viewFisColPub, SetFisColPub] = useState('True')
    function viewModeFisColPub() {
        viewFisColPub == 'True' && SetFisColPub('False')
        viewFisColPub == 'False' && SetFisColPub('True')
    }

    const [viewFisColEdi, SetFisColEdi] = useState('True')
    function viewModeFisColEdi() {
        viewFisColEdi == 'True' && SetFisColEdi('False')
        viewFisColEdi == 'False' && SetFisColEdi('True')
    }

    const InfoFiscal = () => {
        firebase.firestore().collection('Obras').doc(id).collection('QuadroFiscalização').orderBy('Fiscal').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaFiscais.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setFiscais(listaFiscais)
        })
    }

    let listaFiscais = []
    const [Fiscais, setFiscais] = useState([])
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).collection('QuadroFiscalização').orderBy('Fiscal').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaFiscais.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setFiscais(listaFiscais)
        })
    }, [])

    //#region Adicionar Fiscalização
    const [FiscalId, setFiscalId] = useState('')
    const [FiscalMatricula, setFiscalMatricula] = useState('')
    const [FiscalNome, setFiscalNome] = useState('')
    const [FiscalSituação, setFiscalSituação] = useState('')
    const [FiscalDataEficácia, setFiscalDataEficácia] = useState('')
    const [FiscalDataPublicação, setFiscalDataPublicação] = useState('')
    function AdicionarFiscalização() {
        firebase.firestore().collection('Obras').doc(id).collection('QuadroFiscalização').add({
            Matricula: FiscalMatricula,
            Fiscal: FiscalNome,
            Situação: FiscalSituação,
            DataEficácia: FiscalDataEficácia,
            DataPublicação: FiscalDataPublicação
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).collection('QuadroFiscalização').orderBy('Fiscal').get().then(async (resultado) => {
                await resultado.docs.forEach(doc => {
                    listaFiscais.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setFiscais(listaFiscais)
                setFiscalMatricula('')
                setFiscalNome('')
                setFiscalSituação('')
                setFiscalDataEficácia('')
                setFiscalDataPublicação('')
            })
        }).catch(erro => {

        })
    }
    //#endregion

    //#region Carregar Fiscalização
    const [Fiscalização, setFiscalização] = useState([])
    const [NomeFiscal1, setNomeFiscal1] = useState('')
    const [MatrículaFiscal1, setMatrículaFiscal1] = useState('')
    const [NomeFiscal2, setNomeFiscal2] = useState('')
    const [MatrículaFiscal2, setMatrículaFiscal2] = useState('')
    const [NomeFiscal3, setNomeFiscal3] = useState('')
    const [MatrículaFiscal3, setMatrículaFiscal3] = useState('')

    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setFiscalização(resultado.data().Fiscalização)
            setNomeFiscal1(resultado.data().Fiscalização.NomeFiscal1)
            setMatrículaFiscal1(resultado.data().Fiscalização.MatrículaFiscal1)
            setNomeFiscal2(resultado.data().Fiscalização.NomeFiscal2)
            setMatrículaFiscal2(resultado.data().Fiscalização.MatrículaFiscal2)
            setNomeFiscal3(resultado.data().Fiscalização.NomeFiscal3)
            setMatrículaFiscal3(resultado.data().Fiscalização.MatrículaFiscal3)
        })
    }, [])
    //#endregion

    //#region Editar Fiscalização    
    function EditarFiscalização() {
        firebase.firestore().collection('Obras').doc(id).update({
            Fiscalização: {
                NomeFiscal1: NomeFiscal1 ? NomeFiscal1 : '',
                MatrículaFiscal1: MatrículaFiscal1 ? MatrículaFiscal1 : '',
                NomeFiscal2: NomeFiscal2 ? NomeFiscal2 : '',
                MatrículaFiscal2: MatrículaFiscal2 ? MatrículaFiscal2 : '',
                NomeFiscal3: NomeFiscal3 ? NomeFiscal3 : '',
                MatrículaFiscal3: MatrículaFiscal3 ? MatrículaFiscal3 : ''
            }
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setFiscalização(resultado.data().Fiscalização)
                setNomeFiscal1(resultado.data().Fiscalização.NomeFiscal1)
                setMatrículaFiscal1(resultado.data().Fiscalização.MatrículaFiscal1)
                setNomeFiscal2(resultado.data().Fiscalização.NomeFiscal2)
                setMatrículaFiscal2(resultado.data().Fiscalização.MatrículaFiscal2)
                setNomeFiscal3(resultado.data().Fiscalização.NomeFiscal3)
                setMatrículaFiscal3(resultado.data().Fiscalização.MatrículaFiscal3)
            })
        })
    }
    //#endregion


    return (
        <>
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Fiscalização Atuante</h3>
                        </div>
                    </div>
                </div>
                <div className="uk-card-body">
                    <table className="table table-hover text-nowrap">
                        <thead className='font-weight-bold'>
                            <tr>
                                <td>
                                    Número
                                </td>
                                <td>
                                    Matrícula
                                </td>
                                <td>
                                    Nome
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1
                                </td>
                                <td>
                                    {Fiscalização.MatrículaFiscal1}
                                </td>
                                <td>
                                    {Fiscalização.NomeFiscal1}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2
                                </td>
                                <td>
                                    {Fiscalização.MatrículaFiscal2}
                                </td>
                                <td>
                                    {Fiscalização.NomeFiscal2}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    3
                                </td>
                                <td>
                                    {Fiscalização.MatrículaFiscal3}
                                </td>
                                <td>
                                    {Fiscalização.NomeFiscal3}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="uk-card-footer">
                    <button type="button" className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#EditarFiscal">Editar</button>
                    <div className="modal fade" id="EditarFiscal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Fiscal</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setMatrículaFiscal1(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Nº Matrícula Fical 1"
                                                value={MatrículaFiscal1 && MatrículaFiscal1} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-12">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setNomeFiscal1(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Nome Fiscal 1"
                                                value={NomeFiscal1 ? NomeFiscal1 : ''} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 border-top my-3"></div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setMatrículaFiscal2(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Nº Matrícula Fical 2"
                                                value={MatrículaFiscal2 ? MatrículaFiscal2 : ''} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-12">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setNomeFiscal2(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Nome Fiscal 2"
                                                value={NomeFiscal2 ? NomeFiscal2 : ''} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 border-top my-3"></div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setMatrículaFiscal3(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Nº Matrícula Fical 3"
                                                value={MatrículaFiscal3 ? MatrículaFiscal3 : ''} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-12">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setNomeFiscal3(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Nome Fiscal 3"
                                                value={NomeFiscal3 ? NomeFiscal3 : ''} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                    <button type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal" onClick={EditarFiscalização}>Atualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Histórico Fiscalização */}
            <div className="uk-card uk-card-default col-md-12 mt-3">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 className="uk-card-title uk-margin-remove-bottom">Fiscalização Histórico</h3>
                        </div>
                    </div>
                </div>
                <div className="uk-card-body">
                    <table className="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                {viewFisColMat == 'True' ? <th>Matrícula</th> : ""}
                                {viewFisColNome == 'True' ? <th>Fiscal</th> : ""}
                                {viewFisColSit == 'True' ? <th>Situação</th> : ""}
                                {viewFisColEfi == 'True' ? <th>Eficácia</th> : ""}
                                {viewFisColPub == 'True' ? <th>Publicação D.O.</th> : ""}
                                {viewFisColEdi == 'False' ? <th>Editar</th> : ""}
                            </tr>
                        </thead>
                        {/** Composição Tabela */}
                        {Fiscais.map(item =>
                            <tbody>
                                <tr>{viewFisColMat == 'True' ?
                                    <td>
                                        <FiscalCard
                                            key={item.idfiscal}
                                            idfiscal={item.idfiscal}
                                            Matricula={item.Matricula}
                                            Editar='Não'
                                        />
                                    </td> : ""}
                                    {viewFisColNome == 'True' ? <td>
                                        <FiscalCard
                                            key={item.idfiscal}
                                            idfiscal={item.idfiscal}
                                            Fiscal={item.Fiscal}
                                            Editar='Não'
                                        />
                                    </td> : ""}
                                    {viewFisColSit == 'True' ? <td>
                                        <FiscalCard
                                            key={item.idfiscal}
                                            idfiscal={item.idfiscal}
                                            Situação={item.Situação}
                                            Editar='Não'
                                        />
                                    </td> : ""}
                                    {viewFisColEfi == 'True' ? <td>
                                        <FiscalCard
                                            key={item.idfiscal}
                                            idfiscal={item.idfiscal}
                                            DataEficácia={moment(item.DataEficácia).format('L')}
                                            Editar='Não'
                                        />
                                    </td> : ""}
                                    {viewFisColPub == 'True' ? <td>
                                        <FiscalCard
                                            key={item.idfiscal}
                                            idfiscal={item.idfiscal}
                                            DataPublicação={moment(item.DataPublicação).format('L')}
                                            Editar='Não'
                                        />
                                    </td> : ""}
                                    {viewFisColEdi == 'False' ?
                                        <td>
                                            <FiscalCard
                                                key={item.id}
                                                id={item.id}
                                                IdObra={id}
                                                IdFiscal={item.id}
                                                Matricula={item.Matricula}
                                                Fiscal={item.Fiscal}
                                                Situação={item.Situação}
                                                DataEficácia={item.DataEficácia}
                                                DataPublicação={item.DataPublicação}
                                                InfoFiscal={InfoFiscal}
                                                Editar='Sim'
                                            />
                                        </td>
                                        :
                                        ""
                                    }
                                </tr>
                            </tbody>)}
                    </table>
                </div>
                <div className="uk-card-footer">
                    <div className='float-left'>
                        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                            <label><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeFisColMat} /> Matrícula</label>
                            <label><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeFisColNome} /> Fiscal</label>
                            <label><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeFisColSit} /> Situação</label>
                            <label><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeFisColEfi} /> Eficácia</label>
                            <label><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeFisColPub} /> Publicação D.O.</label>
                            <label><input className="uk-checkbox" type="checkbox" onClick={viewModeFisColEdi} /> Editar</label>
                        </div>
                    </div>
                    <button type="button" className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#AdicionarFiscal">Adicionar</button>

                    {/** Modal Adicionar Fiscalização */}
                    <div className="modal fade" id="AdicionarFiscal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Adicionar Fiscal</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setFiscalMatricula(e.target.value)} type="text" className="form-control" label="Nº Matrícula" value={FiscalMatricula && FiscalMatricula} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-12">
                                            <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setFiscalNome(e.target.value)} type="text" className="form-control" label="Nome Fiscal" value={FiscalNome && FiscalNome} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-5">
                                            <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setFiscalSituação(e.target.value)} type="text" className="form-control" label="Situação" value={FiscalSituação && FiscalSituação} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setFiscalDataEficácia(e.target.value)} type="date" className="form-control" label="Data Eficácia" value={FiscalDataEficácia && FiscalDataEficácia} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField InputLabelProps={{ shrink: true }} onChange={(e) => setFiscalDataPublicação(e.target.value)} type="date" className="form-control" label="Data Publicação D.O." value={FiscalDataPublicação && FiscalDataPublicação} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                    <button type="button" className="btn btn-sm btn-outline-primary" onClick={AdicionarFiscalização}>Adicionar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}



export default Fiscalização