import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './new/view/home'
import Login from './new/view/login'
import RecuperarSenha from './new/view/RecuperarSenha'
import NovoUsuario from './new/view/NovoUsuario'
import FaleConosco from './new/view/FaleConosco'
import MeusOrcamentos from './new/view/MeusOrçamentos'
import AdicionarOrcamentos from './new/view/AdicionarOrcamentros'
import AdicionarObras from './new/view/AdicionarObras'
import Configuracoes from './new/view/Configuracoes'
import MinhasObras from './new/view/MinhasObras'
import DetalheObras from './new/view/DetalhesObras'
import ErrorPage from "./view/Erro"

import { children } from 'react'

/** errorElement: <ErrorPage />, */

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      { path: "/", element: <Home /> },
      { path: "/Login", element: <Login /> },
      { path: "/RecuperarSenha", element: <RecuperarSenha /> },
      { path: "/NovoUsuario", element: <NovoUsuario /> },
      { path: "/FaleConosco", element: <FaleConosco /> },
      { path: "/AdicionarObras", element: <AdicionarObras /> },
      { path: "/MeusOrçamentos", element: <MeusOrcamentos /> },
      { path: "/AdicionarOrcamentos", element: <AdicionarOrcamentos /> },
      { path: "/Configurações", element: <Configuracoes /> },
      { path: "/MinhasObras", element: <MinhasObras /> },
      { path: "/DetalhesObras/:id", element: <DetalheObras /> },      
    ]
  }


])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <RouterProvider router={router} />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
