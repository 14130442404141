import React from 'react'

function Footer() {
    return (
        <>
            <footer class="mt-3">
              
                <p className="border-top text-center text-muted">construcaobr.com - Desenvolvimento Eng. Rafael Maia</p>
            </footer>
        </>
    )
}

export default Footer