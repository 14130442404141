import React from 'react'
import { useSelector } from 'react-redux'

/** Páginas */
import Footer from '../../components/footer'
import Menu from '../../../components/menu-home'
import MenuLogin from '../../components/menu/login'
import Minhasobras from '../MinhasObras/MinhasObras'

function Obras() {
    return (
        <>
            {
                useSelector(state => state.usuarioLogado) > 0 ?
                    <>
                        <MenuLogin />
                        <Minhasobras />
                        <Footer />
                    </>
                    :
                    <>
                        <Menu />
                        <h1>Home</h1>
                        <Footer />
                    </>
            }
        </>
    )
}

export default Obras