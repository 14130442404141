import React from 'react'
import { useSelector } from 'react-redux'

import MenuHome from './../../components/menu/home'
import MenuLogin from './../../components/menu/login'
import Footer from '../../../components/footer'

function FaleConosco() {
    return (
        <div>
            {
                useSelector(state => state.usuarioLogado) > 0 ?
                    <>
                        <MenuLogin />
                        <div className='row'>
                            <div className='col-md-2'></div>
                            <h1 className='text-center col-md-8'>Duvidas, críticas e Sugestões, envie emial para:
                                <br></br>
                                rafaelmaiaoliveira@gmail.com</h1>
                            <div className='col-md-2'></div>
                        </div>
                        <Footer />
                    </>
                    :
                    <>
                        <MenuHome />
                        <div className='row'>
                            <div className='col-md-2'></div>
                            <h6 className='text-center col-md-8'>Duvidas, Críticas e Sugestões, envie emial para:
                                <br></br>
                                rafaelmaiaoliveira@gmail.com</h6>
                            <div className='col-md-2'></div>
                        </div>
                        <Footer />
                    </>
            }
        </div>
    )
}

export default FaleConosco