import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'
import { arrayUnion, arrayRemove } from '@firebase/firestore'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

import { TextField } from '@mui/material'

function UsuariosEditores() {

    const { id } = useParams()
    const UsuarioEmail = useSelector(state => state.usuarioEmail)

    //#region Cadastrar Editores
    const [EditoresUsuario, setEditoresUsuario] = useState('')
    function CadastrarEditores() {
        firebase.firestore().collection('Obras').doc(id).update({
            EmailEdição: arrayUnion(EditoresUsuario)
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setEditoresObra(resultado.data().EmailEdição)
                setEditoresUsuario('')
            })
        }).catch(erro => {
            alert('Erro: Tente novamente !')
        })
    }
    //#endregion

    //#region Excluir Editores
    const [DelEditoresUsuario, setDelEditoresUsuario] = useState('')
    function ExcluirEditores() {
        firebase.firestore().collection('Obras').doc(id).update({
            EmailEdição: arrayRemove(DelEditoresUsuario)
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setEditoresObra(resultado.data().EmailEdição)
                setDelEditoresUsuario('')
            })
        }).catch(erro => {
            alert('Erro: Tente novamente !')
        })
    }
    //#endregion

    //#region Cadastrar Visualizadores
    const [ViewsUsuario, setViewsUsuario] = useState('')
    function CadastrarViews() {
        firebase.firestore().collection('Obras').doc(id).update({
            EmailView: arrayUnion(ViewsUsuario)
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setUsuariosObra(resultado.data().EmailView)
            })
        }).catch(erro => {
            alert('Erro: Tente novamente !')
        })
    }
    //#endregion

    //#region Excluir Visualizadores    
    const [DelViewsUsuario, setDelViewsUsuario] = useState('')
    function ExcluirViews() {
        firebase.firestore().collection('Obras').doc(id).update({
            EmailView: arrayRemove(DelViewsUsuario)
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setUsuariosObra(resultado.data().EmailView)
            })
        }).catch(erro => {
            alert('Erro: Tente novamente !')
        })
    }
    //#endregion

    //#region Listagem dos usuários e editores da Obra 
    const [usuariosObra, setUsuariosObra] = useState([]);
    const [editoresObra, setEditoresObra] = useState([]);
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setUsuariosObra(resultado.data().EmailView)
            setEditoresObra(resultado.data().EmailEdição)
        })
    }, [])
    const listUsuariosObra = usuariosObra.map((usuario) => <li key={usuario}>{usuario}</li>)
    const listEditoresObra = editoresObra.map((editor) => <li key={editor}>{editor}</li>)
    //#endregion

    //#region Listagem dos usuários do Programa
    const [todosUsuarios, setTodosUsuarios] = useState([]);
    useEffect(() => {
        firebase.firestore().collection('Usuários').get().then(snapshot => {
            const usuariosData = [];
            snapshot.forEach(doc => {
                const data = doc.data();
                if (data && data.InfoPessoais && data.InfoPessoais.Email) {
                    usuariosData.push(data.InfoPessoais.Email);
                }
            });
            setTodosUsuarios(usuariosData);
        });
    }, [id]);
    const listUsuáriosPrograma = todosUsuarios.map((usuariosPrograma) => <li key={usuariosPrograma}>{usuariosPrograma}</li>);
    //#endregion

    return (
        <div>
            {/** Visualizadores */}
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Usuários da Obra</h3>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <div className="row col-md-12">
                        <p className="col-md-12"><span className="font-weight-bold">Lista de Usuários: </span>{listUsuariosObra}</p>
                    </div>
                </div>
                <div className="uk-card-footer">
                    <button className="btn btn-outline-primary btn-sm float-right" data-bs-toggle="modal" data-bs-target="#Adicionarvisualizadores">Adicionar</button>
                    <button className="btn btn-outline-danger btn-sm mr-3 float-right" data-bs-toggle="modal" data-bs-target="#Excluirvisualizadores">Excluir</button>
                </div>
            </div>

            {/** Modal Adicionar Visualizadores */}
            <div className="modal fade" id="Adicionarvisualizadores" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Adicionar Visualizadores</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body row">
                            <div className='col-md-6 border-end'>
                                <h4>Lista de Usuários desta pasta</h4>
                                <br></br>
                                {listUsuariosObra}
                            </div>
                            <div className='col-md-6'>
                                <h4>Lista de Todos os Usuários do Programa</h4>
                                <ul>
                                    {Array.isArray(todosUsuarios) && todosUsuarios.map((usuario) => (
                                        <li key={usuario}>
                                            {usuario}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <TextField
                                    onChange={(e) => setViewsUsuario(e.target.value)}
                                    className="form-control"
                                    label="Adicione alguém que pode visualizar esta obra"
                                    type="text"
                                    value={ViewsUsuario && ViewsUsuario}
                                    fullWidth />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-secondary btn-sm" data-bs-dismiss="modal">Close</button>
                            <button onClick={CadastrarViews} className="btn btn-outline-primary btn-sm" data-bs-dismiss="modal">Adicionar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/** Modal Excluir Visualizadores */}
            <div className="modal fade" id="Excluirvisualizadores" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Excluir Visualizadores</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body row">
                            <div className='col-md-6 border-end'>
                                <h4>Lista de Usuários desta pasta</h4>
                                <br></br>
                                {listUsuariosObra}
                            </div>
                            <div className='col-md-6'>
                                <h4>Lista de Todos os Usuários do Programa</h4>
                                <ul>
                                    {Array.isArray(todosUsuarios) && todosUsuarios.map((usuario) => (
                                        <li key={usuario}>
                                            {usuario}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <TextField
                                    onChange={(e) => setDelViewsUsuario(e.target.value)}
                                    className="form-control"
                                    label="Adicione alguém que pode visualizar esta obra"
                                    type="text"
                                    value={DelViewsUsuario && DelViewsUsuario}
                                    fullWidth />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-secondary btn-sm" data-bs-dismiss="modal">Close</button>
                            <button onClick={ExcluirViews} className="btn btn-outline-danger btn-sm" data-bs-dismiss="modal">Excluir</button>
                        </div>
                    </div>
                </div>
            </div>

            {/** Editores */}
            <div className="uk-card uk-card-default col-md-12 mt-3">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Editores da Obra</h3>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <p className="col-md-12"><span className="font-weight-bold">Lista de Editores: </span>{listEditoresObra}</p>                    

                </div>
                <div className="uk-card-footer">
                    <button className="btn btn-outline-primary btn-sm float-right" data-bs-toggle="modal" data-bs-target="#Adicionareditores">Adicionar</button>
                    <button className="btn btn-outline-danger btn-sm mr-3 float-right" data-bs-toggle="modal" data-bs-target="#Excluireditores">Excluir</button>
                </div>
            </div>

            {/** Modal Adicionar Editores */}
            <div class="modal fade" id="Adicionareditores" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Adicionar Editores</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body row">
                            <div className='col-md-6 border-end'>
                                <h4>Lista de Editores desta Obra</h4>
                                <br></br>
                                {listEditoresObra}
                            </div>
                            <div className='col-md-6'>
                                <h4>Lista de Todos os Usuários do Programa</h4>
                                <ul>
                                    {Array.isArray(todosUsuarios) && todosUsuarios.map((usuario) => (
                                        <li key={usuario}>
                                            {usuario}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <TextField
                                    onChange={(e) => setEditoresUsuario(e.target.value)}
                                    className="form-control"
                                    label="Adicione alguém que pode visualizar esta obra"
                                    type="text"
                                    value={EditoresUsuario && EditoresUsuario}
                                    fullWidth />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-secondary btn-sm" data-bs-dismiss="modal">Close</button>
                            <button onClick={CadastrarEditores} className="btn btn-outline-primary btn-sm" data-bs-dismiss="modal">Adicionar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/** Modal Excluir Editores */}
            <div class="modal fade" id="Excluireditores" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Excluir Editores</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body row">
                            <div className='col-md-6 border-end'>
                                <h4>Lista de Editores desta Obra</h4>
                                <br></br>
                                {listEditoresObra}
                            </div>
                            <div className='col-md-6'>
                                <h4>Lista de Todos os Usuários do Programa</h4>
                                <ul>
                                    {Array.isArray(todosUsuarios) && todosUsuarios.map((usuario) => (
                                        <li key={usuario}>
                                            {usuario}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <TextField
                                    onChange={(e) => setDelEditoresUsuario(e.target.value)}
                                    className="form-control"
                                    label="Adicione alguém que pode visualizar esta obra"
                                    type="text"
                                    value={DelEditoresUsuario && DelEditoresUsuario}
                                    fullWidth />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-secondary btn-sm" data-bs-dismiss="modal">Close</button>
                            <button onClick={ExcluirEditores} className="btn btn-outline-danger btn-sm" data-bs-dismiss="modal">Excluir</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default UsuariosEditores