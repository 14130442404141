import React, { useState, useEffect } from 'react'
import firebase from '../../../config/firebase'

/** Material UI */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'


function Obras({ id, IdObra, Etapa, Início, Término, Duração, PercentualEtapa, ValorEtapa, PercentualAcumulado, ValorAcumulado, Editar, IdCronograma, InfoCronograma }) {

    const [idObra, setIdObra] = useState(IdObra)
    const [idCronograma, setIdCronograma] = useState(IdCronograma)
    const [etapa, setEtapa] = useState(Etapa)
    const [início, setInício] = useState(Início)
    const [término, setTérmino] = useState(Término)
    const [duração, setDuração] = useState(Duração)
    const [percentualEtapa, setPercentualEtapa] = useState(PercentualEtapa)
    const [valorEtapa, setValorEtapa] = useState(ValorEtapa)
    const [percentualAcumulado, setpercentualAcumulado] = useState(PercentualAcumulado)
    const [valorAcumulado, setvalorAcumulado] = useState(ValorAcumulado)

    useEffect(() => {
        setIdObra(IdObra)
        setIdCronograma(IdCronograma)
        setEtapa(Etapa)
        setInício(Início)
        setTérmino(Término)
        setDuração(Duração)
        setPercentualEtapa(PercentualEtapa)
        setValorEtapa(ValorEtapa)
        setpercentualAcumulado(PercentualAcumulado)
        setvalorAcumulado(ValorAcumulado)
    }, [IdObra, IdCronograma, Etapa, Início, Término, Duração, PercentualEtapa, ValorEtapa, PercentualAcumulado, ValorAcumulado])

    //#region Atualizar Cronograma
    function AtualizarCronograma() {
        firebase.firestore().collection('Obras').doc(idObra).collection('CronogramaFinanceiro').doc(idCronograma).update({
            Etapa: Number(etapa),
            Início: início,
            Término: término,
            Duração: Number(duração),
            PercentualEtapa: Number(percentualEtapa),
            ValorEtapa: Number(valorEtapa),
            PercentualAcumulado: Number(percentualAcumulado),
            ValorAcumulado: Number(valorAcumulado)
        }).then(() => {
            InfoCronograma()
        }, [])
    }
    //#endregion

    //#region Excluir Cronograma
    function ExcluirCronograma() {
        // Exibe uma mensagem de confirmação e só continua se o usuário clicar em "OK"
        const confirmacao = window.confirm('Tem certeza que deseja apagar esta Etapa do Cronograma ?');

        if (confirmacao) {
            firebase.firestore().collection('Obras').doc(idObra).collection('CronogramaFinanceiro').doc(idCronograma).delete()
                .then(() => {
                    // Lógica após a exclusão
                    InfoCronograma();
                    alert('Etapa do Cronograma apagado com sucesso!');
                })
                .catch(erro => {
                    console.error('Erro ao apagar suspensão:', erro);
                    alert('Erro ao apagar suspensão. Verifique o console para mais detalhes.');
                });
        }
    }
    //#endregion


    return (
        <>
            {Editar == 'Não' &&
                <div className="row col-md-6">
                    {id}
                    {Etapa}
                    {Início}
                    {Término}
                    {Duração}
                    {PercentualEtapa}
                    {ValorEtapa}
                    {PercentualAcumulado}
                    {ValorAcumulado}
                </div>
            }
            {Editar == 'Sim' &&
                <>
                    {/** Botão Editar */}
                    <button className='btn btn-sm btn-outline-warning' type="button" data-bs-toggle="modal" data-bs-target={`#A${IdCronograma}-modal-1`}>Editar</button>
                    <div className="modal fade" id={`A${IdCronograma}-modal-1`} tabIndex="-1" aria-labelledby={`A${IdCronograma}-exampleModalLabel-1`} aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações do Cronograma</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='row col-md-12 mb-5'>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setIdObra(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="ID Obra"
                                                value={idObra && idObra} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setIdCronograma(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="ID Cronograma"
                                                value={IdCronograma && IdCronograma} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-4">
                                            <TextField
                                                onChange={(e) => setEtapa(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Etapa"
                                                value={etapa && etapa} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                onChange={(e) => setInício(e.target.value)}
                                                className="form-control"
                                                label="Data de Início"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={início && início} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                onChange={(e) => setTérmino(e.target.value)}
                                                className="form-control"
                                                label="Data de Término"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={término && término} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-4">
                                            <TextField
                                                onChange={(e) => setDuração(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Duração da Etapa"
                                                value={duração && duração} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                onChange={(e) => setPercentualEtapa(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Percentual da Etapa"
                                                value={percentualEtapa && percentualEtapa} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                onChange={(e) => setValorEtapa(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Valor da Etapa"
                                                value={valorEtapa && valorEtapa} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setpercentualAcumulado(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Percentual Acumulado"
                                                value={percentualAcumulado && percentualAcumulado} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setvalorAcumulado(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Valor Acumulado"
                                                value={valorAcumulado && valorAcumulado} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                    <button onClick={AtualizarCronograma} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** Botão Excluir */}
                    <button className='btn btn-sm btn-outline-danger ml-2' type="button" data-bs-toggle="modal" data-bs-target={`#A${IdCronograma}-modal-2`}>Excluir</button>
                    <div className="modal fade" id={`A${IdCronograma}-modal-2`} tabIndex="-1" aria-labelledby={`A${IdCronograma}-exampleModalLabel-1`} aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações do Cronograma</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='row col-md-12 mb-5'>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setIdObra(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="ID Obra"
                                                value={idObra && idObra} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setIdCronograma(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="ID Cronograma"
                                                value={IdCronograma && IdCronograma} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-4">
                                            <TextField
                                                disabled
                                                onChange={(e) => setEtapa(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Etapa"
                                                value={etapa && etapa} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                disabled
                                                onChange={(e) => setInício(e.target.value)}
                                                className="form-control"
                                                label="Data de Início"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={início && início} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                disabled
                                                onChange={(e) => setTérmino(e.target.value)}
                                                className="form-control"
                                                label="Data de Término"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={término && término} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-4">
                                            <TextField
                                                disabled
                                                onChange={(e) => setDuração(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Duração da Etapa"
                                                value={duração && duração} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                disabled
                                                onChange={(e) => setPercentualEtapa(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Percentual da Etapa"
                                                value={percentualEtapa && percentualEtapa} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                disabled
                                                onChange={(e) => setValorEtapa(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Valor da Etapa"
                                                value={valorEtapa && valorEtapa} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setpercentualAcumulado(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Percentual Acumulado"
                                                value={percentualAcumulado && percentualAcumulado} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                disabled
                                                onChange={(e) => setvalorAcumulado(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Valor Acumulado"
                                                value={valorAcumulado && valorAcumulado} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                    <button onClick={ExcluirCronograma} type="button" className="btn btn-sm btn-outline-danger" data-bs-dismiss="modal">Excluir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Obras