import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

/** Material Ui */
import { TextField } from '@mui/material'

function InfoContratante() {

    const { id } = useParams()

    const [Contratante, setContratante] = useState([])
    const [NomeContratante, setNomeContratante] = useState('')
    const [CNPJContratante, setCNPJContratante] = useState('')
    const [ContratanteResponsável, setContratanteResponsável] = useState('')

    /** Buscar Informações Contratante */
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setContratante(resultado.data().Contratante)
            setNomeContratante(resultado.data().Contratante.NomeContratante)
            setCNPJContratante(resultado.data().Contratante.CNPJContratante)
            setContratanteResponsável(resultado.data().Contratante.ContratanteResponsável)
        })
    }, [])

    /** Editar Informações Contratante */
    function EditarContratante() {
        firebase.firestore().collection('Obras').doc(id).update({
            Contratante: {
                CNPJContratante: CNPJContratante,
                NomeContratante: NomeContratante,
                ContratanteResponsável: ContratanteResponsável
            }
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setContratante(resultado.data().Contratante)
                setNomeContratante(resultado.data().Contratante.NomeContratante)
                setCNPJContratante(resultado.data().Contratante.CNPJContratante)
                setContratanteResponsável(resultado.data().Contratante.ContratanteResponsável)
            })
        })
    }

    return (
        <div>
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Contratante</h3>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <div className="row">
                        {/** Contratado */}
                        <div className="row col-md-12 mb-2">
                            <p>
                                <span className="font-weight-bold">Contratante:</span>
                                <br></br>
                                {Contratante.NomeContratante}
                            </p>
                        </div>
                        {/** CNPJ Contratado */}
                        <div className="row col-md-12 mb-2">
                            <p>
                                <span className="font-weight-bold">CNPJ Contratante:</span>
                                <br></br>
                                {Contratante.CNPJContratante}
                            </p>
                        </div>
                        {/** Responsável */}
                        <div className="row col-md-12 mb-2">
                            <p>
                                <span className="font-weight-bold">Responsável:</span>
                                <br></br>
                                {Contratante.ContratanteResponsável}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="uk-card-footer">
                    <button className="btn btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#EditarContratante">Editar</button>
                </div>
                <div class="modal fade" id="EditarContratante" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Editar Informações Contratante</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body row">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setNomeContratante(e.target.value)} className="form-control" label="Contratante" type="text" value={NomeContratante && NomeContratante} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setCNPJContratante(e.target.value)} className="form-control" label="CNPJ Contratante" type="text" value={CNPJContratante && CNPJContratante} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setContratanteResponsável(e.target.value)} className="form-control" label="Responsável" type="text" value={ContratanteResponsável && ContratanteResponsável} />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                <button onClick={EditarContratante} type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Atualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default InfoContratante