import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'


import firebase from '../../config/firebase'
import 'firebase/auth'

import HomeMenu from './../../components/menu/home'
import Footer from '../../components/footer'
import Style from './../login/style.css'


import { useDispatch, useSelector } from 'react-redux'

function Login() {
    const [email, setEmail] = useState()
    const [senha, setSenha] = useState()
    const [msgTipo, setMsgTipo] = useState()

    const UsuarioEmail = useSelector(state => state.usuarioEmail)

    const dispatch = useDispatch()

    function Logar() {
        firebase.auth().signInWithEmailAndPassword(email, senha).then(resultado => {
            setMsgTipo('Sucesso')
            setTimeout(() => {
                dispatch({ type: 'LOG_IN', usuarioEmail: email })
            }, 0)
        }).catch(erro => {
            setMsgTipo('Erro')
        })
    }

    return (
        <>
            <HomeMenu />
            <div className="login-content d-flex align-item-center">
                {
                    useSelector(state => state.usuarioLogado) > 0 ? <Navigate to={'/'} /> : null
                }
                <form className="form-signin mx-auto">
                    <div className="text-center mb-4">
                        <i class="fas fa-home text-white fa-5x mb-3"></i>
                        <h1 className="h3 mb-3 font-weight-normal text-white font-weight-bold">Login</h1>
                    </div>

                    <input onChange={(e) => setEmail(e.target.value)} type="email" id="inputEmail" className="form-control my-2" placeholder="Email" />
                    <input onChange={(e) => setSenha(e.target.value)} type="password" id="inputPassword" className="form-control my-2" placeholder="Senha" />

                    <button onClick={Logar} className="btn btn-lg btn-block btn-login" type="button">Entrar</button>

                    <div className="msg-login text-white text-center my-3">
                        {msgTipo === 'Sucesso' && <span><strong>wow! </strong> Você está conectado!</span>}
                        {msgTipo === 'Erro' && <span>Verifique se a senha ou usuário estão corretos! &#128546;</span>}
                    </div>

                    <div className="opcoes-login text-center">
                        <Link to='/recuperarsenha' className="mx-2">Recuperar Senha</Link>
                        <span className="text-white">&#9733;</span>
                        <Link to='/novousuario' className="mx-2">Fazer Cadastro</Link>
                    </div>
                </form>
            </div>
            <Footer />
        </>
    )
}

export default Login