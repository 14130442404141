import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

/** Material UI  */
import { TextField } from '@mui/material'

function Localização() {

    const { id } = useParams()

    const [Localização, setLocalização] = useState([])
    const [Endereço, setEndereço] = useState('')
    const [Estado, setEstado] = useState('')
    const [Cidade, setCidade] = useState('')
    const [Bairro, setBairro] = useState('')
    const [Cep, setCep] = useState('')
    const [AP, setAP] = useState('')
    const [RA, setRA] = useState('')

    /** Buscar Informações Localização */
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setLocalização(resultado.data().Localização)
            setEndereço(resultado.data().Localização.Endereço)
            setEstado(resultado.data().Localização.Estado)
            setCidade(resultado.data().Localização.Cidade)
            setBairro(resultado.data().Localização.Bairro)
            setCep(resultado.data().Localização.Cep)
            setAP(resultado.data().Localização.AP)
            setRA(resultado.data().Localização.RA)

        })
    }, [])

    /** Editar Informações Localização */
    function EditarInformaçõesLocalização() {
        firebase.firestore().collection('Obras').doc(id).update({
            Localização: {
                Endereço: Endereço,
                Estado: Estado,
                Cidade: Cidade,
                Bairro: Bairro,
                Cep: Cep,
                AP: AP,
                RA: RA
            }
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setLocalização(resultado.data().Localização)
                setEndereço(resultado.data().Localização.Endereço)
                setEstado(resultado.data().Localização.Estado)
                setCidade(resultado.data().Localização.Cidade)
                setBairro(resultado.data().Localização.Bairro)
                setCep(resultado.data().Localização.Cep)
                setAP(resultado.data().Localização.AP)
                setRA(resultado.data().Localização.RA)
            })
        })
    }

    return (

        <>
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Localização da Obra</h3>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <div className="row col-md-12">
                        <p>
                            <span className="font-weight-bold">Endereço:</span>
                            <br></br>
                            {Localização.Endereço}
                        </p>
                    </div>
                    <div className="row col-md-4">
                        <p>
                            <span className="font-weight-bold">Estado:</span>
                            <br></br>
                            {Localização.Estado}
                        </p>
                    </div>
                    <div className="row col-md-4">
                        <p>
                            <span className="font-weight-bold">Cidade:</span>
                            <br></br>
                            {Localização.Cidade}
                        </p>
                    </div>
                    <div className="row col-md-4">
                        <p>
                            <span className="font-weight-bold">Bairro:</span>
                            <br></br>
                            {Localização.Bairro}
                        </p>
                    </div>
                    <div className="row col-md-4">
                        <p>
                            <span className="font-weight-bold">CEP:</span>
                            <br></br>
                            {Localização.Cep}
                        </p>
                    </div>
                    <div className="row col-md-4">
                        <p>
                            <span className="font-weight-bold">Área de Planejamento:</span>
                            <br></br>
                            {Localização.AP}
                        </p>
                    </div>
                    <div className="row col-md-4">
                        <p>
                            <span className="font-weight-bold">Região Administrativa:</span>
                            <br></br>
                            {Localização.RA}
                        </p>
                    </div>
                </div>
                <div className="uk-card-footer">
                    <button className="btn btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#EditarLocalização">Editar</button>
                </div>
                <div class="modal fade" id="EditarLocalização" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Editar Informações Localização</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body row">
                                <div className="row">
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setEndereço(e.target.value)} className="form-control" label="Endereço" type="text" value={Endereço && Endereço} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setEstado(e.target.value)} className="form-control" label="Estado" type="text" value={Estado && Estado} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setCidade(e.target.value)} className="form-control" label="Cidade" type="text" value={Cidade && Cidade} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setBairro(e.target.value)} className="form-control" label="Bairro" type="text" value={Bairro && Bairro} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setCep(e.target.value)} className="form-control" label="CEP" type="text" value={Cep && Cep} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setAP(e.target.value)} className="form-control" label="Área de Planejamento" type="text" value={AP && AP} />
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <TextField onChange={(e) => setRA(e.target.value)} className="form-control" label="Região Administrativa" type="text" value={RA && RA} />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                <button onClick={EditarInformaçõesLocalização} type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Atualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </>
    )
}

export default Localização