import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'
import './detalhe-obras.css'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

/** Material Ui */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'

import SuspensõesCard from './suspensõescard'

function Suspensões() {

    const { id } = useParams()
    var today = moment().startOf('day')

    //#region Buscar Informações Básicas
    const [InformaçõesBásicas, setInformaçõesBásicas] = useState([])
    const [criaçãoobra, setCriaçãoObra] = useState()
    const [TituloReduzido, setTituloReduzido] = useState('')
    const [TituloCompleto, setTituloCompleto] = useState('')
    const [Status, setStatus] = useState('')
    const [PrincipaisServiços, setPrincipaisServiços] = useState('')
    const [InformaçõesAdicionais, setInformaçõesAdicionais] = useState('')

    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setInformaçõesBásicas(resultado.data().InformaçõesBásicas)
            setCriaçãoObra(resultado.data().criacao)
            setTituloReduzido(resultado.data().InformaçõesBásicas.TituloReduzido)
            setTituloCompleto(resultado.data().InformaçõesBásicas.TituloCompleto)
            setInformaçõesAdicionais(resultado.data().InformaçõesBásicas.InformaçõesAdicionais)
            setPrincipaisServiços(resultado.data().InformaçõesBásicas.PrincipaisServiços)
            setStatus(resultado.data().InformaçõesBásicas.Status)
        })
    }, [])
    //#endregion

    //#region Formatar Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region Cronograma
    const [Cronograma, setCronograma] = useState([])
    const [DataInicio, setDataInicio] = useState('')
    const [DataTermino, setDataTermino] = useState('')
    const [Suspenso, setSuspenso] = useState('')
    const [PrazoDias, setPrazoDias] = useState('')
    const [CronogramaFísico, setCronogramaFísico] = useState('')

    /** Buscar Informações Cronograma */
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setCronograma(resultado.data().Cronograma)
            setDataInicio(resultado.data().Cronograma.DataInicio)
            setDataTermino(resultado.data().Cronograma.DataTermino)
            setPrazoDias(resultado.data().Cronograma.PrazoDias)
            setCronogramaFísico(resultado.data().Cronograma.CronogramaFísico)
            setSuspenso(resultado.data().Cronograma.Suspenso)
        })
    }, [])

    //#endregion

    //#region Suspensões

    /** Obter Valores Medição */
    let listaSuspensões = []
    const [Suspensão, setSuspensão] = useState([])
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).collection('Suspensão').orderBy('InicioSuspensão').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaSuspensões.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setSuspensão(listaSuspensões)
        })
    }, [])

    //#endregion

    //#region Medições
    let listaMedição = []
    const [Medição, setMedição] = useState([])
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).collection('Medição').orderBy('Medição').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaMedição.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setMedição(listaMedição)
        })
    }, [])
    //#endregion


    return (
        <div>
            <div className='col-md-12 row my-3'>
                <div className='col-md-4'>
                    <div className='col-md-12'>
                        <h3 className="uk-card-title text-red">Informações Gerais</h3>
                    </div>
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Status da Obra</h3>
                        <h5 className=' float-right'>{InformaçõesBásicas.Status}</h5>
                    </div>
                </div>
            </div>

            <div className='col-md-12 row my-5'>
                <div className='col-md-12'>
                    <h3 className="uk-card-title text-red">Cronograma Físico</h3>
                </div>
                <div className='col-md-4'>
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Data de Início</h3>
                        <h5 className=' float-right'>{moment(Cronograma.DataInicio).format('L')}</h5>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Previsão de Término</h3>
                        <h5 className=' float-right'>{moment(Cronograma.DataTermino).format('L')}</h5>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Prazo Consumido</h3>
                        <h5 className=' float-right'>
                            {InformaçõesBásicas.Status == 'Em Andamento' &&
                                (numberDecimalFormat((Number(moment(today).diff(Cronograma.DataInicio, 'days'))
                                    - Number(numberIntegerFormat(Suspensão.reduce((acc, p) => acc + parseFloat(p.PrazoSuspenso), 0)))) / Number(PrazoDias) * 100) + '%'
                                )}
                        </h5>
                    </div>
                </div>
            </div>

            <div className='col-md-12 row my-5'>
                <div className='col-md-12'>
                    <h3 className="uk-card-title text-red">Cronograma Financeiro</h3>
                </div>
                <div className="col-md-4 my-2">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Valor Medido</h3>
                        <h5 className=' float-right'>{numberFormat(Medição.reduce((acc, p) => acc + parseFloat(p.ValorCVariação), 0))}</h5>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Valor Previsto</h3>
                        <h5 className=' float-right'>{numberFormat(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0))}</h5>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Balanço</h3>
                        {Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorCVariação), 0)) - Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0)) > 0 &&
                            <h5 className=' float-right'>{numberFormat(Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorCVariação), 0)) - Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0)))}</h5>}
                        {Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorCVariação), 0)) - Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0)) < 0 &&
                            <h5 className=' float-right text-red'>{numberFormat(Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorCVariação), 0)) - Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0)))}</h5>}
                    </div>
                </div>
                {Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorCVariação), 0)) - Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0)) < 0 &&
                    <div className="col-md-4 my-2">
                        <div className="uk-card uk-card-default uk-card-small uk-card-body">
                            <h3 className="uk-card-title">Atraso Financeiro (%)</h3>
                            <h5 className=' float-right text-red'>{numberDecimalFormat(-(Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorCVariação), 0)) -
                                Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0)))
                                / Number(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0)) * 100)} %</h5>
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}

export default Suspensões