import React, { useState, useEffect } from 'react'
import firebase from '../../../config/firebase'
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage"

/** Material UI */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'


function FotosCard({ id, IdObra, IdPasta, Data, UrlDocumentos, DescricaoDocumento, TamanhoDocumento, Documento, Usuario, CardPastaSelecionada, ViewTamanhoImagem, ViewUsuárioImagem, ViewNomeArquivo, ViewEdição, ObterDocumentos }) {

    const storage = getStorage();

    const [idObra, setIdObra] = useState(IdObra)
    const [idPasta, setIdPasta] = useState(IdPasta)
    const [data, setData] = useState(Data)
    const [urlDocumentos, setUrlDocumentos] = useState(UrlDocumentos)
    const [descricaoDocumento, setDescricaoDocumento] = useState(DescricaoDocumento)
    const [tamanhoDocumento, setTamanhoImagem] = useState(TamanhoDocumento)
    const [usuario, setUsuario] = useState(Usuario)
    const [documento, setDocumento] = useState(Documento)

    //#region Formatação do Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region Obter Pastas
    let listafotos = []
    const [RelFotos1, setRelFotos1] = useState([])

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('Obras').doc(idObra).collection('Documentos')
            .orderBy('DescricaoDocumento')
            .where(idPasta, '==', CardPastaSelecionada)
            .onSnapshot((snapshot) => {
                // Limpa a lista antes de atualizar com os novos dados
                listafotos = [];

                snapshot.forEach((doc) => {
                    listafotos.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                // Atualiza o estado com a lista atualizada
                setRelFotos1([...listafotos]);
            });

        // O retorno da função useEffect é chamado quando o componente é desmontado ou quando as dependências mudam
        return () => {
            // Isso cancela a inscrição quando o componente é desmontado
            unsubscribe();
        };
    }, [IdPasta]);
    //#endregion

    //#region Exibir foto grande
    const [exibirFotoGrande, setExibirFotoGrande] = useState(false)
    const [urlFotoClicada, setUrlFotoClicada] = useState('')

    const exibirFotoEmTamanhoGrande = (url) => {
        setUrlFotoClicada(url);
        setExibirFotoGrande(true);
    }
    const FotoGrandeModal = ({ url }) => {
        return (
            <div className="modal-foto-grande centralizado">
                <img src={url} alt="Foto Grande" />
            </div>
        )
    }
    //#endregion

    //#region Atualizar Descrição do Documento    
    function AtualizarDescriçãoDocumento() {
        firebase.firestore().collection('Obras').doc(idObra).collection('Documentos').doc(idPasta).update({
            DescricaoDocumento: descricaoDocumento
        }).then(() => {
            ObterDocumentos()
            alert('Descrição do Documento atualizado com sucesso.')
        }).catch(error => {
            console.error('Erro ao atualizar a descrição do Documento:', error);
        });
    }
    //#endregion

    //#region Excluir Foto
    const excluirFoto = async () => {
        try {
            // Excluir a foto no Firestore
            await firebase.firestore().collection('Obras').doc(idObra).collection('Documentos').doc(idPasta).delete();

            // Excluir a foto no Storage
            const storageRef = ref(storage, urlDocumentos);
            await deleteObject(storageRef);

            alert("Arquivo excluída com sucesso");
        } catch (error) {
            console.error('Erro ao excluir arquivo:', error);
            alert('Erro ao excluir arquivo. Por favor, tente novamente.');
        }
    };
    //#endregion

    return (
        <>
            <div className='col-md-3'>
                <div className="uk-card uk-card-default uk-margin-bottom">
                    <div className="uk-card-header">
                        <p className='float-left'>{data.toDate().toLocaleDateString()}</p>
                    </div>
                    <div className="uk-card-body-docs my-3">
                        <span>{descricaoDocumento}</span>
                    </div>
                    <div className="uk-card-footer">
                        <p>
                            <a className='' href={urlDocumentos} target="_blank"><button type="button" className="btn btn-outline-success">Baixar</button></a>
                            <br></br>
                        </p>


                        {ViewEdição == 'True' ?
                            <div className="border-top">
                                <button className="btn btn-sm btn-outline-warning my-3" data-bs-toggle="modal" data-bs-target={`#A${idPasta}-modalcard-1`}>Editar</button>
                                <button className="btn btn-sm btn-outline-danger ml-2 my-3" data-bs-toggle="modal" data-bs-target={`#A${idPasta}-modalcard-2`}>Excluir</button>
                            </div>
                            : ''}
                        {ViewTamanhoImagem == 'True' ?
                            <div className='border-top'>
                                <p className='mt-3'>Tamanho da foto: {numberDecimalFormat(tamanhoDocumento / 1000000)} Mb</p>
                            </div>
                            : ''}
                        {ViewNomeArquivo == 'True' ?
                            <div className='border-top'>
                                <p className='my-3'>Nome do arquivo: {documento}</p>
                            </div>
                            : ''}
                        {ViewUsuárioImagem == 'True' ?
                            <div className='border-top'>
                                <p className='mt-3'>Usuário: {usuario}</p>
                            </div>
                            : ''}
                    </div>
                </div>

                {/** Modal para editar o texto da Foto */}
                <div className="modal fade" id={`A${idPasta}-modalcard-1`} tabIndex="-1" aria-labelledby={`A${idPasta}-exampleModalLabel-1`} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Editar descrição do Documento</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">                                
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-12">
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => setDescricaoDocumento(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            label="Descrição do Documento"
                                            value={descricaoDocumento && descricaoDocumento} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={AtualizarDescriçãoDocumento} className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/** Modal para Excluir a Arquivo */}
                <div className="modal fade" id={`A${idPasta}-modalcard-2`} tabIndex="-1" aria-labelledby={`A${idPasta}-exampleModalLabel-2`} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Excluir Arquivo</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-12">
                                        <TextField
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => setDescricaoDocumento(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            label="Descrição do Documento"
                                            value={descricaoDocumento && descricaoDocumento} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={excluirFoto} className="btn btn-sm btn-outline-danger" data-bs-dismiss="modal">Excluir</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/** Modal Exibir foto grande */}
                <div class="modal fade" id={`A${idPasta}-modal-3`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Detalhe do Arquivo</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                {exibirFotoGrande && <FotoGrandeModal url={urlFotoClicada} onClose={() => setExibirFotoGrande(false)} />}
                            </div>
                            <div class="modal-footer">
                                <button className="btn btn-outline-secondary btn-sm" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FotosCard