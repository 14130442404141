import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import firebase from '../../config/firebase';
import moment from 'moment';
import 'moment/locale/pt-br';

function Home() {
    const { id } = useParams();
    const [dadosHome, setDadosHome] = useState([]);
    const [limitePostagens, setLimitePostagens] = useState(3);
    const observadorRef = useRef(); // Ref para o observador

    function ImageWithFallback({ src, alt }) {
        const [errored, setErrored] = useState(false);

        const handleError = () => {
            setErrored(true);
        };

        if (errored) {
            // Exibe mensagem ou imagem alternativa quando a imagem original não puder ser carregada
            return <strong className='text-red'>Foto Apagada !</strong>;
        }

        return <img src={src} alt={alt} onError={handleError} />;
    }

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('Obras').doc(id).collection('Home')
            .orderBy('Data', 'desc')
            .onSnapshot((snapshot) => {
                const dadosAtualizados = snapshot.docs.map(doc => {
                    const data = doc.data();
                    return { id: doc.id, ...data, Data: data.Data.toDate() };
                });
                setDadosHome(dadosAtualizados);
            });

        return () => unsubscribe();
    }, [id]);

    // Função para observar o elemento final
    useEffect(() => {
        const observador = new IntersectionObserver((entradas) => {
            if (entradas[0].isIntersecting) {
                setLimitePostagens(limiteAnterior => limiteAnterior + 3);
            }
        }, { threshold: 1.0 });

        if (observadorRef.current) {
            observador.observe(observadorRef.current);
        }

        return () => {
            if (observadorRef.current) {
                observador.unobserve(observadorRef.current);
            }
        };
    }, []);



    return (
        <div className="uk-card uk-card-default col-md-12">
            <div className="uk-card-header">
                <div className="uk-grid-small uk-flex-middle" uk-grid>
                    <div className="uk-width-expand">
                        <h3 class="uk-card-title uk-margin-remove-bottom">Home</h3>
                    </div>
                </div>
            </div>
            <div class="uk-card-body scrollable-div">
                {dadosHome.slice(0, limitePostagens).map((item, index) => (
                    item.Tipo === 'Fotos' && (
                        <div key={index}>
                            <div className="uk-card uk-card-default uk-margin-bottom">
                                {/* Exemplo: mostrar descrição */}
                                <div className="uk-card-header">
                                    <strong>Foto Inserida em {moment(item.Data).format('L')} às {moment(item.Data).format('LT')}.</strong>
                                    <p>Inserido por <strong>'{item.usuário}'</strong> na pasta <strong>'{item.Pasta}'</strong>.</p>
                                </div>
                                {/* ...exibição de informações... */}
                                <div className="uk-card-body-estilo my-3">
                                    <ImageWithFallback src={item.urlImagens} alt="Foto" />
                                </div>
                                <div className="uk-card-footer text-center">
                                    {item.DescricaoFoto}
                                </div>
                            </div>
                        </div>)
                ))}
                <div ref={observadorRef}></div>
            </div>
        </div >
    )
}

export default Home




