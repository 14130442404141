import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './obras-card.css'
import firebase from '../../config/firebase'
import { useSelector } from 'react-redux'
import { arrayUnion, arrayRemove } from '@firebase/firestore'

/** Material Ui */
import { Button } from '@mui/material'

/** Moment */
import moment from 'moment'
import 'moment/locale/pt-br'

function Obras({ id, titulo, titulocompleto, inicio, termino, status, investimento, usuariosviews, empenho, viewPainel, col }) {

    const [RelPastas, setRelPastas] = useState([])
    const [LabelPasta, setLabelPasta] = useState('')
    const UsuarioEmail = useSelector(state => state.usuarioEmail)

    const [mensagemSucesso, setMensagemSucesso] = useState('');

    useEffect(() => {
        // Carregar pastas do Firestore (ou da sua fonte de dados)
        firebase.firestore().collection('Usuários').doc(UsuarioEmail).collection('Pastas')
            .orderBy('LabelPasta')  // Ordenar por nome da pasta
            .get()
            .then((resultado) => {
                const pastas = resultado.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setRelPastas(pastas);
            })
            .catch(erro => {
                console.error('Erro ao carregar pastas:', erro);
            });
    }, []); // Certifique-se de ajustar dependências conforme necessário

    var today = moment().startOf('day')

    //#region Formatar Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion


    // Adicionar subcoleção Obras
    function adicionarSubcollectionObras(pastaId) {
        firebase.firestore().collection('Usuários').doc(UsuarioEmail).collection('Pastas').doc(pastaId).update({
            Pastas: arrayUnion(id)
        })
            .then(() => {
                setMensagemSucesso(`Subcollection "Obras" adicionada com sucesso para a pasta: ${pastaId}`);
                alert('Pasta adicionada com sucesso !');
            })
            .catch(erro => {
                console.error('Erro ao adicionar subcollection "Obras" para a pasta:', pastaId, erro);
            });
    }

    // Remover subcoleção Obras
    function removerSubcollectionObras(pastaId) {
        firebase.firestore().collection('Usuários').doc(UsuarioEmail).collection('Pastas').doc(pastaId).update({
            Pastas: arrayRemove(id)
        })
            .then(() => {
                setMensagemSucesso(`Subcollection "Obras" removida com sucesso da pasta: ${pastaId}`);
                console.log('Pasta removida com sucesso de:', pastaId);
            })
            .catch(erro => {
                console.error('Erro ao remover subcollection "Obras" da pasta:', pastaId, erro);
            });
    }

    return (
        <div className="row col-md-4 col-sm-12 mb-3" >
            <div className="col-md-12 col-sm-12">
                <div className="uk-card uk-card-default col-md-12 border rounded-top rounded-3">
                    <div className="uk-card-header">
                        <h5 className='truncate-text' >{titulo}</h5>
                    </div>
                    <div className="uk-card-body">
                        <ul uk-accordion="multiple: true">
                            <li /** className="uk-open"*/>
                                <a class="uk-accordion-title" href>Título Completo</a>
                                <div class="uk-accordion-content">
                                    <p>{titulocompleto}</p>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href>Mais Info</a>
                                <div className="uk-accordion-content">
                                    <div className="row">
                                        <p className="col-12"><span className="font-weight-bold">Período: </span>{moment(inicio).format('L')} a {moment(termino).format('L')}</p>
                                        <p className="col-12"><span className="font-weight-bold">Status: </span>{status}</p>
                                        <p className="col-12"><span className="font-weight-bold">Investimento: </span>{investimento}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul uk-accordion="multiple: true">
                            <li>
                                <a className="uk-accordion-title" href>Pastas</a>
                                <div className="uk-accordion-content" >
                                    {RelPastas.map(pasta => (
                                        <li key={pasta.id}>
                                            <div className='my-4'>
                                                {pasta.LabelPasta}
                                                <Button className='float-right ml-2' variant="outlined" size="small" color="error" onClick={() => removerSubcollectionObras(pasta.id)}>Remover</Button>
                                                <Button className='float-right' variant="outlined" size="small" onClick={() => adicionarSubcollectionObras(pasta.id)}>Adicionar</Button>
                                            </div>
                                        </li>
                                    ))}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-card-footer">
                        <Link to={"/detalhesobras/" + id} className="float-right btn btn-sm btn-outline-primary">Mais Detalhes</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Obras