import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import firebase from '../../../config/firebase'
import { getStorage, ref, getDownloadURL, uploadBytes, listAll } from "firebase/storage"
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'



/** Páginas */
import ObrasCard from '../../../components/obras-card'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'


function Projetos(props) {


    const UsuarioEmail = useSelector(state => state.usuarioEmail)
    const db = firebase.firestore()

    const [Pesquisa, setPesquisa] = useState('')
    const [PesquisaB, setPesquisaB] = useState('')

    const [msgTipo, setMsgTipo] = useState()
    const [nomeperfil, setNomePerfil] = useState()

    const storage = getStorage()

    //#region Visualizar Foto perfil
    const [urlImagens, setUrlImagens] = useState()
    getDownloadURL(ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/${'FotoPerfil'}`))
    .then(url => setUrlImagens(url))
    .catch(error => {
        // Tratar o erro aqui
        console.error("Erro ao obter URL da imagem:", error.message)
        // Definir uma URL padrão ou fazer outra coisa para lidar com a falta do objeto
    })

    const [urlImagemNova, setUrlImagemNova] = useState()
    getDownloadURL(ref(storage, `Usuários/${'user defalt.png'}`)).then(url => setUrlImagemNova(url))
    //#endregion

    //#region Imagens Storage
    const [fotoNova, setFotoNova] = useState(null)
    const imageListRef = ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/`)

    const cadastrarfoto = () => {
        if (fotoNova == null) return;
        const imageRef = ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/${'FotoPerfil'}`);
        uploadBytes(imageRef, fotoNova).then((snaphsot) => {
            getDownloadURL(snaphsot.ref).then((url) => {
                setUrlImagens(url)
            })
            alert("Image Alterada")
        })
    }
    
    useEffect(() => {
        listAll(imageListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setUrlImagens(url)
                })
            })
        })
    }, [])

    //#endregion

    //#region Formatar Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region Visualizar Perfil
    const [viewInfoPessoal, SetViewInfoPessoal] = useState('True')

    //#endregion

    //#region Informações Usuário
    const [InfoPessoais, setInfoPessoais] = useState('')
    const [nome, setNome] = useState('')
    const [formação, setFormação] = useState('')
    const [Foto, setFoto] = useState('')
    const [datacriação, setDataCriação] = useState('')
    const [aniversário, setAniversário] = useState('')
    const [sexo, setSexo] = useState('')
    const [telefone, setTelefone] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [InfoPastas, setInfoPastas] = useState('')
    const [pasta1, setPasta1] = useState('')
    const [label1, setLabel1] = useState('')
    const [pasta2, setPasta2] = useState('')
    const [label2, setLabel2] = useState('')
    const [pasta3, setPasta3] = useState('')
    const [label3, setLabel3] = useState('')
    const [pasta4, setPasta4] = useState('')
    const [label4, setLabel4] = useState('')
    const [pasta5, setPasta5] = useState('')
    const [label5, setLabel5] = useState('')
    const [pasta6, setPasta6] = useState('')
    const [label6, setLabel6] = useState('')
    const [pasta7, setPasta7] = useState('')
    const [label7, setLabel7] = useState('')
    const [pasta8, setPasta8] = useState('')
    const [label8, setLabel8] = useState('')
    const [pasta9, setPasta9] = useState('')
    const [label9, setLabel9] = useState('')
    const [pasta10, setPasta10] = useState('')
    const [label10, setLabel10] = useState('')
    const [pasta11, setPasta11] = useState('')
    const [label11, setLabel11] = useState('')
    const [pasta12, setPasta12] = useState('')
    const [label12, setLabel12] = useState('')

    useEffect(() => {
        firebase.firestore().collection('Usuários').doc(UsuarioEmail).get().then(resultado => {
            setDataCriação(resultado.data().Datacriação)
            setInfoPessoais(resultado.data().InfoPessoais)
            setAniversário(resultado.data().InfoPessoais.Aniversário)
            setFormação(resultado.data().InfoPessoais.Formação)
            setFoto(resultado.data().InfoPessoais.Foto)
            setNome(resultado.data().InfoPessoais.Nome)
            setSexo(resultado.data().InfoPessoais.Sexo)
            setTelefone(resultado.data().InfoPessoais.Telefone)
            setEmpresa(resultado.data().InfoPessoais.Empresa)
            setInfoPastas(resultado.data().InfoPastas)
            setLabel1(resultado.data().InfoPastas.Label1)
            setLabel2(resultado.data().InfoPastas.Label2)
            setLabel3(resultado.data().InfoPastas.Label3)
            setLabel4(resultado.data().InfoPastas.Label4)
            setLabel5(resultado.data().InfoPastas.Label5)
            setLabel6(resultado.data().InfoPastas.Label6)
            setLabel7(resultado.data().InfoPastas.Label7)
            setLabel8(resultado.data().InfoPastas.Label8)
            setLabel9(resultado.data().InfoPastas.Label9)
            setLabel10(resultado.data().InfoPastas.Label10)
            setLabel11(resultado.data().InfoPastas.Label11)
            setLabel12(resultado.data().InfoPastas.Label12)
            setPasta1(resultado.data().InfoPastas.Pasta1)
            setPasta2(resultado.data().InfoPastas.Pasta2)
            setPasta3(resultado.data().InfoPastas.Pasta3)
            setPasta4(resultado.data().InfoPastas.Pasta4)
            setPasta5(resultado.data().InfoPastas.Pasta5)
            setPasta6(resultado.data().InfoPastas.Pasta6)
            setPasta7(resultado.data().InfoPastas.Pasta7)
            setPasta8(resultado.data().InfoPastas.Pasta8)
            setPasta9(resultado.data().InfoPastas.Pasta9)
            setPasta10(resultado.data().InfoPastas.Pasta10)
            setPasta11(resultado.data().InfoPastas.Pasta11)
            setPasta12(resultado.data().InfoPastas.Pasta12)
        })
    }, [])
    //#endregion

    //#region Editar Informações Usuários
    function EditarInformaçõesUsuários() {
        db.collection('Usuários').doc(UsuarioEmail).update({
            InfoPessoais: {
                Nome: nome,
                Email: UsuarioEmail,
                Formação: formação,
                Empresa: empresa,
                Aniversário: aniversário,
                Sexo: sexo,
                Telefone: telefone,
            }
        }).then(() => {
            firebase.firestore().collection('Usuários').doc(UsuarioEmail).get().then(resultado => {
                setInfoPessoais(resultado.data().InfoPessoais)
                setAniversário(resultado.data().InfoPessoais.Aniversário)
                setFormação(resultado.data().InfoPessoais.Formação)
                setNome(resultado.data().InfoPessoais.Nome)
                setSexo(resultado.data().InfoPessoais.Sexo)
                setTelefone(resultado.data().InfoPessoais.Telefone)
                setEmpresa(resultado.data().InfoPessoais.Empresa)
            })
        })
    }
    //#endregion

    //#region Editar Informações Pastas
    function EditarInformaçõesPastas() {
        db.collection('Usuários').doc(UsuarioEmail).update({
            InfoPastas: {
                Pasta1: pasta1,
                Label1: label1,
                Pasta2: pasta2,
                Label2: label2,
                Pasta3: pasta3,
                Label3: label3,
                Pasta4: pasta4,
                Label4: label4,
                Pasta5: pasta5,
                Label5: label5,
                Pasta6: pasta6,
                Label6: label6,
                Pasta7: pasta7,
                Label7: label7,
                Pasta8: pasta8,
                Label8: label8,
                Pasta9: pasta9,
                Label9: label9,
                Pasta10: pasta10,
                Label10: label10,
                Pasta11: pasta11,
                Label11: label11,
                Pasta12: pasta12,
                Label12: label12
            }
        }).then(() => {
            firebase.firestore().collection('Usuários').doc(UsuarioEmail).get().then(resultado => {
                setInfoPastas(resultado.data().InfoPastas)
                setLabel1(resultado.data().InfoPastas.Label1)
                setLabel2(resultado.data().InfoPastas.Label2)
                setLabel3(resultado.data().InfoPastas.Label3)
                setLabel4(resultado.data().InfoPastas.Label4)
                setLabel5(resultado.data().InfoPastas.Label5)
                setLabel6(resultado.data().InfoPastas.Label6)
                setLabel7(resultado.data().InfoPastas.Label7)
                setLabel8(resultado.data().InfoPastas.Label8)
                setLabel9(resultado.data().InfoPastas.Label9)
                setLabel10(resultado.data().InfoPastas.Label10)
                setLabel11(resultado.data().InfoPastas.Label11)
                setLabel12(resultado.data().InfoPastas.Label12)
                setPasta1(resultado.data().InfoPastas.Pasta1)
                setPasta2(resultado.data().InfoPastas.Pasta2)
                setPasta3(resultado.data().InfoPastas.Pasta3)
                setPasta4(resultado.data().InfoPastas.Pasta4)
                setPasta5(resultado.data().InfoPastas.Pasta5)
                setPasta6(resultado.data().InfoPastas.Pasta6)
                setPasta7(resultado.data().InfoPastas.Pasta7)
                setPasta8(resultado.data().InfoPastas.Pasta8)
                setPasta9(resultado.data().InfoPastas.Pasta9)
                setPasta10(resultado.data().InfoPastas.Pasta10)
                setPasta11(resultado.data().InfoPastas.Pasta11)
                setPasta12(resultado.data().InfoPastas.Pasta12)
            })
        })
    }
    //#endregion

    const [Obras, setObras] = useState([])
    let listaobras = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaobras.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObras(listaobras)
        })
    }, [Pesquisa])



    //#region Visualizar Informações Básicas
    const [viewFiltro, SetViewFiltro] = useState('False')
    function viewModeFiltro() {
        viewFiltro == 'True' && SetViewFiltro('False')
        viewFiltro == 'False' && SetViewFiltro('True')
    }
    //#endregion

    //#region Obras Estudo
    const [ObrasEstudo, setObrasEstudo] = useState([])
    let listaobrasestudo = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).where('InformaçõesBásicas.Status', '==', 'Estudo').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaobrasestudo.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObrasEstudo(listaobrasestudo)
        })
    }, [Pesquisa])
    //#endregion

    //#region Obras A Iniciar
    const [ObrasAIniciar, setObrasAIniciar] = useState([])
    let listaobrasainiciar = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).where('InformaçõesBásicas.Status', '==', 'A Iniciar').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaobrasainiciar.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObrasAIniciar(listaobrasainiciar)
        })
    }, [Pesquisa])
    //#endregion

    //#region Obras Em Andamento
    const [ObrasEmAndamento, setObrasEmAndamento] = useState([])
    let listaobrasEmAndamento = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).where('InformaçõesBásicas.Status', '==', 'Em Andamento').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaobrasEmAndamento.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObrasEmAndamento(listaobrasEmAndamento)
        })
    }, [Pesquisa])
    //#endregion

    //#region Obras Paralisado / Suspenso
    const [ObrasParalisadoSuspenso, setObrasParalisadoSuspenso] = useState([])
    let listaobrasParalisadoSuspenso = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).where('InformaçõesBásicas.Status', '==', 'Paralisado / Suspenso').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaobrasParalisadoSuspenso.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObrasParalisadoSuspenso(listaobrasParalisadoSuspenso)
        })
    }, [Pesquisa])
    //#endregion

    //#region Obras Rescindido
    const [ObrasRescindido, setObrasRescindido] = useState([])
    let listaobrasRescindido = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).where('InformaçõesBásicas.Status', '==', 'Rescindido').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaobrasRescindido.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObrasRescindido(listaobrasRescindido)
        })
    }, [Pesquisa])
    //#endregion

    //#region Obras Concluídas
    const [ObrasConcluídas, setObrasConcluídas] = useState([])
    let listaobrasconcluídas = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).where('InformaçõesBásicas.Status', '==', 'Concluído').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaobrasconcluídas.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObrasConcluídas(listaobrasconcluídas)
        })
    }, [Pesquisa])
    //#endregion


    /** const [urlImagens, setUrlImagens] = useState()

    useEffect(() => {
        firebase.storage().ref(`Usuários/${UsuarioEmail}/FotoPerfil/${'FotoPerfil'}`).getDownloadURL().then(url => setUrlImagens(url))
    }, [urlImagens])

    const [urlImagemNova, setUrlImagemNova] = useState()
    useEffect(() => {
        firebase.storage().ref(`Usuários/${'user defalt.png'}`).getDownloadURL().then(url => setUrlImagemNova(url))
    }, [urlImagemNova]) */


    return (
        <>
            <div className="col-md-12 my-1">
                <div className="card card-widget widget-user shadow">
                    <div className="widget-user-header bg-olive">
                        <h3 classN="widget-user-username">{InfoPessoais.Nome ? InfoPessoais.Nome : 'Novo Usuário'}</h3>
                        <h5 className="widget-user-desc">{UsuarioEmail}</h5>
                    </div>
                    <div class="widget-user-image">
                        <img className="img-circle elevation-2" src={urlImagens ? urlImagens : urlImagemNova} alt="User Avatar" />
                    </div>
                    <div class="card-footer">
                        <h2 className="col-md-12 my-3 text-dark text-center font-weight-bold">Configurações</h2>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row'>
                                {/** Usuário */}
                                <div className='col-md-4 my-3 mb-5'>
                                    <h3 className="ml-3 text-dark text-center font-weight-bold">Usuário</h3>
                                    <div className='row col-md-12'>
                                        <div className='col-md-12 mt-2'>
                                            <h5 className="col-md-12 my-3 text-dark text-center font-weight-bold">Imagem Perfil</h5>
                                            <img class="uk-align-center elevation-2" src={urlImagens ? urlImagens : urlImagemNova} alt="User Avatar" />
                                        </div>
                                        <div className='col-md-12'>
                                            {<input onChange={(e) => setFotoNova(e.target.files[0])} accept="image/*" type="file" className="form-control" />}
                                        </div>
                                        <div className='col-md-12 mt-2'>
                                            <div className='float-right'>
                                                {<button onClick={cadastrarfoto} type="button" className="btn btn-block btn-outline-secondary float-right">Atuarlizar foto</button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='border-top my-3'>
                                        <h5 className="col-md-12 my-3 text-dark text-center font-weight-bold">Informações Usuário</h5>
                                        <div className="col-md-12 my-2">
                                            <p>
                                                <span className="font-weight-bold">Nome: </span>
                                                {InfoPessoais.Nome}
                                            </p>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <p>
                                                <span className="font-weight-bold">Email: </span>
                                                {InfoPessoais.Email}
                                            </p>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <p>
                                                <span className="font-weight-bold">Formação: </span>
                                                {InfoPessoais.Formação}
                                            </p>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <p>
                                                <span className="font-weight-bold">Empresa: </span>
                                                {InfoPessoais.Empresa}
                                            </p>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <p>
                                                <span className="font-weight-bold">Aniversário: </span>
                                                {moment(InfoPessoais.Aniversário).format('L')}
                                            </p>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <p>
                                                <span className="font-weight-bold">Sexo: </span>
                                                {InfoPessoais.Sexo}
                                            </p>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <p>
                                                <span className="font-weight-bold">Telefone: </span>
                                                {InfoPessoais.Telefone}
                                            </p>
                                        </div>
                                        <div className="col-md-12 my-2">
                                            <p>
                                                <span className="font-weight-bold">Cadastrado em: </span>
                                                {moment(InfoPessoais.Datacriação).format('LL')}

                                            </p>
                                        </div>
                                        <div className='float-right mr-2 mb-3'>
                                            <button type="button" className="btn btn-block btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#EditarInformaçõesUsuário">Editar</button>
                                        </div>

                                        <div className="modal fade" id="EditarInformaçõesUsuário" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Editar Informações Usuário</h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body row">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <TextField
                                                                    onChange={(e) => setNome(e.target.value)}
                                                                    type="text"
                                                                    className="form-control my-4"
                                                                    id="nome"
                                                                    label="Nome"
                                                                    value={nome && nome} />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <TextField
                                                                    disabled
                                                                    type="text"
                                                                    className="form-control my-4"
                                                                    id="Email"
                                                                    label="Email"
                                                                    value={UsuarioEmail && UsuarioEmail} />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <TextField
                                                                    onChange={(e) => setFormação(e.target.value)}
                                                                    type="text"
                                                                    className="form-control my-4"
                                                                    id="Formação"
                                                                    label="Formação"
                                                                    value={formação && formação} />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <TextField
                                                                    onChange={(e) => setEmpresa(e.target.value)}
                                                                    type="text"
                                                                    className="form-control my-4"
                                                                    id="Empresa"
                                                                    label="Empresa"
                                                                    value={empresa && empresa} />
                                                            </div>
                                                            <div className="col-md-12 my-4">
                                                                <span className="">Sexo: </span>
                                                                <select className="form-select" onChange={(e) => setSexo(e.target.value)} aria-label="Default select example" value={sexo && sexo} >
                                                                    <option selected>-- Selecione um Tipo --</option>
                                                                    <option value={'Masculino'}>Masculino</option>
                                                                    <option value={'Feminino'}>Feminino</option>
                                                                    <option value={'Outros'}>Outros</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <TextField
                                                                    onChange={(e) => setAniversário(e.target.value)}
                                                                    type="Date"
                                                                    className="form-control my-4"
                                                                    id="Aniversário"
                                                                    label="Aniversário"
                                                                    value={aniversário && aniversário}
                                                                    InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }} />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <TextField
                                                                    onChange={(e) => setTelefone(e.target.value)}
                                                                    type="text"
                                                                    className="form-control my-4"
                                                                    id="Telefone"
                                                                    label="Telefone"
                                                                    value={telefone && telefone} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button onClick={EditarInformaçõesUsuários} type="button" className="btn btn-primary" data-bs-dismiss="modal">Atualizar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/** Pastas */}
                                <div className='col-md-4 my-3 mb-5 border-left border-right'>
                                    <h3 className="ml-3 text-dark text-center font-weight-bold">𝑷𝒂𝒔𝒕𝒂𝒔 "𝑴𝒊𝒏𝒉𝒂𝒔 𝑶𝒃𝒓𝒂𝒔"</h3>
                                    <div className='row'>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 1: </span>
                                            <br></br>
                                            {InfoPastas.Label1}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 1: </span>
                                            <br></br>
                                            {InfoPastas.Pasta1}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 2: </span>
                                            <br></br>
                                            {InfoPastas.Label2}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 2: </span>
                                            <br></br>
                                            {InfoPastas.Pasta2}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 3: </span>
                                            <br></br>
                                            {InfoPastas.Label3}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 3: </span>
                                            <br></br>
                                            {InfoPastas.Pasta3}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 4: </span>
                                            <br></br>
                                            {InfoPastas.Label4}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 4: </span>
                                            <br></br>
                                            {InfoPastas.Pasta4}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 5: </span>
                                            <br></br>
                                            {InfoPastas.Label5}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 5: </span>
                                            <br></br>
                                            {InfoPastas.Pasta5}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 6: </span>
                                            <br></br>
                                            {InfoPastas.Label6}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 6: </span>
                                            <br></br>
                                            {InfoPastas.Pasta6}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 7: </span>
                                            <br></br>
                                            {InfoPastas.Label7}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 7: </span>
                                            <br></br>
                                            {InfoPastas.Pasta7}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 8: </span>
                                            <br></br>
                                            {InfoPastas.Label8}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 8: </span>
                                            <br></br>
                                            {InfoPastas.Pasta8}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 9: </span>
                                            <br></br>
                                            {InfoPastas.Label9}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 9: </span>
                                            <br></br>
                                            {InfoPastas.Pasta9}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 10: </span>
                                            <br></br>
                                            {InfoPastas.Label10}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 10: </span>
                                            <br></br>
                                            {InfoPastas.Pasta10}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 11: </span>
                                            <br></br>
                                            {InfoPastas.Label11}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 11: </span>
                                            <br></br>
                                            {InfoPastas.Pasta11}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Nome Pasta 12: </span>
                                            <br></br>
                                            {InfoPastas.Label12}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <span className="font-weight-bold">Pasta 12: </span>
                                            <br></br>
                                            {InfoPastas.Pasta12}
                                        </div>
                                    </div>
                                    <div className='float-right mr-2 mb-3'>
                                        <button type="button" className="btn btn-block btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#EditarInformaçõesPastas">Editar</button>
                                    </div>
                                    <div className="modal fade" id="EditarInformaçõesPastas" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Editar Informações Pastas</h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" ></button>
                                                </div>
                                                <div className="modal-body row">
                                                    <div className="row">
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel1(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 1" label="Nome Pasta 1" value={label1 && label1} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 1: </span>
                                                            <select className="form-select" onChange={(e) => setPasta1(e.target.value)} aria-label="Default select example" value={pasta1 && pasta1} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel2(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 2" label="Nome Pasta 2" value={label2 && label2} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 2: </span>
                                                            <select className="form-select" onChange={(e) => setPasta2(e.target.value)} aria-label="Default select example" value={pasta2 && pasta2} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel3(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 3" label="Nome Pasta 3" value={label3 && label3} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 3: </span>
                                                            <select className="form-select" onChange={(e) => setPasta3(e.target.value)} aria-label="Default select example" value={pasta3 && pasta3} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel4(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 4" label="Nome Pasta 4" value={label4 && label4} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 4: </span>
                                                            <select className="form-select" onChange={(e) => setPasta4(e.target.value)} aria-label="Default select example" value={pasta4 && pasta4} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel5(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 5" label="Nome Pasta 5" value={label5 && label5} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 5: </span>
                                                            <select className="form-select" onChange={(e) => setPasta5(e.target.value)} aria-label="Default select example" value={pasta5 && pasta5} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel6(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 6" label="Nome Pasta 6" value={label6 && label6} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 6: </span>
                                                            <select className="form-select" onChange={(e) => setPasta6(e.target.value)} aria-label="Default select example" value={pasta6 && pasta6} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel7(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 7" label="Nome Pasta 7" value={label7 && label7} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 7: </span>
                                                            <select className="form-select" onChange={(e) => setPasta7(e.target.value)} aria-label="Default select example" value={pasta7 && pasta7} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel8(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 8" label="Nome Pasta 8" value={label8 && label8} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 8: </span>
                                                            <select className="form-select" onChange={(e) => setPasta8(e.target.value)} aria-label="Default select example" value={pasta8 && pasta8} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel9(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 9" label="Nome Pasta 9" value={label9 && label9} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 9: </span>
                                                            <select className="form-select" onChange={(e) => setPasta9(e.target.value)} aria-label="Default select example" value={pasta9 && pasta9} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel10(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 10" label="Nome Pasta 10" value={label10 && label10} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 10: </span>
                                                            <select className="form-select" onChange={(e) => setPasta10(e.target.value)} aria-label="Default select example" value={pasta10 && pasta10} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel11(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 11" label="Nome Pasta 11" value={label11 && label11} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 11: </span>
                                                            <select className="form-select" onChange={(e) => setPasta11(e.target.value)} aria-label="Default select example" value={pasta11 && pasta11} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField onChange={(e) => setLabel12(e.target.value)} type="text" className="form-control my-4" id="Nome Pasta 12" label="Nome Pasta 12" value={label12 && label12} />
                                                        </div>
                                                        <div className="col-md-6 my-2">
                                                            <span className="">Pasta 12 </span>
                                                            <select className="form-select" onChange={(e) => setPasta12(e.target.value)} aria-label="Default select example" value={pasta12 && pasta12} >
                                                                <option selected>-- Selecione um Tipo --</option>
                                                                <option value={'Mostrar'}>Mostrar</option>
                                                                <option value={'Ocultar'}>Ocultar</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                    <button onClick={EditarInformaçõesPastas} type="button" className="btn btn-primary" data-bs-dismiss="modal">Atualizar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/** Layout */}
                                <div className='col-md-4 my-3 mb-5 border-left border-right'>
                                    <h3 className="ml-3 text-dark text-center font-weight-bold">𝑳𝒂𝒚𝒐𝒖𝒕</h3>
                                    <div className='row'>
                                        <h5 className="col-md-12 my-3 text-dark text-center font-weight-bold">Em breve !</h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Projetos