import React from 'react'
import { useSelector } from 'react-redux'

/** Páginas */
import MenuLogin from './../../components/menu/login'
import MenuHome from './../../components/menu/home'
import Footer from '../../../components/footer'

import MeusOrcamentos from './MeusOrcamentos'

function Minhasobras() {
    return (
        <div>
            {
                useSelector(state => state.usuarioLogado) > 0 ?
                    <>
                        <MenuLogin />
                        <MeusOrcamentos />
                        <Footer />
                    </>
                    :
                    <>
                        <MenuHome />
                        <h1>Home</h1>
                        <Footer />
                    </>
            }
        </div >
    )
}

export default Minhasobras