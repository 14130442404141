import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import firebase from '../../config/firebase'
import { getStorage, ref, getDownloadURL, uploadBytes, listAll } from "firebase/storage"

/** Material Ui */
import { TextField } from '@mui/material'

/** Páginas */
import ObrasCard from '../../../components/obras-card'

/** Moment */
import moment from 'moment'
import 'moment/locale/pt-br'

function MinhasObras(props) {

    const UsuarioEmail = useSelector(state => state.usuarioEmail)
    const db = firebase.firestore()

    const [Pesquisa, setPesquisa] = useState('')

    const storage = getStorage()

    //#region Lista de Obras do Usuário
    const [Obras, setObras] = useState([])
    let listaObras = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaObras.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObras(listaObras)
        })
    }, [Pesquisa])
    //#endregion

    //#region Carregando as Pastas
    useEffect(() => {
        // Carregar pastas do Firestore (ou da sua fonte de dados)
        firebase.firestore().collection('Usuários').doc(UsuarioEmail).collection('Pastas')
            .orderBy('LabelPasta')  // Ordenar por nome da pasta
            .get()
            .then(async (resultado) => {
                const pastas = resultado.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Aqui você obtém os códigos dinamicamente
                const codigosObras = pastas.flatMap(pasta => pasta.Pastas);

                // Agora você pode usar esses códigos para carregar as obras
                const obrasPromises = codigosObras.map(async (codigoObra) => {
                    const obraDoc = await firebase.firestore().collection('Obras').doc(codigoObra).get();
                    const obraData = obraDoc.data();
                    return obraData ? { id: obraDoc.id, ...obraData } : null;
                });

                // Aguarde todas as obras serem carregadas
                const obras = await Promise.all(obrasPromises);

                setRelPastas(pastas);
                setObras(obras.filter(Boolean)); // Filtra obras nulas (que não foram encontradas)
            })
            .catch(erro => {
                console.error('Erro ao carregar pastas:', erro);
            });
    }, []); // Certifique-se de ajustar dependências conforme necessário
    //#endregion

    //#region Função Adicionar Pasta
    let listaPastas = []
    const [RelPastas, setRelPastas] = useState([])
    const [LabelPasta, setLabelPasta] = useState('')
    const [pastaSelecionada, setPastaSelecionada] = useState(null);
    const adicionarPasta = async () => {
        if (pastaSelecionada) {
            // Editar pasta existente
            firebase.firestore().collection('Usuários').doc(UsuarioEmail).collection('Pastas')
                .doc(pastaSelecionada.id)
                .update({ LabelPasta })
                .then(() => {
                    // Atualizar lista após edição
                    setRelPastas(prevPastas =>
                        prevPastas.map(pasta =>
                            pasta.id === pastaSelecionada.id ? { ...pasta, LabelPasta } : pasta
                        )
                    );
                    setLabelPasta(''); // Limpar campo após edição
                    setPastaSelecionada(null); // Limpar estado de pasta selecionada
                    console.log('Pasta editada com sucesso!');
                })
                .catch(erro => {
                    console.error('Erro ao editar pasta:', erro);
                });
        } else {
            // Adicionar nova pasta
            firebase.firestore().collection('Usuários').doc(UsuarioEmail).collection('Pastas')
                .add({ LabelPasta, Data: new Date() })
                .then(() => {
                    // Atualizar lista após adição
                    setRelPastas(prevPastas => [...prevPastas, { LabelPasta, Data: new Date() }]);
                    setLabelPasta(''); // Limpar campo após adição
                    console.log('Pasta adicionada com sucesso!');
                })
                .catch(erro => {
                    console.error('Erro ao adicionar pasta:', erro);
                });
        }
    };
    //#endregion

    //#region Editar pasta
    const handleEditarPasta = (pasta) => {
        setPastaSelecionada(pasta);
        setLabelPasta(pasta.LabelPasta);
        // Abra a caixa de diálogo de adição/editar
        document.getElementById('AdicionarDocumento').classList.add('show');
    };
    //#endregion

    //#region Excluir Obras
    const handleExcluirPasta = (id) => {
        if (window.confirm('Tem certeza de que deseja excluir esta pasta?')) {
            // Excluir pasta do Firestore (ou da sua fonte de dados)
            firebase.firestore().collection('Usuários').doc(UsuarioEmail).collection('Pastas')
                .doc(id)
                .delete()
                .then(() => {
                    // Atualizar lista após exclusão
                    setRelPastas(prevPastas => prevPastas.filter(pasta => pasta.id !== id));
                    console.log('Pasta excluída com sucesso!');
                })
                .catch(erro => {
                    console.error('Erro ao excluir pasta:', erro);
                });
        }
    };
    //#endregion

    //#region Visualizar Foto perfil
    const [urlImagens, setUrlImagens] = useState()
    getDownloadURL(ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/${'FotoPerfil'}`))
        .then(url => setUrlImagens(url))
        .catch(error => {
            // Tratar o erro aqui
            console.error("Erro ao obter URL da imagem:", error.message)
            // Definir uma URL padrão ou fazer outra coisa para lidar com a falta do objeto
        })

    const [urlImagemNova, setUrlImagemNova] = useState()
    getDownloadURL(ref(storage, `Usuários/${'user defalt.png'}`)).then(url => setUrlImagemNova(url))
    //#endregion

    //#region Imagens Storage
    const [fotoNova, setFotoNova] = useState(null)
    const imageListRef = ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/`)

    const cadastrarfoto = () => {
        if (fotoNova == null) return;
        const imageRef = ref(storage, `Usuários/${UsuarioEmail}/FotoPerfil/${'FotoPerfil'}`);
        uploadBytes(imageRef, fotoNova).then((snaphsot) => {
            getDownloadURL(snaphsot.ref).then((url) => {
                setUrlImagens(url)
            })
            alert("Image Alterada")
        })
    }
    useEffect(() => {
        listAll(imageListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setUrlImagens(url)
                })
            })
        })
    }, [])

    //#endregion

    //#region Formatar Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region Visualizar Perfil
    const [viewPerfil, SetViewPerfil] = useState('TimeLine')
    function viewModeTimeLine() { SetViewPerfil('TimeLine') }
    function viewModeMinhasObras() { SetViewPerfil('MinhasObras') }
    function viewModeOrçamentos() { SetViewPerfil('Orçamentos') }
    function viewModeTreinamento() { SetViewPerfil('Treinamento') }
    function viewModeNetwork() { SetViewPerfil('Network') }
    function viewModeConfigurações() { SetViewPerfil('Configurações') }
    //#endregion

    //#region Informações Usuário
    const [InfoPessoais, setInfoPessoais] = useState('')
    const [InfoPastas, setInfoPastas] = useState('')
    const [pasta1, setPasta1] = useState('')
    const [label1, setLabel1] = useState('')
    const [pasta2, setPasta2] = useState('')
    const [label2, setLabel2] = useState('')
    const [pasta3, setPasta3] = useState('')
    const [label3, setLabel3] = useState('')
    const [pasta4, setPasta4] = useState('')
    const [label4, setLabel4] = useState('')
    const [pasta5, setPasta5] = useState('')
    const [label5, setLabel5] = useState('')
    const [pasta6, setPasta6] = useState('')
    const [label6, setLabel6] = useState('')
    const [pasta7, setPasta7] = useState('')
    const [label7, setLabel7] = useState('')
    const [pasta8, setPasta8] = useState('')
    const [label8, setLabel8] = useState('')
    const [pasta9, setPasta9] = useState('')
    const [label9, setLabel9] = useState('')
    const [pasta10, setPasta10] = useState('')
    const [label10, setLabel10] = useState('')
    const [pasta11, setPasta11] = useState('')
    const [label11, setLabel11] = useState('')
    const [pasta12, setPasta12] = useState('')
    const [label12, setLabel12] = useState('')

    useEffect(() => {
        firebase.firestore().collection('Usuários').doc(UsuarioEmail).get().then(resultado => {
            setInfoPastas(resultado.data().InfoPastas)
            setLabel1(resultado.data().InfoPastas.Label1)
            setLabel2(resultado.data().InfoPastas.Label2)
            setLabel3(resultado.data().InfoPastas.Label3)
            setLabel4(resultado.data().InfoPastas.Label4)
            setLabel5(resultado.data().InfoPastas.Label5)
            setLabel6(resultado.data().InfoPastas.Label6)
            setLabel7(resultado.data().InfoPastas.Label7)
            setLabel8(resultado.data().InfoPastas.Label8)
            setLabel9(resultado.data().InfoPastas.Label9)
            setLabel10(resultado.data().InfoPastas.Label10)
            setLabel11(resultado.data().InfoPastas.Label11)
            setLabel12(resultado.data().InfoPastas.Label12)
            setPasta1(resultado.data().InfoPastas.Pasta1)
            setPasta2(resultado.data().InfoPastas.Pasta2)
            setPasta3(resultado.data().InfoPastas.Pasta3)
            setPasta4(resultado.data().InfoPastas.Pasta4)
            setPasta5(resultado.data().InfoPastas.Pasta5)
            setPasta6(resultado.data().InfoPastas.Pasta6)
            setPasta7(resultado.data().InfoPastas.Pasta7)
            setPasta8(resultado.data().InfoPastas.Pasta8)
            setPasta9(resultado.data().InfoPastas.Pasta9)
            setPasta10(resultado.data().InfoPastas.Pasta10)
            setPasta11(resultado.data().InfoPastas.Pasta11)
            setPasta12(resultado.data().InfoPastas.Pasta12)
        })
    }, [])
    //#endregion

    //#region Lista de Obras do Usuário
    const [minhasObras, setMinhasObras] = useState([])
    let listaMinhasObras = []

    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', UsuarioEmail).get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaMinhasObras.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setMinhasObras(listaMinhasObras)
        })
    }, [Pesquisa])
    //#endregion

    //#region Visualizar Informações Básicas
    const [viewFiltro, SetViewFiltro] = useState('False')
    function viewModeFiltro() {
        viewFiltro == 'True' && SetViewFiltro('False')
        viewFiltro == 'False' && SetViewFiltro('True')
    }
    //#endregion

    //#region View Tabela
    const [viewPainel, SetViewPainel] = useState('Cartão')
    function viewModeCartão() { SetViewPainel('Cartão') }
    function viewModeTabela() { SetViewPainel('Tabela') }
    //#endregion

    return (
        <>
            <div className="col-md-12 my-1">
                <div class="card card-widget widget-user shadow">
                    <div class="widget-user-header bg-olive">
                        {<h3 class="widget-user-username">{InfoPessoais.Nome ? InfoPessoais.Nome : 'Novo Usuário'}</h3>}
                        <h5 class="widget-user-desc">{UsuarioEmail}</h5>
                    </div>
                    <div class="widget-user-image">
                        <img class="img-circle elevation-2" src={urlImagens ? urlImagens : urlImagemNova} alt="User Avatar" />
                    </div>
                    <div class="card-footer">
                        <h2 className="ml-3 text-dark text-center font-weight-bold">Minhas Obras</h2>
                    </div>
                </div>
            </div>
            <div className='col-md-12 '>
                <div className='row'>
                    <div className='mb-3 form-check-inline mt-3'>
                        <Link className="btn btn-outline-info ml-3" to='/AdicionarObras'>Adicionar Obras</Link>
                    </div>
                    <div className='col-md-3'>
                        <div className="uk-card uk-card-default col-md-12 border border-primary-subtle">
                            <div className="uk-card-header mb-2">
                                <h2 className="text-center">Minhas Pastas</h2>
                            </div>
                            {/** Pasta */}
                            <ul className="nav uk-nav uk-navbar-nav">
                                {RelPastas.map(pasta => (
                                    <li key={pasta.id} className="uk-nav uk-navbar-dropdown-nav uk-width-1-1@s" uk-nav="multiple: false">
                                        <li className="uk-nav-sub my-1" data-bs-toggle="tab" data-bs-target={`#${pasta.id}`} type="button" role="tab" aria-controls={`${pasta.id}-tab-pane`} aria-selected="true">
                                            {pasta.LabelPasta} ({pasta.Pastas ? pasta.Pastas.reduce((acc, obraId) => {
                                                const obra = Obras.find(obra => obra.id === obraId);
                                                return acc + (obra ? parseFloat(obra.Contagem) : 0);
                                            }, 0) : 0})
                                            <button className='btn btn-outline-danger ml-2 float-right btn-sm' variant="outlined" size="small" color="error" onClick={() => handleExcluirPasta(pasta.id)}>Excluir</button>
                                            <button className="btn btn-outline-warning float-right btn-sm" onClick={() => handleEditarPasta(pasta)} variant="outlined" size="small" data-bs-toggle="modal" data-bs-target="#EditarPasta">Editar</button>
                                        </li>
                                        {/* Caixa de diálogo de Editar Pasta */}
                                        <div className="modal fade" id="EditarPasta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h1 className="modal-title fs-5" id="exampleModalLabel">{pastaSelecionada ? 'Editar Pasta' : 'Adicionar Pasta'}</h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row col-md-12 mb-5">
                                                            <TextField
                                                                InputLabelProps={{ shrink: true }}
                                                                onChange={(e) => setLabelPasta(e.target.value)}
                                                                type="text"
                                                                className="form-control"
                                                                label="Nome da Pasta"
                                                                value={LabelPasta && LabelPasta} />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                                        <button type="button" className="btn btn-primary" onClick={adicionarPasta} data-bs-dismiss="modal">
                                                            {pastaSelecionada ? 'Editar' : 'Adicionar'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                <li className="uk-nav uk-navbar-dropdown-nav uk-width-1-1@s" uk-nav="multiple: false">
                                    <li className="uk-nav-sub" id="Estudo-tab" data-bs-toggle="tab" data-bs-target="#Pasta12" type="button" role="tab" aria-controls="Estudo-tab-pane" aria-selected="true">
                                        {<p>Minhas Obras ({minhasObras.reduce((acc, p) => acc + parseFloat(p.Contagem), 0)})</p>}
                                    </li>
                                </li>
                            </ul>
                            {/** Adicionar Pasta */}
                            <div className="uk-card-footer">
                                <button className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#AdicionarDocumento">Criar Pasta</button>
                            </div>
                            <div className="modal fade" id="AdicionarDocumento" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="exampleModalLabel">Criar Pasta</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row col-md-12 mb-5">
                                                <TextField
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setLabelPasta(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="Nome da Pasta"
                                                    value={LabelPasta && LabelPasta} />
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                            <button type="button" className="btn btn-primary" onClick={adicionarPasta} data-bs-dismiss="modal">Adicionar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Caixa de diálogo de Adicionar Pasta */}
                            <div className="modal fade" id="AdicionarDocumento" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="exampleModalLabel">{pastaSelecionada ? 'Editar Pasta' : 'Adicionar Pasta'}</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row col-md-12 mb-5">
                                                <TextField
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setLabelPasta(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    label="Nome da Pasta"
                                                    value={LabelPasta && LabelPasta} />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                            <button type="button" className="btn btn-primary" onClick={adicionarPasta} data-bs-dismiss="modal">
                                                {pastaSelecionada ? 'Editar' : 'Adicionar'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <div className="tab-content" id="myTabContent">
                            {/** Pasta */}
                            {RelPastas.map(pasta => (
                                <div key={pasta.id} className="tab-pane fade" id={pasta.id} role="tabpanel" aria-labelledby={`${pasta.id}-tab`} tabIndex="0">

                                    <div className="row col-md-12">
                                        <div className="uk-card uk-card-default col-md-12 border rounded-top rounded-3">
                                            <div className="uk-card-header">
                                                <h2 className='text-center'>{pasta.LabelPasta}</h2>
                                            </div>
                                            {/* Mapeia as obras associadas à pasta */}

                                            <ul >
                                                <div className="row col-md-12 mt-4">
                                                    {pasta.Pastas && pasta.Pastas.map(obraId => {
                                                        // Aqui você pode buscar as informações da obra no Firestore usando o ID
                                                        const obra = Obras.find(o => o.id === obraId);
                                                        // Certifique-se de verificar se a obra foi encontrada antes de renderizar
                                                        if (obra) {
                                                            return (
                                                                <div className="row col-md-4 col-sm-12 mb-3">
                                                                    <div key={obra.id}>
                                                                        <div className="uk-card-default border rounded-top rounded-3 my-2">
                                                                            <div className="uk-card-header">
                                                                                <h5 className='truncate-text' >{obra.InformaçõesBásicas.TituloReduzido}</h5>
                                                                            </div>
                                                                            <div className="uk-card-body">
                                                                                <ul uk-accordion="multiple: true">
                                                                                    <li /** className="uk-open"*/>
                                                                                        <a class="uk-accordion-title" href>Título Completo</a>
                                                                                        <div class="uk-accordion-content">
                                                                                            <p>{obra.InformaçõesBásicas.TituloCompleto}</p>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a className="uk-accordion-title" href>Mais Info</a>
                                                                                        <div className="uk-accordion-content">
                                                                                            <div className="row">
                                                                                                <p className="col-12"><span className="font-weight-bold">Período: </span>{moment(obra.Cronograma.DataInicio).format('L')} a {moment(obra.Cronograma.DataTermino).format('L')}</p>
                                                                                                <p className="col-12"><span className="font-weight-bold">Status: </span>{obra.InformaçõesBásicas.Status}</p>
                                                                                                <p className="col-12"><span className="font-weight-bold">Investimento: </span>{numberFormat(obra.InformaçõesFinanceiras.Investimento)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className="uk-card-footer">
                                                                                <Link to={"/detalhesobras/" + obra.id} className="btn btn-sm btn-outline-primary float-right">Mais Detalhes</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        return null; // ou uma mensagem indicando que a obra não foi encontrada
                                                    })}
                                                </div>
                                            </ul>

                                            <div className="uk-card-footer row">
                                                <div className='col-md-9'>

                                                </div>
                                                <div className="float-right col-md-3">
                                                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                                        <h3 className="uk-card-title">Investimento Total</h3>
                                                        <h5 className='float-right'>
                                                            {numberFormat(pasta.Pastas ? pasta.Pastas.reduce((acc, obraId) => {
                                                                const obra = Obras.find(o => o.id === obraId);
                                                                return acc + (obra ? parseFloat(obra.InformaçõesFinanceiras.Investimento) : 0);
                                                            }, 0) : 0)}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* Adicione o conteúdo específico da pasta aqui */}
                                    </div>


                                </div>
                            ))}

                            {/** Pasta Minhas Obras */}
                            <div className="tab-pane fade" id="Pasta12" role="tabpanel" aria-labelledby="Pasta12-tab" tabindex="0">
                                <div className="row uk-card uk-card-default col-md-12 border border-primary-subtle">
                                    <div className="uk-card-header">
                                        <h2 className='text-center'>Minhas Obras</h2>
                                    </div>
                                    <div className="row col-md-12 mt-4">
                                        {minhasObras.map(item => <ObrasCard
                                            key={item.id}
                                            id={item.id}
                                            titulo={item.InformaçõesBásicas.TituloReduzido}
                                            titulocompleto={item.InformaçõesBásicas.TituloCompleto}
                                            inicio={item.Cronograma.DataInicio}
                                            termino={item.Cronograma.DataTermino}
                                            status={item.InformaçõesBásicas.Status}
                                            usuariosviews={item.EmailView}
                                            investimento={numberFormat(item.InformaçõesFinanceiras.Investimento)}
                                            empenho={numberFormat(item.InformaçõesFinanceiras.EmpenhoFinanceiro)}
                                            viewPainel='Cartão'
                                        />)}
                                    </div>
                                    <div className='col-md-9'>
                                    </div>
                                    <div className="col-md-3 mt-2 mb-5">
                                        <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                            <h3 className="uk-card-title">Investimento Total</h3>
                                            <h5 className=' float-right'>{numberFormat(minhasObras.reduce((acc, p) => acc + parseFloat(p.InformaçõesFinanceiras.Investimento), 0))}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>

    )
}

export default MinhasObras