import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage"
import { useSelector } from 'react-redux'
import { TextField } from '@mui/material'

/** Pastas */
import DocumentosCard from './documentoscard'
import DocumentosPasta from './documentosPasta'

function Documentos() {

    const storage = getStorage();
    const UsuarioEmail = useSelector(state => state.usuarioEmail)
    const { id } = useParams()

    const [Documento, setDocumento] = useState(null)
    const [DescricaoDocumento, setDescricaoDocumento] = useState('')
    const [UrlDocumentos, setUrlDocumentos] = useState('')
    const [PastaSelecionada, setPastaSelecionada] = useState('')
    const [uploadTask, setUploadTask] = useState(null)

    //#region Views Dropdown
    const [viewEdição, SetViewEdição] = useState('False')
    function viewModeMedEdição() {
        viewEdição == 'True' && SetViewEdição('False')
        viewEdição == 'False' && SetViewEdição('True')
    }

    const [viewPasta, SetViewPasta] = useState('False')
    function viewModePasta() {
        viewPasta == 'True' && SetViewPasta('False')
        viewPasta == 'False' && SetViewPasta('True')
    }

    const [viewCriarPasta, SetViewCriarPasta] = useState('False')
    function viewModeCriarPasta() {
        viewCriarPasta == 'True' && SetViewCriarPasta('False')
        viewCriarPasta == 'False' && SetViewCriarPasta('True')
    }

    const [viewTamanhoImagem, SetViewTamanhoImagem] = useState('False')
    function viewModeTamanhoImagem() {
        viewTamanhoImagem == 'True' && SetViewTamanhoImagem('False')
        viewTamanhoImagem == 'False' && SetViewTamanhoImagem('True')
    }

    const [viewUsuárioImagem, SetViewUsuárioImagem] = useState('False')
    function viewModeUsuárioImagem() {
        viewUsuárioImagem == 'True' && SetViewUsuárioImagem('False')
        viewUsuárioImagem == 'False' && SetViewUsuárioImagem('True')
    }

    const [viewNomeArquivo, SetViewNomeArquivo] = useState('False')
    function viewModeNomeArquivo() {
        viewNomeArquivo == 'True' && SetViewNomeArquivo('False')
        viewNomeArquivo == 'False' && SetViewNomeArquivo('True')
    }
    //#endregion

    //#region Formatação do Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region Cadastrar Documento
    const cadastrarDocumento = async () => {
        try {
            // Se não houver documento ou pasta selecionada, exibe uma mensagem de erro e retorna
            if (!Documento || !PastaSelecionada) {
                alert("Selecione um documento e uma pasta");
                return;
            }

            // Cria uma referência resumível para o upload
            const task = uploadBytesResumable(ref(storage, `Obras/${id}/Documentos/${PastaSelecionada}/${Documento.name}`), Documento);

            // Atualiza a barra de progresso (opcional)
            task.on('state_changed',
                (snapshot) => { },
                (error) => {
                    console.error('Erro durante o upload:', error);
                    alert('Erro durante o upload. Por favor, tente novamente.');
                },
                async () => {
                    // Upload concluído com sucesso
                    const url = await getDownloadURL(task.snapshot.ref);
                    setUrlDocumentos(url);
                    alert("Documento Inserido");

                    // Adiciona ao Firestore
                    const docRef = firebase.firestore().collection('Obras').doc(id).collection('Documentos').doc();

                    // Obtém o tamanho do documento em bytes
                    const tamanhoDocumento = Documento.size;

                    await docRef.set({
                        DescricaoDocumento: DescricaoDocumento,
                        Data: new Date(),
                        Usuario: UsuarioEmail,
                        Obra: id,
                        Documento: Documento.name,
                        Documentos: docRef,
                        Pasta: PastaSelecionada,
                        urlDocumentos: url, // Adiciona a URL do documento
                        tamanhoDocumento: tamanhoDocumento
                    });

                    // Limpa os campos de entrada
                    setDocumento(null);
                    setDescricaoDocumento('');

                    // Carrega os documentos
                    firebase.firestore().collection('Obras').doc(id).collection('Documentos').where('Pasta', '==', PastaSelecionada).get().then(async (resultado) => {
                        await resultado.docs.forEach(doc => {
                            listaDocumentos.push({
                                id: doc.id,
                                ...doc.data()
                            })
                        })
                        setRelDocumentos(listaDocumentos);
                    });
                }
            );

            // Atualiza o estado uploadTask
            setUploadTask(task);
        } catch (error) {
            console.error('Erro ao adicionar documento:', error);
            alert('Erro ao adicionar documento. Por favor, tente novamente.');
        }
    };
    //#endregion

    //#region Obter informações para fazer o Reducer (tamanho total da galeria)
    let listatodosdocumentos = [];
    const [RelDocumentos1, setRelDocumentos1] = useState([]);

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('Obras').doc(id).collection('Documentos')
            .onSnapshot((snapshot) => {
                // Limpa a lista antes de atualizar com os novos dados
                listatodosdocumentos = [];

                snapshot.forEach((doc) => {
                    listatodosdocumentos.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                // Atualiza o estado com a lista atualizada
                setRelDocumentos1([...listatodosdocumentos]);
            });

        // O retorno da função useEffect é chamado quando o componente é desmontado
        return () => {
            // Isso cancela a inscrição quando o componente é desmontado
            unsubscribe();
        };
    }, []);

    //#endregion

    //#region Obter Documentos
    let listaDocumentos = []
    const [RelDocumentos, setRelDocumentos] = useState([])

    const ObterDocumentos = () => {
        const unsubscribe = firebase.firestore().collection('Obras').doc(id).collection('Documentos')
            .orderBy('DescricaoDocumento')
            .where('Pasta', '==', PastaSelecionada)
            .onSnapshot((snapshot) => {
                let listaDocumentos = []; // É melhor definir como let dentro do escopo da função

                snapshot.forEach((doc) => {
                    listaDocumentos.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                // Atualiza o estado com a lista atualizada
                setRelDocumentos([...listaDocumentos]);
            });

        // O retorno da função useEffect é chamado quando o componente é desmontado ou quando as dependências mudam
        return () => {
            // Isso cancela a inscrição quando o componente é desmontado
            unsubscribe();
        };
    };    

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('Obras').doc(id).collection('Documentos')
            .orderBy('DescricaoDocumento')
            .where('Pasta', '==', PastaSelecionada)
            .onSnapshot((snapshot) => {
                // Limpa a lista antes de atualizar com os novos dados
                listaDocumentos = [];

                snapshot.forEach((doc) => {
                    listaDocumentos.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });

                // Atualiza o estado com a lista atualizada
                setRelDocumentos([...listaDocumentos]);
            });

        // O retorno da função useEffect é chamado quando o componente é desmontado ou quando as dependências mudam
        return () => {
            // Isso cancela a inscrição quando o componente é desmontado
            unsubscribe();
        };
    }, [PastaSelecionada]);
    //#endregion

    //#region Criar Pastas
    const [NomePasta, setNomePasta] = useState()
    const [DescricaoPasta, setDescricaoPasta] = useState()

    const CriarPasta = async () => {
        // Verifique se o nome da pasta foi fornecido
        if (!NomePasta) {
            alert("Digite um nome para a pasta");
            return;
        }

        // Adicione a nova pasta à coleção "Pastas"
        const pastaRef = firebase.firestore().collection('Obras').doc(id).collection('PastasDoc').doc();

        pastaRef.set({
            Nome: NomePasta,
            Descricao: DescricaoPasta || '',
            DataCriacao: new Date(),
            Usuario: UsuarioEmail,
        });

        // Limpe os estados após criar a pasta
        const carregarPastas = async () => {
            try {
                const snapshot = await firebase.firestore().collection('Obras').doc(id).collection('PastasDoc').orderBy('Nome').get();
                const listaPastas = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPastas(listaPastas);
            } catch (error) {
                console.error('Erro ao obter pastas:', error);
            }
        };
        carregarPastas();
        setNomePasta('')
        setDescricaoPasta('')
        alert("Pasta criada com Sucesso")
    }

    //#endregion

    //#region Carregar Pastas
    const [pastas, setPastas] = useState([]);
    useEffect(() => {
        const carregarPastas = async () => {
            try {
                const snapshot = await firebase.firestore().collection('Obras').doc(id).collection('PastasDoc').orderBy('Nome').get();
                const listaPastas = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPastas(listaPastas);
            } catch (error) {
                console.error('Erro ao obter pastas:', error);
            }
        };
        carregarPastas();
    }, [id])
    //#endregion

    //#region Listar Pasta
    let listaPastas = []
    const carregarPastas = () => {
        firebase.firestore().collection('Obras').doc(id).collection('PastasDoc').orderBy('Nome').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaPastas.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setPastas(listaPastas)
        })
    }
    //#endregion

    return (
        <div className='row'>
            <div className="uk-card uk-card-default col-md-12 mb-3">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand">
                            <div className="d-flex align-items-center">
                                <h3 className="uk-card-title uk-margin-remove-bottom">Meus Documentos</h3>
                                <div class="uk-inline">
                                    <i className="fa-solid fa-caret-down ml-3" style={{ fontSize: '22px' }} />
                                    <div uk-dropdown="mode: click; animation: slide-top; animate-out: true; duration: 700 ">
                                        <ul className="uk-nav uk-dropdown-nav">
                                            <li class="uk-nav-header mb-2  fw-bolder">Pastas</li>
                                            <li className='mb-2'><input onClick={viewModeCriarPasta} className="uk-checkbox" type="checkbox" /> Criar Pasta</li>
                                            <li className='mb-2'><input onClick={viewModePasta} className="uk-checkbox" type="checkbox" /> Editar Pasta</li>
                                            <li class="uk-nav-divider mt-3"></li>
                                            <li class="uk-nav-header mb-2 fw-bolder">Arquivos</li>
                                            <li className='mb-2'><input onClick={viewModeMedEdição} className="uk-checkbox" type="checkbox" /> Editar Arquivos</li>
                                            <li className='mb-2'><input onClick={viewModeTamanhoImagem} className="uk-checkbox" type="checkbox" /> Tamanho dos Arquivos</li>
                                            <li className='mb-2'><input onClick={viewModeNomeArquivo} className="uk-checkbox" type="checkbox" /> Nome do Arquivo</li>
                                            <li className='mb-2'><input onClick={viewModeUsuárioImagem} className="uk-checkbox" type="checkbox" /> Informações Usuário</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h5 className=' float-right'>{numberDecimalFormat(RelDocumentos1.reduce((acc, p) => acc + parseFloat(p.tamanhoDocumento), 0) / 1000000)}Mb / 100Mb</h5>
                        </div>
                    </div>
                </div>
                <div className="uk-card-body">
                    <div className='col-12'>
                        <div className="row">

                            {/** Minhas Pastas */}
                            <div className='mb-3 border-bottom'>
                                <h4 className='mb-4'>Minhas Pastas</h4>
                                {/* Adicione um botão para cada pasta */}
                                {pastas.map(pasta => (
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary ml-2 mb-3"
                                        key={pasta.id}
                                        onClick={() => {
                                            setPastaSelecionada(pasta.id);
                                        }}
                                    >
                                        <span>{pasta.Nome}</span>
                                    </button>
                                ))}
                            </div>

                            {/** Informações da Pasta */}
                            <div className='text-center'>
                                <div>
                                    {PastaSelecionada && (
                                        <div>
                                            {pastas.map(pasta => {
                                                if (pasta.id === PastaSelecionada) {
                                                    return (
                                                        <>
                                                            <DocumentosPasta
                                                                key={pasta.id}
                                                                Nome={pasta.Nome}
                                                                IdPasta={pasta.id}
                                                                CardPastaSelecionada={PastaSelecionada}
                                                                IdObra={id}
                                                                Descricao={pasta.Descricao}
                                                                tamanhoPasta={numberDecimalFormat(RelDocumentos.reduce((acc, p) => acc + parseFloat(p.tamanhoDocumento), 0) / 1000000)}
                                                                viewPasta={viewPasta}
                                                                carregarPastas={carregarPastas}
                                                            />
                                                        </>
                                                    )
                                                }
                                            }
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/** Obter Documentos */}
                            <div className='col-12'>
                                <div className="row text-center">
                                    {RelDocumentos.length > 0 ? (
                                        RelDocumentos.map(item => (
                                            <>
                                                <DocumentosCard
                                                    key={item.id}
                                                    IdObra={id}
                                                    IdPasta={item.id}
                                                    Data={item.Data}
                                                    DescricaoDocumento={item.DescricaoDocumento}
                                                    CardPastaSelecionada={PastaSelecionada}
                                                    Fotos={item.Fotos}
                                                    Documento={item.Documento}
                                                    Usuario={item.Usuario}
                                                    ViewEdição={viewEdição}
                                                    ViewTamanhoImagem={viewTamanhoImagem}
                                                    ViewUsuárioImagem={viewUsuárioImagem}
                                                    ViewNomeArquivo={viewNomeArquivo}
                                                    TamanhoDocumento={item.tamanhoDocumento}
                                                    UrlDocumentos={item.urlDocumentos}
                                                    ObterDocumentos={ObterDocumentos}
                                                />
                                            </>
                                        ))
                                    ) : (
                                        <div className="col-12 mt-5">
                                            <h3 className='text-red'>Aqui aparecem seus documentos quando você adicionar documentos a sua pasta.</h3>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>



                </div>

                <div className="uk-card-footer">
                    <button className="btn btn-sm btn-outline-primary float-right ml-2" data-bs-toggle="modal" data-bs-target="#Adicionardocumentos">Adicionar Docs</button>
                    {viewCriarPasta == 'True' ?
                        <button className="btn btn-sm btn-outline-success float-right" data-bs-toggle="modal" data-bs-target="#Criarpasta">Criar Pasta</button>
                        : ''}
                </div>

                {/* Modal para adicionar documentos */}
                <div className="modal fade" id="Adicionardocumentos" tabIndex="-1" aria-labelledby="AdicionarFoto" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Adicionar Documento</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row col-md-12 mb-5">
                                    <p className="mb-2" id="EscolherPasta">Primeiro Escolha a Pasta:</p>
                                    <select
                                        onChange={(e) => setPastaSelecionada(e.target.value)}
                                        className="form-select">
                                        <option value="">Selecionar Pasta</option>
                                        {pastas.map((pasta) => (
                                            <option key={pasta.id} value={pasta.id}>{pasta.Nome}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="row col-md-12 mb-5">
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setDescricaoDocumento(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        label="Descrição do Arquivo"
                                        value={DescricaoDocumento && DescricaoDocumento} />
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <input onChange={(e) => setDocumento(e.target.files[0])} type="file" className="form-control" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={cadastrarDocumento} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Adicionar</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal para criar pasta */}
                <div className="modal fade" id="Criarpasta" tabIndex="-1" aria-labelledby="Criarpasta" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="Criarpasta">Adicionar Documento</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row col-md-12 mb-5">
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setNomePasta(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        label="Nome da Pasta"
                                        value={NomePasta && NomePasta} />
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setDescricaoPasta(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        label="Breve descrição da Pasta"
                                        value={DescricaoPasta && DescricaoPasta} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={CriarPasta} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Criar Pasta</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Documentos