import React, { useState, useEffect } from 'react'
import firebase from '../../../config/firebase'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

/** Cards */
import ObrasCard from '../../../components/obras-card'
import Viewlist from './lista'
import ViewLinkLista from './link'

/** Moment */
import moment from 'moment'
import 'moment/locale/pt-br'

function Obras() {
    
    const [Obras, setObras] = useState([])
    const [view, setview] = useState('card')
    const [Pesquisa, setPesquisa] = useState('')
    let listaobras = []
    const usuarioEmail = useSelector(state => state.usuarioEmail)
    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);

    function viewcard() { setview('card') }
    function viewlist() { setview('list') }

    let getId = []
    useEffect(() => {
        firebase.firestore().collection('Obras').where('EmailView', 'array-contains', usuarioEmail).get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                if (doc.data().InformaçõesBásicas.TituloReduzido.indexOf(Pesquisa) >= 0) {
                    listaobras.push({
                        id: doc.id,
                        ...doc.data()
                    })
                }
            })
            setObras(listaobras)
        })
    }, [Pesquisa])

    return (
        <>
            <div className="">
                <div className="row col-md-12">
                    <div className="col-md-8">
                        <h3 className="p-3">Meus Orçamentos</h3>
                    </div>
                    <div className="col-md-4">
                        <Link to="/adicionarorcamentos" className="nav-link">
                            <button type="button" className="btn btn-sm float-right mt-1 mb-3 btn-cadastro">Adicionar Orçamento</button>
                        </Link>
                    </div>
                </div>
                <div className="row mb-3 ml-3 mr-3">
                    <input onChange={(e) => setPesquisa(e.target.value)} type="text" className="form-control text-center p-3" placeholder="Pesquisar Obras" />
                </div>

            </div>

        </>
    )
}
export default Obras