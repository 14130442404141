import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

/** Componentes */
import Menu from '../../components/menu/login'
import Cabeçalho from '../../components/cabeçalho'
import Footer from '../../components/footer'

/** Informações Básicas */
import InfoBásicas from './InfoBásicas'
import Fiscalização from './Fiscalização'
import CronogramaFinanceiro from './CronoFinanceiro'
import InfoContratado from './InfoContratado'
import Anotações from './Anotações'
import Suspensões from './Suspensões'
import Chat from './chat'
import Documentos from './documentos'
import Medição from './medição'
import UsuáriosEditores from './usuariosEditores'
import OrganizaçãoPastas from './organizaçãoPastas'
import InfoContratante from './infoContratante'
import InfoFinanceiras from './infoFinanceiras'
import Localização from './localização'
import Fotos from './fotos'
import ListadeTarefas from './ListadeTarefas'
import Análise from './análise'
import GraficoGantt from './graficoGantt'
import Calendario from './calendario'
import Home from './home'

function Detalhes() {

    const { id } = useParams()

    const UsuarioEmail = useSelector(state => state.usuarioEmail)

    const [activeTab, setActiveTab] = useState('Analise'); // Aba padrão ativa

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    }


    //#region Informações Básicas para o Título Reduzido da Obra
    const [InformaçõesBásicas, setInformaçõesBásicas] = useState([])
    const [TituloReduzido, setTituloReduzido] = useState('')
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setInformaçõesBásicas(resultado.data().InformaçõesBásicas)
            setTituloReduzido(resultado.data().InformaçõesBásicas.TituloReduzido)
        })
    }, [])
    //#endregion

    //#region Listagem dos usuários para visualizar a Obra 
    const [usuariosObra, setUsuariosObra] = useState([]);
    const [editoresObra, setEditoresObra] = useState([]);
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setUsuariosObra(resultado.data().EmailView)
            setEditoresObra(resultado.data().EmailEdição)
        })
    }, [])
    const listUsuariosObra = usuariosObra.map((usuario) => <li key={usuario}>{usuario}</li>)
    const listEditoresObra = editoresObra.map((editor) => <li key={editor}>{editor}</li>)
    //#endregion

    return (
        <>
            {usuariosObra.map(usuario => usuario.toLowerCase()).includes(UsuarioEmail) ?
                <>
                    <Menu />
                    <div className='container-fluid'>
                        <Cabeçalho />
                        <section className="col-md-12 mt-5 mb-3 text-center">
                            <h2 className="ml-3 text-dark text-center font-weight-bold">{InformaçõesBásicas.TituloReduzido}</h2>
                        </section>
                        <div className='container-fluid row'>
                            <div className='col-md-2'>
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-header">
                                        <div className='text-center'>
                                            <h4>Menu</h4>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="row">
                                            <ul className="nav uk-nav uk-navbar-nav">
                                                <li className="uk-nav uk-navbar-dropdown-nav uk-width-1-1@s" uk-nav="multiple: false">

                                                    {/** Análise da Obra */}
                                                    <li className="uk-nav-header text-center text-red mt-3">Análise da Obra</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('Analise')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#Analise" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Análise Geral</a>
                                                    </li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('Home')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#Home" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Home</a>
                                                    </li>
                                                    {/**<li className="uk-nav-sub" onClick={() => handleTabChange('Calendario')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#Calendario" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Calendário</a>
            </li> */}
                                                    <li className="uk-nav-divider" />

                                                    {/** Informações Gerais */}
                                                    <li className="uk-nav-header text-center text-red">Informações Gerais</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('InformaçõesBásicas')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#InformaçõesBásicas" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Informações Básicas</a>
                                                    </li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('Localização')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#Localização" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3'>- Localização</a>
                                                    </li>
                                                    <li className="uk-nav-divider" />

                                                    {/** Comunicação */}
                                                    <li className="uk-nav-header text-center text-red">Comunicação</li>
                                                    {/** <li className="uk-nav-sub" id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#Chat" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                            <a className='ml-3'>- Chat</a>
    </li> */}
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('Anotações')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#Anotações" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Anotações</a>
                                                    </li>
                                                    <li className="uk-nav-divider" />

                                                    {/** Informações Contrato */}
                                                    <li className="uk-nav-header text-center text-red">Informações Contrato</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('InformaçõesContratado')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#InfoContratado" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3'>- Informações Contratado</a>
                                                    </li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('InformaçõesContratante')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#InfoContratante" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3'>- Informações Contratante</a>
                                                    </li>
                                                    <li className="uk-nav-divider" />

                                                    {/** Cronograma Físico */}
                                                    <li className="uk-nav-header text-center text-red">Cronograma Físico</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('PrazoseSuspensões')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#Suspensões" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3'>- Prazos e Suspensões</a>
                                                    </li>
                                                    {/**<li className="uk-nav-sub" onClick={() => handleTabChange('GraficoGantt')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#GraficoGantt" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3'>- Gráfico Gantt</a>
</li> */}
                                                    <li className="uk-nav-divider" />

                                                    {/** Informações Financeiras */}
                                                    <li className="uk-nav-header text-center text-red">Informações Financeiras</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('CronogramaFinanceiro')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#CronogramaFinanceiro" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Cronograma Financeiro</a>
                                                    </li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('Medição')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#Medição" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Medição</a>
                                                    </li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('InformaçõesFinanceiras')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#InfoFinanceiras" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Informações Financeiras</a>
                                                    </li>
                                                    <li className="uk-nav-divider" />

                                                    {/** Informações Financeiras */}
                                                    <li className="uk-nav-header text-center text-red">Lista de Tarefas</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('ListadeTarefas')} id="PastaA-tab" data-bs-toggle="tab" data-bs-target="#CronogramaFisico" type="button" role="tab" aria-controls="PastaA-tab-pane" aria-selected="true">
                                                        <a className='ml-3'>- Lista de Tarefas</a>
                                                    </li>
                                                    <li className="uk-nav-divider" />

                                                    {/** Fiscalização */}
                                                    <li className="uk-nav-header text-center text-red">Fiscalização</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('Fiscalização')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#Fiscalização" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3'>- Fiscalização</a>
                                                    </li>
                                                    <li className="uk-nav-divider" />

                                                    {/** Usuário e Editores */}
                                                    <li className="uk-nav-header text-center text-red">Usuários e Editores</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('UsuárioseEditores')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#UsuárioEditores" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3'>- Usuários e Editores</a>
                                                    </li>
                                                    <li className="uk-nav-divider" />

                                                    {/** Arquivos */}
                                                    <li className="uk-nav-header text-center text-red">Arquivos</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('Documentos')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#Documentos" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3'>- Documentos</a>
                                                    </li>
                                                    <li className="uk-nav-divider" />

                                                    {/** Organização Pastas */}
                                                    <li className="uk-nav-header text-center text-red">Fotos</li>
                                                    <li className="uk-nav-sub" onClick={() => handleTabChange('Fotos')} id="PastaB-tab" data-bs-toggle="tab" data-bs-target="#Fotos" type="button" role="tab" aria-controls="PastaB-tab-pane" aria-selected="false">
                                                        <a className='ml-3 mb-4'>- Fotos</a>
                                                    </li>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-10'>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="Home" role="tabpanel" aria-labelledby="PastaA-tab" tabindex="0">
                                        {activeTab === 'Home' && <Home />}
                                    </div>
                                    <div className="tab-pane fade show active" id="Analise" role="tabpanel" aria-labelledby="PastaA-tab" tabindex="0">
                                        {activeTab === 'Analise' && <Análise />}
                                    </div>
                                    <div className="tab-pane fade show active" id="Calendario" role="tabpanel" aria-labelledby="PastaA-tab" tabindex="0">
                                        {activeTab === 'Calendario' && <Calendario />}
                                    </div>
                                    <div className="tab-pane fade show" id="InformaçõesBásicas" role="tabpanel" aria-labelledby="PastaA-tab" tabindex="0">
                                        {activeTab === 'InformaçõesBásicas' && <InfoBásicas />}
                                    </div>
                                    <div className="tab-pane fade show" id="Fiscalização" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'Fiscalização' && <Fiscalização />}
                                    </div>
                                    <div className="tab-pane fade show" id="CronogramaFinanceiro" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'CronogramaFinanceiro' && <CronogramaFinanceiro />}
                                    </div>
                                    <div className="tab-pane fade show" id="InfoContratado" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'InformaçõesContratado' && <InfoContratado />}
                                    </div>
                                    <div className="tab-pane fade show" id="Anotações" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'Anotações' && <Anotações />}
                                    </div>
                                    <div className="tab-pane fade show" id="Suspensões" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'PrazoseSuspensões' && <Suspensões />}
                                    </div>
                                    {/** <div className="tab-pane fade show" id="Chat" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                            <Chat />
</div>*/}
                                    <div className="tab-pane fade show" id="Documentos" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        <Documentos />
                                    </div>
                                    <div className="tab-pane fade show" id="Medição" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'Medição' && <Medição />}
                                    </div>
                                    <div className="tab-pane fade show" id="UsuárioEditores" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'UsuárioseEditores' && <UsuáriosEditores />}
                                    </div>
                                    <div className="tab-pane fade show" id="InfoContratante" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'InformaçõesContratante' && <InfoContratante />}
                                    </div>
                                    <div className="tab-pane fade show" id="InfoFinanceiras" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'InformaçõesFinanceiras' && <InfoFinanceiras />}
                                    </div>
                                    <div className="tab-pane fade show" id="Localização" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'Localização' && <Localização />}
                                    </div>
                                    <div className="tab-pane fade show" id="CronogramaFisico" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'ListadeTarefas' && <ListadeTarefas />}
                                    </div>
                                    <div className="tab-pane fade show" id="GraficoGantt" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        {activeTab === 'GraficoGantt' && <GraficoGantt />}
                                    </div>
                                    <div className="tab-pane fade show" id="Fotos" role="tabpanel" aria-labelledby="PastaB-tab" tabindex="0">
                                        <Fotos />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </>
                : ''}
        </>
    )
}

export default Detalhes