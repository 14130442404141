import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import firebase from '../../config/firebase'
import { useParams } from 'react-router-dom';

/** Páginas */
import MenuHome from '../../components/menu/home'
import MenuLogin from '../../components/menu/login'
import Footer from '../../components/footer'

/** Moment */
import moment from 'moment'
import 'moment/locale/pt-br'

function Home() {

    const UsuarioEmail = useSelector(state => state.usuarioEmail)

    //#region Formatar Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    function ImageWithFallback({ src, alt }) {
        const [errored, setErrored] = useState(false);

        const handleError = () => {
            setErrored(true);
        };

        if (errored) {
            // Exibe mensagem ou imagem alternativa quando a imagem original não puder ser carregada
            return <strong className='text-red'>Foto Apagada !</strong>;
        }

        return <img src={src} alt={alt} onError={handleError} />;
    }

    const [tipos, setTipos] = useState([]);
    const [informacoes, setInformacoes] = useState([]);
    const [ultimoDocumento, setUltimoDocumento] = useState(null);
    const [carregandoMais, setCarregandoMais] = useState(false);

    useEffect(() => {
        const unsubscribeList = []; // Lista para armazenar funções de unsubscribe
        let infosTemp = []; // Armazenar temporariamente os dados de todas as coleções 'Home'

        firebase.firestore().collection('Obras')
            .where('EmailView', 'array-contains', UsuarioEmail)
            .onSnapshot(obrasSnapshot => {
                obrasSnapshot.docs.forEach(doc => {
                    const unsubscribeHome = doc.ref.collection('Home').orderBy('Data', 'desc')
                        .onSnapshot(homeSnapshot => {
                            const homeData = homeSnapshot.docs.map(doc => {
                                const data = doc.data();
                                return {
                                    id: doc.id, // Adicionando o id do documento
                                    Tipo: data.Tipo,
                                    Data: data.Data ? data.Data.toDate() : null,
                                    DescricaoFoto: data.DescricaoFoto,
                                    Pasta: data.Pasta,
                                    TituloReduzido: data.TituloReduzido,
                                    urlImagens: data.urlImagens,
                                    usuario: data.usuário
                                };
                            });

                            infosTemp = [...infosTemp, ...homeData];
                            const uniqueInfos = Array.from(new Map(infosTemp.map(info => [info.id, info])).values());
                            uniqueInfos.sort((a, b) => b.Data - a.Data);
                            setInformacoes(uniqueInfos);
                        });

                    unsubscribeList.push(unsubscribeHome); // Adicionar a função de unsubscribe à lista
                });
            });

        return () => {
            // Desinscrever de todos os listeners quando o componente for desmontado
            unsubscribeList.forEach(unsubscribe => unsubscribe());
        };
    }, [UsuarioEmail]);



    //#region Lista de Obras do Usuário
    const [minhasObras, setMinhasObras] = useState([])
    let listaMinhasObras = []

    useEffect(() => {
        if (UsuarioEmail) {
            firebase.firestore().collection('Obras')
                .where('EmailView', 'array-contains', UsuarioEmail)
                .get()
                .then(async (resultado) => {
                    const obras = resultado.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setMinhasObras(obras);
                }).catch(error => {
                    console.error("Erro ao buscar obras: ", error);
                });
        }
    }, [UsuarioEmail]);
    //#endregion



    const { id } = useParams();
    const [dadosHome, setDadosHome] = useState([]);
    const [limitePostagens, setLimitePostagens] = useState(3);
    const observadorRef = useRef(); // Ref para o observador

    function ImageWithFallback({ src, alt }) {
        const [errored, setErrored] = useState(false);

        const handleError = () => {
            setErrored(true);
        };

        if (errored) {
            // Exibe mensagem ou imagem alternativa quando a imagem original não puder ser carregada
            return <strong className='text-red'>Foto Apagada !</strong>;
        }

        return <img src={src} alt={alt} onError={handleError} />;
    }

    useEffect(() => {
        const observador = new IntersectionObserver((entradas) => {
            if (entradas[0].isIntersecting) {
                setLimitePostagens(limiteAntigo => limiteAntigo + 3);
            }
        }, { threshold: 1.0 });

        if (observadorRef.current) {
            observador.observe(observadorRef.current);
        }

        return () => {
            if (observadorRef.current) {
                observador.unobserve(observadorRef.current);
            }
        };
    }, []);


    return (
        <>
            {useSelector(state => state.usuarioLogado) > 0 ?
                <>
                    <MenuLogin />
                    <div className="col-md-12 my-1">
                        <div class="card card-widget widget-user shadow">
                            <h5 className='text-center my-3'>Seja bem vindo <span className='font-weight-bold'>{UsuarioEmail}</span></h5>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='row'>
                                            <div className='col-md-12 row'>
                                                <div className='col-md-3'>

                                                </div>
                                                <div className='col-md-6'>
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/programaconstrucao-cd195.appspot.com/o/Programa%2FConstru%C3%A7%C3%A3o%20Site.jpg?alt=media&token=0c94203d-ede2-4b81-ac54-a68809bd2c67" className="uk-align-center img-fluid" alt="..." />
                                                </div>
                                                <div className='col-md-3'>

                                                </div>
                                            </div>
                                            <div className='col-md-6 my-3'>
                                                <div className="row uk-card uk-card-default col-md-12 border border-primary-subtle">
                                                    <div className="uk-card-header">
                                                        <h2 className='text-center'>Minhas Obras</h2>
                                                    </div>
                                                    <div className="scrollable-list mt-4">
                                                        {minhasObras.map(obra => (
                                                            <div className="uk-card-body" key={obra.id}>
                                                                <div className="col-md-12">
                                                                    <h3>{obra.InformaçõesBásicas.TituloReduzido}</h3>
                                                                    Data de início: {moment(obra.Cronograma.DataInicio).format('L')}
                                                                    <br />
                                                                    Data de término: {moment(obra.Cronograma.DataTermino).format('L')}
                                                                    <br />
                                                                    Investimento: {numberFormat(obra.InformaçõesFinanceiras.Investimento)}
                                                                    <br />
                                                                    <Link to={"/detalhesobras/" + obra.id} className="btn btn-sm btn-outline-primary">Mais Detalhes</Link>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 my-3'>
                                                <div className="uk-card uk-card-default col-md-12">
                                                    <div className="uk-card-header">
                                                        <div className="uk-grid-small uk-flex-middle" uk-grid>
                                                            <div className="uk-width-expand">
                                                                <h3 class="uk-card-title uk-margin-remove-bottom">Atualizações</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="uk-card-body scrollable-div">
                                                        {informacoes.slice(0, limitePostagens).map((info, index) => (
                                                            (info.Tipo == 'Fotos' &&
                                                                <div className="uk-card uk-card-default uk-margin-bottom">
                                                                    <div className="uk-card-header">
                                                                        <strong className='text-red'>{info.TituloReduzido}</strong>
                                                                        <br></br>
                                                                        <strong>Foto Inserida em {moment(info.Data).format('L')} às {moment(info.Data).format('LT')}.</strong>
                                                                        <p>Inserido por <strong>'{info.usuario}'</strong> na pasta <strong>'{info.Pasta}'</strong>.</p>
                                                                    </div>
                                                                    <div className="uk-card-body-estilo my-3">
                                                                        <ImageWithFallback src={info.urlImagens} alt="Foto" />
                                                                    </div>
                                                                    <div className="uk-card-footer text-center">
                                                                        {info.DescricaoFoto}
                                                                    </div>
                                                                </div>)
                                                        ))}
                                                        {carregandoMais && <p>Carregando mais...</p>}
                                                        <div ref={observadorRef} />
                                                    </div>
                                                </div >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
                :
                <>
                    <MenuHome />
                    <div className="col-md-12 my-1">
                        <div className='d-flex flex-column h-100'>
                            <div className='row'>
                                <div className='col-md-12 mt-3'>
                                    <div className='row float-left'>
                                        <Link className="btn btn-outline-success rounded-pill mb-3 d-block" to='/login'>Faça Login</Link>
                                        <Link className="btn btn-outline-info rounded-pill mb-3 d-block" to='/novousuario'>Cadastre-se</Link>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/programaconstrucao-cd195.appspot.com/o/Programa%2FConstru%C3%A7%C3%A3o%20Site.jpg?alt=media&token=0c94203d-ede2-4b81-ac54-a68809bd2c67" className="uk-align-center img-fluid" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }

        </>
    )
}

export default Home