import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import firebase from '../../../config/firebase'
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage"

/** Material UI */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

import JoditEditor from 'jodit-react'

function ListadeTarefasCard({ id, IdObra, IdTarefas, DataInicio, Assunto, Responsável, Descrição, DataConclusão, Situação, Importância, Observações, Usuario, ViewEdição }) {

    const editor = useRef(null);

    const UsuarioEmail = useSelector(state => state.usuarioEmail)

    const [idObra, setIdObra] = useState(IdObra)
    const [idTarefas, setIdTarefas] = useState(IdTarefas)
    const [dataInicio, setDataInicio] = useState(DataInicio)
    const [assunto, setAssunto] = useState(Assunto)
    const [responsável, setResponsável] = useState(Responsável)
    const [descrição, setDescrição] = useState(Descrição)
    const [dataConclusão, setDataConclusão] = useState(DataConclusão)
    const [situação, setSituação] = useState(Situação)
    const [importância, setImportância] = useState(Importância)
    const [observações, setObservações] = useState(Observações)
    const [usuario, setUsuario] = useState(Usuario)

    //#region Atualizar Tarefas    
    function AtualizarTarefas() {
        firebase.firestore().collection('Obras').doc(idObra).collection('Tarefas').doc(idTarefas).update({
            DataInicio: dataInicio,
            Assunto: assunto,
            Responsável: responsável,
            Descrição: descrição,
            DataConclusão: dataConclusão,
            Situação: situação,
            Importância: importância,
            Observações: observações,
            Usuario: UsuarioEmail,

        }).then(() => {

        }).catch(error => {
            console.error('Erro ao atualizar a descrição da foto:', error);
        });
    }
    //#endregion

    //#region Excluir Tarefas
    function ExcluirTarefas() {
        // Exibe uma mensagem de confirmação e só continua se o usuário clicar em "OK"
        const confirmacao = window.confirm('Tem certeza que deseja apagar esta Tarefa ?');

        if (confirmacao) {
            firebase.firestore().collection('Obras').doc(idObra).collection('Tarefas').doc(idTarefas).delete()
                .then(() => {
                    // Lógica após a exclusão

                    alert('Medição apagada com sucesso!');
                })
                .catch(erro => {
                    console.error('Erro ao apagar suspensão:', erro);
                    alert('Erro ao apagar a medição. Verifique o console para mais detalhes.');
                });
        }
    }
    //#endregion

    return (
        <>

            {/** Situação Pendente */}
            {situação == 'Pendente' &&
                < div className='col-md-4'>
                    <div className="uk-card uk-card-default uk-margin-bottom">
                        <div className="uk-card-header">
                            {importância == 'Baixa' && <h5 style={{ borderBottom: '3px solid LimeGreen', display: 'inline', color: 'black' }}>{assunto}</h5>}
                            {importância == 'Média' && <h5 style={{ borderBottom: '3px solid Gold', display: 'inline', color: 'black' }}>{assunto}</h5>}
                            {importância == 'Alta' && <h5 style={{ borderBottom: '3px solid red', display: 'inline', color: 'black' }}>{assunto}</h5>}
                        </div>
                        <div className="uk-card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className='text-red'>Descrição:</h6>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: descrição }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Responsável:</h6>
                                    <p>{responsável}</p>
                                </div>
                                <div className="col-md-6">
                                    <h6 className='text-red'>Situação:</h6>
                                    <p>{situação}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Data de Início:</h6>
                                    <p>{moment(dataInicio).format('L')}</p>
                                </div>
                                <div className="col-md-6">
                                    <h6 className='text-red'>Data de Conclusão:</h6>
                                    <p>{moment(dataConclusão).format('L')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Importância:</h6>
                                    <p>{importância}</p>
                                </div>
                                <div className="col-md-12">
                                    <h6 className='text-red'>Observação / Solução:</h6>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: observações }} />
                                </div>
                                <div className="col-md-12">
                                    <h6 className='text-red'>Inserido por:</h6>
                                    <p>{usuario}</p>
                                </div>
                            </div>
                        </div>

                        {ViewEdição == 'True' ?
                            <div className="uk-card-footer text-center">
                                <button className="btn btn-sm btn-outline-warning" data-bs-toggle="modal" data-bs-target={`#A${idTarefas}-modalcard-1`}>Editar</button>
                                <button className="btn btn-sm btn-outline-danger ml-2" data-bs-toggle="modal" data-bs-target={`#A${idTarefas}-modalcard-2`}>Excluir</button>
                            </div>
                            : ''}

                    </div>
                </div >
            }

            {/** Situação em Andamento */}
            {
                situação == 'Em Andamento' &&
                <div className='col-md-4'>
                    <div className="uk-card uk-card-default uk-margin-bottom">
                        <div className="uk-card-header">
                            {importância == 'Baixa' && <h5 style={{ borderBottom: '3px solid LimeGreen', display: 'inline', color: 'black' }}>{assunto}</h5>}
                            {importância == 'Média' && <h5 style={{ borderBottom: '3px solid Gold', display: 'inline', color: 'black' }}>{assunto}</h5>}
                            {importância == 'Alta' && <h5 style={{ borderBottom: '3px solid red', display: 'inline', color: 'black' }}>{assunto}</h5>}
                        </div>
                        <div className="uk-card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className='text-red'>Descrição:</h6>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: descrição }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Responsável:</h6>
                                    <p>{responsável}</p>
                                </div>
                                <div className="col-md-6">
                                    <h6 className='text-red'>Situação:</h6>
                                    <p>{situação}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Data de Início:</h6>
                                    <p>{moment(dataInicio).format('L')}</p>
                                </div>
                                <div className="col-md-6">
                                    <h6 className='text-red'>Data de Conclusão:</h6>
                                    <p>{moment(dataConclusão).format('L')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Importância:</h6>
                                    <p>{importância}</p>
                                </div>
                                <div className="col-md-12">
                                    <h6 className='text-red'>Observação / Solução:</h6>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: observações }} />
                                </div>
                                <div className="col-md-12">
                                    <h6 className='text-red'>Inserido por:</h6>
                                    <p>{usuario}</p>
                                </div>
                            </div>
                        </div>

                        <div className="uk-card-footer text-center">
                            <button className="btn btn-sm btn-outline-warning" data-bs-toggle="modal" data-bs-target={`#A${idTarefas}-modalcard-1`}>Editar</button>
                            <button className="btn btn-sm btn-outline-danger ml-2" data-bs-toggle="modal" data-bs-target={`#A${idTarefas}-modalcard-2`}>Excluir</button>
                        </div>

                    </div>
                </div>
            }

            {/** Situação em Concluído */}
            {
                situação == 'Concluído' &&
                <div className='col-md-4'>
                    <div className="uk-card uk-card-default uk-margin-bottom">
                        <div className="uk-card-header">
                            {importância == 'Baixa' && <h5 style={{ borderBottom: '3px solid LimeGreen', display: 'inline', color: 'black' }}>{assunto}</h5>}
                            {importância == 'Média' && <h5 style={{ borderBottom: '3px solid Gold', display: 'inline', color: 'black' }}>{assunto}</h5>}
                            {importância == 'Alta' && <h5 style={{ borderBottom: '3px solid red', display: 'inline', color: 'black' }}>{assunto}</h5>}
                        </div>
                        <div className="uk-card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className='text-red'>Descrição:</h6>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: descrição }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Responsável:</h6>
                                    <p>{responsável}</p>
                                </div>
                                <div className="col-md-6">
                                    <h6 className='text-red'>Situação:</h6>
                                    <p>{situação}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Data de Início:</h6>
                                    <p>{moment(dataInicio).format('L')}</p>
                                </div>
                                <div className="col-md-6">
                                    <h6 className='text-red'>Data de Conclusão:</h6>
                                    <p>{moment(dataConclusão).format('L')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className='text-red'>Importância:</h6>
                                    <p>{importância}</p>
                                </div>
                                <div className="col-md-12">
                                    <h6 className='text-red'>Observação / Solução:</h6>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: observações }} />
                                </div>
                                <div className="col-md-12">
                                    <h6 className='text-red'>Inserido por:</h6>
                                    <p>{usuario}</p>
                                </div>
                            </div>
                        </div>

                        <div className="uk-card-footer text-center">
                            <button className="btn btn-sm btn-outline-warning" data-bs-toggle="modal" data-bs-target={`#A${idTarefas}-modalcard-1`}>Editar</button>
                            <button className="btn btn-sm btn-outline-danger ml-2" data-bs-toggle="modal" data-bs-target={`#A${idTarefas}-modalcard-2`}>Excluir</button>
                        </div>

                    </div>
                </div>
            }

            {/** Modal para editar as tarefas */}
            <div className="modal fade" id={`A${idTarefas}-modalcard-1`} tabIndex="-1" aria-labelledby={`A${idTarefas}-exampleModalLabel-1`} aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações da Lista de Tarefas</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-3">
                                    <TextField
                                        onChange={(e) => setDataInicio(e.target.value)}
                                        type="date"
                                        className="form-control"
                                        label="Data de Início"
                                        InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                        value={dataInicio && dataInicio} />
                                </div>
                                <div className="col-md-9">
                                    <TextField
                                        onChange={(e) => setAssunto(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        label="Assunto"
                                        value={assunto && assunto} />
                                </div>
                            </div>
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-6">
                                    <TextField
                                        onChange={(e) => setResponsável(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        label="Responsável Pela Execução"
                                        value={responsável && responsável} />
                                </div>
                            </div>
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-4">
                                    <TextField
                                        onChange={(e) => setDataConclusão(e.target.value)}
                                        className="form-control"
                                        label="Data de Conclusão"
                                        type="date"
                                        InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                        value={dataConclusão && dataConclusão} />
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <select
                                            className="form-select"
                                            id="floatingSelect"
                                            aria-label="Floating label select example"
                                            onChange={(e) => setSituação(e.target.value)}
                                            value={situação && situação}
                                            type="text">
                                            <option disabled>-- Selecione um tipo --</option>
                                            <option selected value="Pendente">Pendente</option>
                                            <option value="Em Andamento">Em andamento</option>
                                            <option value="Concluído">Concluído</option>
                                        </select>
                                        <label for="floatingSelect">Situação</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <select
                                            className="form-select"
                                            id="floatingSelect"
                                            aria-label="Floating label select example"
                                            onChange={(e) => setImportância(e.target.value)}
                                            value={importância && importância}
                                            type="text">
                                            <option disabled>-- Selecione um tipo --</option>
                                            <option selected value="Baixa">Baixa</option>
                                            <option value="Média">Média</option>
                                            <option value="Alta">Alta</option>
                                        </select>
                                        <label for="floatingSelect">Importância</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-12">
                                    <label>Descrição</label>
                                    <JoditEditor
                                        className=''
                                        ref={editor}
                                        value={descrição}
                                        tabIndex={1}
                                        onChange={newContent => setDescrição(newContent)}
                                    />
                                </div>
                            </div>
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-12">
                                    <label>Observações</label>
                                    <JoditEditor
                                        className=''
                                        ref={editor}
                                        value={observações}
                                        tabIndex={1}
                                        onChange={newContent => setObservações(newContent)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button onClick={AtualizarTarefas} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Atualizar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/** Modal para excluir as tarefas */}
            <div className="modal fade" id={`A${idTarefas}-modalcard-2`} tabIndex="-1" aria-labelledby={`A${idTarefas}-exampleModalLabel-2`} aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações da Lista de Tarefas</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-3">
                                    <TextField
                                        disabled
                                        onChange={(e) => setDataInicio(e.target.value)}
                                        type="date"
                                        className="form-control"
                                        label="Data de Início"
                                        InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                        value={dataInicio && dataInicio} />
                                </div>
                                <div className="col-md-9">
                                    <TextField
                                        disabled
                                        onChange={(e) => setAssunto(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        label="Assunto"
                                        value={assunto && assunto} />
                                </div>
                            </div>
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-6">
                                    <TextField
                                        disabled
                                        onChange={(e) => setResponsável(e.target.value)}
                                        type="text"
                                        className="form-control"
                                        label="Responsável Pela Execução"
                                        value={responsável && responsável} />
                                </div>
                            </div>
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-4">
                                    <TextField
                                        disabled
                                        onChange={(e) => setDataConclusão(e.target.value)}
                                        className="form-control"
                                        label="Data de Conclusão"
                                        type="date"
                                        InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                        value={dataConclusão && dataConclusão} />
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <select
                                            disabled
                                            className="form-select"
                                            id="floatingSelect"
                                            aria-label="Floating label select example"
                                            onChange={(e) => setSituação(e.target.value)}
                                            value={situação && situação}
                                            type="text">
                                            <option disabled>-- Selecione um tipo --</option>
                                            <option selected value="Pendente">Pendente</option>
                                            <option value="Em Andamento">Em andamento</option>
                                            <option value="Concluído">Concluído</option>
                                        </select>
                                        <label for="floatingSelect">Situação</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <select
                                            disabled
                                            className="form-select"
                                            id="floatingSelect"
                                            aria-label="Floating label select example"
                                            onChange={(e) => setImportância(e.target.value)}
                                            value={importância && importância}
                                            type="text">
                                            <option disabled>-- Selecione um tipo --</option>
                                            <option selected value="Baixa">Baixa</option>
                                            <option value="Média">Média</option>
                                            <option value="Alta">Alta</option>
                                        </select>
                                        <label for="floatingSelect">Importância</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-12">
                                    <label>Descrição</label>
                                    <JoditEditor
                                        disabled
                                        className=''
                                        ref={editor}
                                        value={descrição}
                                        tabIndex={1}
                                        onChange={newContent => setDescrição(newContent)}
                                    />
                                </div>
                            </div>
                            <div className="row col-md-12 mb-5">
                                <div className="col-md-12">
                                    <label>Observações</label>
                                    <JoditEditor
                                        disabled
                                        className=''
                                        ref={editor}
                                        value={observações}
                                        tabIndex={1}
                                        onChange={newContent => setObservações(newContent)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button onClick={ExcluirTarefas} type="button" className="btn btn-sm btn-outline-danger" data-bs-dismiss="modal">Excluir</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ListadeTarefasCard