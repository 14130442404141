import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import firebase from '../../../config/firebase'

function MeuCalendario() {
    const [eventos, setEventos] = useState([]);

    // Carregar eventos do Firebase quando o componente é montado
    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('eventos')
            .onSnapshot(snapshot => {
                const eventosFirebase = snapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setEventos(eventosFirebase);
            });

        return () => unsubscribe();
    }, []);

    const handleDateClick = (arg) => {
        // Adicionar novo evento no Firebase
        const novoEvento = {
            title: 'Nova Anotação',
            start: arg.dateStr,
        };

        firebase.firestore().collection('eventos').add(novoEvento)
            .then((docRef) => {
                setEventos([...eventos, { ...novoEvento, id: docRef.id }]);
            })
            .catch(error => {
                console.error("Erro ao adicionar documento: ", error);
            });
    };

    return (
        <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={eventos}
            dateClick={handleDateClick}
        />
    );
}

export default MeuCalendario;

