import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import firebase from '../../config/firebase'

import { getStorage, ref, getDownloadURL } from "firebase/storage"


function Menu() {

    const usuarioEmail = useSelector(state => state.usuarioEmail)
    const dispatch = useDispatch()

    const storage = getStorage()

    const [urlImagens, setUrlImagens] = useState()
    getDownloadURL(ref(storage, `Usuários/${usuarioEmail}/FotoPerfil/${'FotoPerfil'}`))
    .then(url => setUrlImagens(url))
    .catch(error => {
        // Tratar o erro aqui
        console.error("Erro ao obter URL da imagem:", error.message)
        // Definir uma URL padrão ou fazer outra coisa para lidar com a falta do objeto
    })

    const [urlImagemNova, setUrlImagemNova] = useState()
    getDownloadURL(ref(storage, `Usuários/${'user defalt.png'}`)).then(url => setUrlImagemNova(url))

    //#region Informações Usuário
    const [InfoPessoais, setInfoPessoais] = useState('')
    const [nome, setNome] = useState('')
    const [formação, setFormação] = useState('')
    const [Foto, setFoto] = useState('')
    const [datacriação, setDataCriação] = useState('')
    const [aniversário, setAniversário] = useState('')
    const [sexo, setSexo] = useState('')
    const [telefone, setTelefone] = useState('')

    useEffect(() => {
        firebase.firestore().collection('Usuários').doc(usuarioEmail).get().then(resultado => {
            setInfoPessoais(resultado.data().InfoPessoais)
            setAniversário(resultado.data().InfoPessoais.Aniversário)
            setDataCriação(resultado.data().InfoPessoais.Datacriação)
            setFormação(resultado.data().InfoPessoais.Formação)
            setFoto(resultado.data().InfoPessoais.Foto)
            setNome(resultado.data().InfoPessoais.Nome)
            setSexo(resultado.data().InfoPessoais.Sexo)
            setTelefone(resultado.data().InfoPessoais.Telefone)
        })
    }, [])
    //#endregion

    return (
        <>
            {/** Nav Superior */}
            <nav className="navbar border-bottom">
                {/* Left navbar links */}
                <div>
                    <Link className="" type="button" uk-toggle="target: #offcanvas-nav">
                        <span className='text-white' uk-icon="icon: menu" />
                        <span className="text-black uk-margin-small-left mr-2 text-white text-navbar text-bold"></span>
                    </Link>
                    <div id="offcanvas-nav" uk-offcanvas="overlay: true">
                        <div className="uk-offcanvas-bar">
                            <button className="uk-offcanvas-close uk-background-secondary " uk-icon="icon: close" type="button" uk-close></button>
                            <ul className="uk-nav uk-nav-default uk-nav-parent-icon" uk-nav="multiple: false">
                                {useSelector(state => state.usuarioLogado) > 0 ?
                                    <li className="uk-nav-header" align='center'>
                                        <img className="img-circle elevation-2 my-2 foto" src={urlImagens ? urlImagens : urlImagemNova} alt="User Avatar" />
                                        <span className="text-red d-block my-2"><h6 className='itens-menu'>{InfoPessoais.Nome ? InfoPessoais.Nome : 'Novo Usuário'}</h6></span>
                                        <span className="text-red d-block"><h6 className='itens-menu'>{usuarioEmail}</h6></span>
                                    </li>
                                    : ""}
                                <li className="uk-nav-header">
                                    <Link className="nav-link" to='/'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: home" />Home</h5></Link>
                                </li>
                                {useSelector(state => state.usuarioLogado) > 0 ?
                                    <>
                                        <li class="uk-nav-divider mb-3"></li>
                                        <li className="nav-item">
                                            <Link className="nav-link my-2" to='/MinhasObras'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: arrow-right" />Minhas Obras</h5></Link>
                                        </li>
                                        <li class="uk-nav-divider mb-3"></li>
                                        <li className="nav-item">
                                            <Link className="nav-link my-2" to='/configurações'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: cog" />Configurações</h5></Link>
                                        </li>
                                    </>
                                    :
                                    ''
                                }

                                <li class="uk-nav-divider mb-3"></li>
                                <li><Link className="nav-link my-2" to='/faleconosco'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: users" />Fale Conosco</h5></Link></li>
                                {useSelector(state => state.usuarioLogado) > 0 ?
                                    <li className="nav-item">
                                        <li class="uk-nav-divider mb-3"></li>
                                        <Link className="nav-link my-2" to="/" onClick={() => dispatch({ type: 'LOG_OUT' })}><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: sign-out" />Sair</h5></Link>
                                    </li>
                                    :
                                    <>
                                        <li class="uk-nav-divider my-2"></li>
                                        <li><Link className="d-block" to='/login'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: sign-in" />Faça Login</h5></Link></li>
                                        <li><Link className="d-block" to='/novousuario'><h5 className='itens-menu'><span className="uk-margin-small-right" uk-icon="icon: user" />Cadastrar</h5></Link></li>
                                    </>
                                }

                            </ul>
                        </div>
                    </div>
                </div>
                {/** navbar center */}
                <div class="uk-navbar-center mt-1">
                    <Link to='/'>
                        <h6 className='text-white text-navbar text-bold'>CONSTRUCAOBR.COM</h6>
                    </Link>
                </div>
                {/* Right navbar links */}
                <div className="uk-navbar-right">

                </div>
            </nav>
        </>
    )
}

export default Menu