import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

import JoditEditor from 'jodit-react'

function Anotações() {

    const { id } = useParams()

    const [Anotações, setAnotações] = useState('')
    const editor = useRef(null);

    /** Buscar Anotações */
    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
            setAnotações(resultado.data().Anotações)
        })
    }, [])

    /** Editar Anotações */
    function EditarAnotações() {
        firebase.firestore().collection('Obras').doc(id).update({
            Anotações: Anotações
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).get().then(resultado => {
                setAnotações(resultado.data().Anotações)
            })
        })
    }


    return (
        <div className="uk-card uk-card-default col-md-12">
            <div className="uk-card-header">
                <div className="uk-grid-small uk-flex-middle" uk-grid>
                    <div className="uk-width-expand">
                        <h3 class="uk-card-title uk-margin-remove-bottom">Anotações - Visível e Editável por TODOS.</h3>
                    </div>
                </div>
            </div>
            <div class="uk-card-body">
                <div className="row card-body bg-transparent table-responsive card-success">
                    <span className='col-md-12' dangerouslySetInnerHTML={{ __html: Anotações }} />
                </div>

                <div className="uk-card-footer">

                    <button type="button" className="btn btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#Anotação">Editar</button>


                    <div class="modal fade" id="Anotação" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className="col-md-12 mb-5">
                                        <label>Principais Serviços</label>
                                        <JoditEditor
                                            className=''
                                            ref={editor}
                                            value={Anotações}
                                            tabIndex={1}
                                            onChange={newContent => setAnotações(newContent)}
                                        />
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                    <button onClick={EditarAnotações} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Atualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** Caixa de Diálogo*/}
                    <div className="modal fade" id="Anotações" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Editar Anotações</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body row">
                                    <div className="row">
                                        <div className="col-md-12 mb-5">
                                            <label>Principais Serviços</label>
                                            <JoditEditor
                                                className=''
                                                ref={editor}
                                                value={Anotações}
                                                tabIndex={1}
                                                onChange={newContent => setAnotações(newContent)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                                    <button onClick={EditarAnotações} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Atualizar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Anotações
