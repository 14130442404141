import React, { useState } from 'react'
import firebase from '../../config/firebase'
import 'firebase/auth'
import { Navigate } from 'react-router-dom'


import './NovoUsuario.css'
import MenuHome from './../../components/menu/home'
import Footer from '../../components/footer'

import { useDispatch, useSelector } from 'react-redux'

function NovoUsuario() {

    const [email, setEmail] = useState()
    const [senha, setSenha] = useState()
    const [repetirsenha, setRepetirSenha] = useState()
    const [msgTipo, setMsgTipo] = useState()
    const [msg, setMsg] = useState()
    const [carregando, setCarregando] = useState()
    const dispatch = useDispatch()

    const db = firebase.firestore()

    function cadastrar() {

        setCarregando(1)

        setMsgTipo(null)

        if (!email || !senha) {
            setCarregando(0)
            setMsgTipo('Erro')
            setMsg('Você precisa informar o email e a senha para fazer o cadastro !')
            return;
        }

        firebase.auth().createUserWithEmailAndPassword(email, senha).then(resultado => {

            db.collection('Usuários').doc(email).set({
                Contagem: 1,
                DataCriação: new Date(),
                InfoPessoais: {
                    Nome: '',
                    Email: email,
                    Formação: '',
                    Aniversário: '',
                    Sexo: '',
                    Telefone: '',
                    Empresa: ''
                },
                InfoPastas: {
                    Pasta1: 'Mostrar',
                    Label1: 'Pasta 1',
                    Pasta2: 'Mostrar',
                    Label2: 'Pasta 2',
                    Pasta3: 'Mostrar',
                    Label3: 'Pasta 3',
                    Pasta4: 'Ocultar',
                    Label4: 'Pasta 4',
                    Pasta5: 'Ocultar',
                    Label5: 'Pasta 5',
                    Pasta6: 'Ocultar',
                    Label6: 'Pasta 6',
                    Pasta7: 'Ocultar',
                    Label7: 'Pasta 7',
                    Pasta8: 'Ocultar',
                    Label8: 'Pasta 8',
                    Pasta9: 'Ocultar',
                    Label9: 'Pasta 9',
                    Pasta10: 'Ocultar',
                    Label10: 'Pasta 10',
                    Pasta11: 'Ocultar',
                    Label11: 'Pasta 11',
                    Pasta12: 'Mostrar',
                    Label12: 'Todas as Obras'
                },
                InfoLayout: {
                    color: 'green'
                }
            }).then(() => {
                setMsgTipo('Sucesso')
                setTimeout(() => {
                    dispatch({ type: 'LOG_IN', usuarioEmail: email })
                }, 0)
            }).catch(erro => {
                setMsgTipo('Erro')
            })
            setCarregando(0)
            setMsgTipo('Sucesso')
        }).catch(erro => {
            setCarregando(0)
            setMsgTipo('Erro')
            switch (erro.message) {
                case 'Password should be at least 6 characters':
                    setMsg('A senha deve ter pelo menos 6 caracteres')
                    break
                case 'The email address is already in use by another account.':
                    setMsg('Este email já está sendo utilizado por outro usuário')
                    break
                case 'The email address is badly formatted.':
                    setMsg('O formato do seu email é inválido')
                    break
                default:
                    setMsg('Não foi possível cadastrar. Tente novamente mais tarde.')
                    break
            }

        })
    }

    return (
        <>
            <MenuHome />
            {
                useSelector(state => state.usuarioLogado) > 0 ? <Navigate to={'/login'} /> : null
            }
            <div className="form-cadastro">
                <form className="text-center form-login mx-auto mt-5">
                    <h1 className="h3 mb-3 text-black font-weight-bold">Cadastro</h1>
                    <input onChange={(e) => setEmail(e.target.value)} type="email" className="text-lowercase form-control my-2" placeholder="Email" />
                    <input onChange={(e) => setSenha(e.target.value)} type="password" className="form-control my-2" placeholder="Senha" />
                    <input onChange={(e) => setRepetirSenha(e.target.value)} type="password" className="form-control my-2" placeholder="Repetir Senha" />

                    {
                        carregando ? <div class="spinner-border text-danger" role="status"><span class="sr-only">Loading...</span></div>
                            :
                            repetirsenha === senha ?
                                <button onClick={cadastrar} type="button" className="btn btn-lg btn-block mt-3 mb-5 btn-cadastro">Cadastrar</button> : ''
                    }

                    <div className="msg-login text-black text-center my-5">
                        {msgTipo === 'Sucesso' && <span> Usuário cadastrado com sucesso! &#128522;</span>}
                        {msgTipo === 'Erro' && <span><strong>Ops! </strong> {msg}</span>}
                    </div>
                    <h5 className="mb-3 text-black font-weight-bold text-red">Atenção: Utilize um email existente e válido, em caso de perda de senha, a recuperação será feita através do email existente.</h5>
                </form>
            </div>
            <Footer />
        </>
    )
}

export default NovoUsuario