import React, { useState } from 'react'
import './RecuperarSenha.css'

import MenuHome from './../../components/menu/home'
import firebase from '../../config/firebase'
import Footer from '../../components/footer'
import 'firebase/auth'

function RecuperarSenha() {

    const [email, setEmail] = useState()
    const [msg, setMsg] = useState()

    function Senha() {
        firebase.auth().sendPasswordResetEmail(email).then(resultado => {
            setMsg('Enviamos um Link para o seu email para você redefinir a senha.')
        }).catch(erro => {
            setMsg('Verifique se o email está cooreto.')
        })
    }

    return (
        <>
            <MenuHome />
            <form className="text-center form-login mx-auto mt-5">
                <h3 className="mb-3 font-weight-bold ">Recuperar Senha</h3>
                <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control my-2" placeholder="Email" />
                <div className="msg my-4 text-center">
                    <span>{msg}</span>
                </div>
                <button onClick={Senha} type="button" className="btn btn-lg btn-block btn-enviar">Recuperar Senha</button>
            </form>
            <Footer />
        </>
    )
}

export default RecuperarSenha