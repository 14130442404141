import React, { useState, useEffect } from 'react'
import firebase from '../../../config/firebase'

/** Material UI */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'


function Obras({ id, Etapa, Medição, Tipo, Início, Término, Valor, Valorcbdi, ValorCVariação, ValorPrevistoCVariação, Editar, IdMedição, IdObra, InfoMedições }) {

    const [idObra, setIdObra] = useState(IdObra)
    const [idMedição, setIdMedição] = useState(IdMedição)
    const [etapa, setEtapa] = useState(Etapa)
    const [medição, setMedição] = useState(Medição)
    const [tipo, setTipo] = useState(Tipo)
    const [início, setInício] = useState(Início)
    const [término, setTérmino] = useState(Término)
    const [valor, setValor] = useState(Valor)
    const [valorcbdi, setValorcBDI] = useState(Valorcbdi)
    const [valorcvariação, setValorCVariação] = useState(ValorCVariação)
    const [valorPrevistoCVariação, setValorPrevistoCVariação] = useState(ValorPrevistoCVariação)

    useEffect(() => {
        setIdObra(IdObra);
        setIdMedição(IdMedição);
        setEtapa(Etapa);
        setMedição(Medição);
        setTipo(Tipo);
        setInício(Início);
        setTérmino(Término);
        setValor(Valor);
        setValorcBDI(Valorcbdi);
        setValorCVariação(ValorCVariação);
        setValorPrevistoCVariação(ValorPrevistoCVariação);
    }, [IdObra, IdMedição, Etapa, Medição, Tipo, Início, Término, Valor, Valorcbdi, ValorCVariação, ValorPrevistoCVariação]);

    //#region Atualizar Medição
    function AtualizarMedição() {
        firebase.firestore().collection('Obras').doc(idObra).collection('Medição').doc(idMedição).update({
            Etapa: Number(etapa),
            Medição: Number(medição),
            Tipo: tipo,
            Início: início,
            Término: término,
            Valor: Number(valor),
            ValorCBDI: Number(valorcbdi),
            ValorCVariação: Number(valorcvariação),
            ValorPrevistoCVariação: Number(valorPrevistoCVariação)
        }).then(() => {
            InfoMedições();
        }, [])
    }
    //#endregion

    //#region Excluir Medição
    function ExcluirCronograma() {
        // Exibe uma mensagem de confirmação e só continua se o usuário clicar em "OK"
        const confirmacao = window.confirm('Tem certeza que deseja apagar esta Medição ?');

        if (confirmacao) {
            firebase.firestore().collection('Obras').doc(idObra).collection('Medição').doc(idMedição).delete()
                .then(() => {
                    // Lógica após a exclusão
                    InfoMedições();
                    alert('Medição apagada com sucesso!');
                })
                .catch(erro => {
                    console.error('Erro ao apagar suspensão:', erro);
                    alert('Erro ao apagar a medição. Verifique o console para mais detalhes.');
                });
        }
    }
    //#endregion

    return (
        <>
            {Editar == "Não" &&
                < div className="row col-md-12" >
                    {id}
                    {Etapa}
                    {Medição}
                    {Tipo}
                    {início}
                    {término}
                    {valor}
                    {valorcbdi}
                    {valorcvariação}
                    {ValorPrevistoCVariação}
                </div >

            }
            {Editar == "Sim" && <>
                {/** Botão Editar */}
                <button className='btn btn-sm btn-outline-warning' type="button" data-bs-toggle="modal" data-bs-target={`#A${idMedição}-modal-1`}>Editar</button>
                <div className="modal fade" id={`A${idMedição}-modal-1`} tabIndex="-1" aria-labelledby={`A${idMedição}-exampleModalLabel-1`} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações da Medição</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">                                
                                <div className='row col-md-12 mb-5'>
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setIdObra(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            label="ID Obra"
                                            value={idObra && idObra} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setIdMedição(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            label="ID Cronograma"
                                            value={idMedição && idMedição} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-3">
                                        <TextField
                                            onChange={(e) => setEtapa(e.target.value)}
                                            type="number"
                                            className="form-control"
                                            label="Etapa"
                                            value={etapa && etapa} />
                                    </div>
                                    <div className="col-md-3">
                                        <TextField
                                            onChange={(e) => setMedição(e.target.value)}
                                            className="form-control"
                                            label="Medição"
                                            type="number"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={medição && medição} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            onChange={(e) => setTipo(e.target.value)}
                                            className="form-control"
                                            label="Tipo da Medição"
                                            type="text"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={tipo && tipo} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setInício(e.target.value)}
                                            className="form-control"
                                            label="Data de Início"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={início && início} />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            onChange={(e) => setTérmino(e.target.value)}
                                            className="form-control"
                                            label="Data de Término"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={término && término} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-6">
                                        <TextField
                                            onChange={(e) => setValor(e.target.value)}
                                            className="form-control"
                                            label="Valor"
                                            type="number"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={valor && valor} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            onChange={(e) => setValorcBDI(e.target.value)}
                                            className="form-control"
                                            label="Valor com BDI"
                                            type="number"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={valorcbdi && valorcbdi} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-6">
                                        <TextField
                                            onChange={(e) => setValorCVariação(e.target.value)}
                                            className="form-control"
                                            label="Valor Com Variação"
                                            type="number"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={valorcvariação && valorcvariação} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            onChange={(e) => setValorPrevistoCVariação(e.target.value)}
                                            className="form-control"
                                            label="Valor Previsto Com Variação"
                                            type="number"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={valorPrevistoCVariação && valorPrevistoCVariação} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={AtualizarMedição} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/** Botão Excluir */}
                <button className='btn btn-sm btn-outline-danger ml-2' type="button" data-bs-toggle="modal" data-bs-target={`#A${idMedição}-modal-2`}>Excluir</button>
                <div className="modal fade" id={`A${idMedição}-modal-2`} tabIndex="-1" aria-labelledby={`A${idMedição}-exampleModalLabel-2`} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Excluir Medição</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row col-md-12 mb-2'>
                                    <p className='fw-bolder text-red'>Atenção: Separar as casas decimais com ponto (".")</p>
                                </div>
                                <div className='row col-md-12 mb-5'>
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setIdObra(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            label="ID Obra"
                                            value={idObra && idObra} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setIdMedição(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            label="ID Cronograma"
                                            value={idMedição && idMedição} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-3">
                                        <TextField
                                            disabled
                                            onChange={(e) => setEtapa(e.target.value)}
                                            type="number"
                                            className="form-control"
                                            label="Etapa"
                                            value={etapa && etapa} />
                                    </div>
                                    <div className="col-md-3">
                                        <TextField
                                            disabled
                                            onChange={(e) => setMedição(e.target.value)}
                                            className="form-control"
                                            label="Medição"
                                            type="number"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={medição && medição} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setTipo(e.target.value)}
                                            className="form-control"
                                            label="Tipo da Medição"
                                            type="text"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={tipo && tipo} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-4">
                                        <TextField
                                            disabled
                                            onChange={(e) => setInício(e.target.value)}
                                            className="form-control"
                                            label="Data de Início"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={início && início} />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            disabled
                                            onChange={(e) => setTérmino(e.target.value)}
                                            className="form-control"
                                            label="Data de Término"
                                            type="date"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={término && término} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setValor(e.target.value)}
                                            className="form-control"
                                            label="Valor"
                                            type="number"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={valor && valor} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setValorcBDI(e.target.value)}
                                            className="form-control"
                                            label="Valor com BDI"
                                            type="number"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={valorcbdi && valorcbdi} />
                                    </div>
                                </div>
                                <div className="row col-md-12 mb-5">
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setValorCVariação(e.target.value)}
                                            className="form-control"
                                            label="Valor Previsto Com Variação"
                                            type="text"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={valorcvariação && valorcvariação} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            disabled
                                            onChange={(e) => setValorPrevistoCVariação(e.target.value)}
                                            className="form-control"
                                            label="Valor Previsto Com Variação"
                                            type="text"
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            value={valorPrevistoCVariação && valorPrevistoCVariação} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                <button onClick={ExcluirCronograma} type="button" className="btn btn-sm btn-outline-danger" data-bs-dismiss="modal">Excluir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default Obras