import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from '../../config/firebase'

/** Moment */
import moment, { now } from 'moment'
import 'moment/locale/pt-br'

/** Material UI */
import { TextField } from '@mui/material'
import { InputAdornment } from '@mui/material'

/** Páginas */
import MediçãoCard from './mediçãoCard'

function Medição() {

    const { id } = useParams()

    const [checked, setChecked] = React.useState(true);

    //#region Formatação do Número
    const numberDecimalFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);

    const numberIntegerFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(value);

    const numberFormat = (value) =>
        new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    //#endregion

    //#region views
    const [viewMedições, SetViewMedições] = useState('False')
    function viewModeMedições() {
        viewMedições == 'True' && SetViewMedições('False')
        viewMedições == 'False' && SetViewMedições('True')
    }

    const [viewMedColEtapa, SetMedColEtapa] = useState('True')
    function viewModeMedColEtapa() {
        viewMedColEtapa == 'True' && SetMedColEtapa('False')
        viewMedColEtapa == 'False' && SetMedColEtapa('True')
    }

    const [viewMedColMed, SetMedColMed] = useState('True')
    function viewModeMedColMed() {
        viewMedColMed == 'True' && SetMedColMed('False')
        viewMedColMed == 'False' && SetMedColMed('True')
    }

    const [viewMedColTipo, SetMedColTipo] = useState('True')
    function viewModeMedColTipo() {
        viewMedColTipo == 'True' && SetMedColTipo('False')
        viewMedColTipo == 'False' && SetMedColTipo('True')
    }

    const [viewMedColInicio, SetMedColInicio] = useState('True')
    function viewModeMedColInicio() {
        viewMedColInicio == 'True' && SetMedColInicio('False')
        viewMedColInicio == 'False' && SetMedColInicio('True')
    }

    const [viewMedColTermino, SetMedColTermino] = useState('True')
    function viewModeMedColTermino() {
        viewMedColTermino == 'True' && SetMedColTermino('False')
        viewMedColTermino == 'False' && SetMedColTermino('True')
    }

    const [viewMedColValor, SetMedColValor] = useState('True')
    function viewModeMedColValor() {
        viewMedColValor == 'True' && SetMedColValor('False')
        viewMedColValor == 'False' && SetMedColValor('True')
    }

    const [viewMedColValorCBDI, SetMedColValorCBDI] = useState('True')
    function viewModeMedColValorCBDI() {
        viewMedColValorCBDI == 'True' && SetMedColValorCBDI('False')
        viewMedColValorCBDI == 'False' && SetMedColValorCBDI('True')
    }

    const [viewMedColValorCVar, SetMedColValorCVar] = useState('True')
    function viewModeMedColValorCVar() {
        viewMedColValorCVar == 'True' && SetMedColValorCVar('False')
        viewMedColValorCVar == 'False' && SetMedColValorCVar('True')
    }

    const [viewMedColValorPCVar, SetMedColValorPCVar] = useState('True')
    function viewModeMedColValorPCVar() {
        viewMedColValorPCVar == 'True' && SetMedColValorPCVar('False')
        viewMedColValorPCVar == 'False' && SetMedColValorPCVar('True')
    }

    const [viewMedColEdit, SetMedColEdit] = useState('True')
    function viewModeMedColEdit() {
        viewMedColEdit == 'True' && SetMedColEdit('False')
        viewMedColEdit == 'False' && SetMedColEdit('True')
    }
    //#endregion

    //#region Ler Banco de dados
    let listaMedição = []
    const [MediçãoEtapa, setMediçãoEtapa] = useState('')
    const [MediçãoMedição, setMediçãoMedição] = useState('')
    const [MediçãoTipo, setMediçãoTipo] = useState('')
    const [MediçãoInício, setMediçãoInício] = useState('')
    const [MediçãoTérmino, setMediçãoTérmino] = useState('')
    const [MediçãoValor, setMediçãoValor] = useState('')
    const [MediçãoValorCBDI, setMediçãoValorCBDI] = useState('')
    const [MediçãoValorCVariação, setMediçãoValorCVariação] = useState('')
    const [MediçãoValorPrevistoCVariação, setMediçãoValorPrevistoCVariação] = useState('')
    const [Medição, setMedição] = useState([])

    const InfoMedições = () => {
        firebase.firestore().collection('Obras').doc(id).collection('Medição').orderBy('Medição').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaMedição.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setMedição(listaMedição)
        })
    }

    useEffect(() => {
        firebase.firestore().collection('Obras').doc(id).collection('Medição').orderBy('Medição').get().then(async (resultado) => {
            await resultado.docs.forEach(doc => {
                listaMedição.push({
                    id: doc.id,
                    ...doc.data()
                })
            })
            setMedição(listaMedição)
        })
    }, [])
    //#endregion

    //#region Inserir Medição
    function InserirMedição() {
        firebase.firestore().collection('Obras').doc(id).collection('Medição').add({
            Etapa: MediçãoEtapa,
            Medição: MediçãoMedição,
            Tipo: MediçãoTipo,
            Início: MediçãoInício,
            Término: MediçãoTérmino,
            Valor: MediçãoValor,
            ValorCBDI: MediçãoValorCBDI,
            ValorCVariação: MediçãoValorCVariação,
            ValorPrevistoCVariação: MediçãoValorPrevistoCVariação
        }).then(() => {
            firebase.firestore().collection('Obras').doc(id).collection('Medição').orderBy('Etapa').get().then(async (resultado) => {
                await resultado.docs.forEach(doc => {
                    listaMedição.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setMedição(listaMedição)
                setMediçãoEtapa('')
                setMediçãoMedição('')
                setMediçãoTipo('')
                setMediçãoInício('')
                setMediçãoTérmino('')
                setMediçãoValor('')
                setMediçãoValorCBDI('')
                setMediçãoValorCVariação('')
                setMediçãoValorPrevistoCVariação('')
            })
        }).catch(erro => {

        })
    }
    //#endregion

    return (
        <>
            <div className="uk-card uk-card-default col-md-12">
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                        <div className="uk-width-expand d-flex align-items-center">
                            <h3 class="uk-card-title uk-margin-remove-bottom">Medições</h3>
                            <div class="uk-inline">
                                <i className="fa-solid fa-caret-down ml-3" style={{ fontSize: '22px' }} />
                                <div uk-dropdown="mode: click; animation: slide-top; animate-out: true; duration: 700 ">
                                    <ul className="uk-nav uk-dropdown-nav">
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" onClick={viewModeMedColEtapa} /> Etapa</h6></li>
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeMedColMed} /> Medição</h6></li>
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeMedColTipo} /> Tipo</h6></li>
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeMedColInicio} /> Início</h6></li>
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeMedColTermino} /> Término</h6></li>
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeMedColValor} /> Valor</h6></li>
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" onClick={viewModeMedColValorCBDI} /> Valor C/ BDI</h6></li>
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeMedColValorCVar} /> Valor C/ Variação</h6></li>
                                        <li className='mb-2'><h6><input className="uk-checkbox" type="checkbox" defaultChecked={checked} onClick={viewModeMedColValorPCVar} /> Valor Previsto C/ Variação</h6></li>
                                        <li><h6><input className="uk-checkbox" type="checkbox" onClick={viewModeMedColEdit} /> Editar</h6></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body">
                    <table className="table table-hover text-nowrap">
                        {/** Cabeçalho Tabela */}
                        <thead>
                            <tr>
                                {viewMedColEtapa == 'False' ? <th>Etapa</th> : ""}
                                {viewMedColMed == 'True' ? <th>Medição</th> : ""}
                                {viewMedColTipo == 'True' ? <th>Tipo</th> : ""}
                                {viewMedColInicio == 'True' ? <th>Início</th> : ""}
                                {viewMedColTermino == 'True' ? <th>Término</th> : ""}
                                {viewMedColValor == 'True' ? <th>Valor</th> : ""}
                                {viewMedColValorCBDI == 'False' ? <th>Valor C/ BDI</th> : ""}
                                {viewMedColValorCVar == 'True' ? <th>Valor C/ Variação</th> : ""}
                                {viewMedColValorPCVar == 'True' ? <th>Valor Previsto C/ Variação</th> : ""}
                                {viewMedColEdit == 'False' ? <th>Editar</th> : ""}
                            </tr>
                        </thead>

                        {/** Composição Tabela */}
                        {Medição.map(item =>
                            <tbody>
                                <tr>{viewMedColEtapa == 'False' ?
                                    <td>
                                        <MediçãoCard
                                            key={item.id}
                                            Etapa={item.Etapa}
                                            Editar='Não'
                                        />
                                    </td>
                                    : ''}
                                    {viewMedColMed == 'True' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                Medição={item.Medição}
                                                Editar='Não'
                                            />
                                        </td>
                                        : ''}
                                    {viewMedColTipo == 'True' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                Tipo={item.Tipo}
                                                Editar='Não'
                                            />
                                        </td>
                                        : ''}
                                    {viewMedColInicio == 'True' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                Início={moment(item.Início).format('L')}
                                                Editar='Não'
                                            />
                                        </td>
                                        : ''}
                                    {viewMedColTermino == 'True' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                Término={moment(item.Término).format('L')}
                                                Editar='Não'
                                            />
                                        </td>
                                        : ''}
                                    {viewMedColValor == 'True' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                Valor={numberFormat(item.Valor)}
                                                Editar='Não'
                                            />
                                        </td>
                                        : ''}
                                    {viewMedColValorCBDI == 'False' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                Valorcbdi={numberFormat(item.ValorCBDI)}
                                                Editar='Não'
                                            />
                                        </td>
                                        : ''}
                                    {viewMedColValorCVar == 'True' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                ValorCVariação={numberFormat(item.ValorCVariação)}
                                                Editar='Não'
                                            />
                                        </td>
                                        : ''}
                                    {viewMedColValorPCVar == 'True' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                ValorPrevistoCVariação={numberFormat(item.ValorPrevistoCVariação)}
                                                Editar='Não'
                                            />
                                        </td>
                                        : ''}
                                    {viewMedColEdit == 'False' ?
                                        <td>
                                            <MediçãoCard
                                                key={item.id}
                                                id={item.id}
                                                IdObra={id}
                                                IdMedição={item.id}
                                                Etapa={item.Etapa}
                                                Medição={item.Medição}
                                                Tipo={item.Tipo}
                                                Início={item.Início}
                                                Término={item.Término}
                                                Valor={item.Valor}
                                                Valorcbdi={item.ValorCBDI}
                                                ValorCVariação={item.ValorCVariação}
                                                ValorPrevistoCVariação={item.ValorPrevistoCVariação}
                                                InfoMedições={InfoMedições}
                                                Editar='Sim'
                                            />
                                        </td>
                                        :
                                        ''}
                                </tr>
                            </tbody>)}
                    </table>
                </div>
                <div className="uk-card-footer">
                    <button className="btn btn-sm btn-outline-primary float-right" data-bs-toggle="modal" data-bs-target="#Medicao">Adicionar</button>
                </div>

                {/** Caixa de Diálogo Adicionar Medição*/}
                <div className="modal fade" id="Medicao" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Editar Informações da Medição</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-3">
                                            <TextField
                                                onChange={(e) => setMediçãoEtapa(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Etapa"
                                                value={MediçãoEtapa && MediçãoEtapa} />
                                        </div>
                                        <div className="col-md-3">
                                            <TextField
                                                onChange={(e) => setMediçãoMedição(e.target.value)}
                                                type="number"
                                                className="form-control"
                                                label="Medição"
                                                value={MediçãoMedição && MediçãoMedição} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setMediçãoTipo(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                label="Tipo da Medição"
                                                value={MediçãoTipo && MediçãoTipo} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-4">
                                            <TextField
                                                onChange={(e) => setMediçãoInício(e.target.value)}
                                                className="form-control"
                                                label="Data de Início"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={MediçãoInício && MediçãoInício} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                onChange={(e) => setMediçãoTérmino(e.target.value)}
                                                className="form-control"
                                                label="Data de Término"
                                                type="date"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={MediçãoTérmino && MediçãoTérmino} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setMediçãoValor(e.target.value)}
                                                className="form-control"
                                                label="Valor"
                                                type="number"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={MediçãoValor && MediçãoValor} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setMediçãoValorCBDI(e.target.value)}
                                                className="form-control"
                                                label="Valor com BDI"
                                                type="number"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={MediçãoValorCBDI && MediçãoValorCBDI} />
                                        </div>
                                    </div>
                                    <div className="row col-md-12 mb-5">
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setMediçãoValorCVariação(e.target.value)}
                                                className="form-control"
                                                label="Valor Com Variação"
                                                type="number"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={MediçãoValorCVariação && MediçãoValorCVariação} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                onChange={(e) => setMediçãoValorPrevistoCVariação(e.target.value)}
                                                className="form-control"
                                                label="Valor Previsto Com Variação"
                                                type="number"
                                                InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                                value={MediçãoValorPrevistoCVariação && MediçãoValorPrevistoCVariação} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">Fechar</button>
                                    <button onClick={InserirMedição} type="button" className="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Inserir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Análises da Medição */}
            <div className='row'>
                <div className="col-md-3 my-2">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Acumulado C/ Variação</h3>
                        <h5 className=' float-right'>{numberFormat(Medição.reduce((acc, p) => acc + parseFloat(p.ValorCVariação), 0))}</h5>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <h3 className="uk-card-title">Previsto Acumulado C/ Variação</h3>
                        <h5 className=' float-right'>{numberFormat(Medição.reduce((acc, p) => acc + parseFloat(p.ValorPrevistoCVariação), 0))}</h5>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Medição